import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../api";
import * as config from "../../utils/apiVersion";
import { startLoading, stopLoading } from "../feature";
import { errorToastSelector, successToastSelector } from "../selector";
import { buildQueryString } from "../../helpers";
import { setListingState } from "../feature/coinlisting/coinlisting.Slice";

export const submitListAgreement = createAsyncThunk(
  "coinListingService/submitListAgreement",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = "";
      let method = "post";
      if (payload?.id) {
        method = "put";
      }
      url = `listing/coin_listings?${buildQueryString(payload)}`;
      let res = await API[method](config.peatio)(url);
      dispatch(setListingState({ key: "success", data: true }));
      dispatch(stopLoading());
      return { data: res, status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const submitAsset = createAsyncThunk(
  "coinListingService/submitAsset",
  async (payload, { rejectWithValue, dispatch }) => {
    let url = "";
    if (payload?.params) {
      url = `listing/coin_listings/asset/?icon_id=${payload?.params}`;
    } else {
      url = `listing/coin_listings/asset`;
    }
    try {
      dispatch(startLoading());
      let res = await API.put(config.peatio)(url, payload?.body);
      if (res) {
        dispatch(setListingState({ key: "success", data: true }));
      }
      dispatch(stopLoading());
      return { data: res, status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const submitCompliance = createAsyncThunk(
  "coinListingService/submitCompliance",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = "";
      url = `listing/coin_listings/compliance?${buildQueryString(payload)}`;
      let res = await API.put(config.peatio)(url);
      if (res) {
        dispatch(setListingState({ key: "success", data: true }));
      }
      dispatch(stopLoading());
      return { data: res, status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const submitCoinListingForm = createAsyncThunk(
  "coinListingService/submitCoinListingForm",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = "";
      url = `listing/coin_listings/submit?${buildQueryString(payload)}`;
      let res = await API.put(config.peatio)(url);
      if (res) {
        dispatch(setListingState({ key: "success", data: true }));
        // dispatch(
        //   successToastSelector({ message: "Asset created successfully " })
        // );
      }
      dispatch(stopLoading());
      return { data: res, status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const getCoinLists = createAsyncThunk(
  "coinListingService/getCoinLists",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = "";
      url = `listing/coin_listings?${buildQueryString(payload)}`;
      let res = await API.get(config.peatioWithHeader)(url);
        dispatch(stopLoading());
        return { list: res?.data, total: res?.headers?.total , status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const getSingleCoinData = createAsyncThunk(
  "coinListingService/getSingleCoinData",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = "";
      url = `listing/coin_listings/${payload?.id}`;
      let res = await API.get(config.peatio)(url);
      dispatch(stopLoading());
      return { data: res, status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const deleteAsset = createAsyncThunk(
  "coinListingService/deleteAsset",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = "";
      url = `listing/coin_listings?${buildQueryString(payload)}`;
      let res = await API.delete(config.peatio)(url);
      if (res) {
        dispatch(
          setListingState({
            key: "success",
            data: { state: true, type: "delete" },
          })
        );
        dispatch(
          successToastSelector({ message: "Asset deleted successfully " })
        );
        dispatch(stopLoading());
        return { data: res, status: true };
      }
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const getAllListedCoins = createAsyncThunk(
  "coinListingService/getAllListedCoins",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `listing/coin_listings/listed_coins?${buildQueryString(
        payload
      )}`;
      let res = await API.get(config.peatioWithHeader)(url);
      dispatch(stopLoading());
      return { list: res?.data, total: res?.headers?.total, status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);
