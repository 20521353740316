import React from "react";
import { Card, Typography, Row, Col, Tag } from "antd";
import monero from "../../Assets/Images/monero.svg";
import style from "./style.module.scss";
import { FinishIcon } from "../../Assets/Svg/Svg";

const { Title } = Typography;

const LaunchpadCard = (props) => {
  return (
    <>
      <Card className="LaunchpadCard_custom" onClick={props.onClick}>
        <div className="LaunchpadCard ">
          <div className="block imageblock">
            <div
              className={`iconOuter ${style.cardBlock_img}`}
              style={{
                backgroundImage: props.cardblockimg
                  ? "linear-gradient(180deg, #8247E5 -13.5%, #D2B8FF 100%)"
                  : "linear-gradient(180deg, #F26822 -13.5%, #4D4D4D 100%)",
              }}
            >
              <div className={style.cardBlock_img_icon}>
                {props.monero ? (
                  <FinishIcon />
                ) : (
                  <img src={monero} alt="icon" />
                )}
              </div>
              <Tag
                style={{ border: "unset" }}
                className={`customTag ${style.cardBlock_img_tag}`}
                color={props.color}
              >
                {props.tagtext}
              </Tag>
            </div>
          </div>
          <div className="block">
            <div className={style.cardBlock_Content_flex}>
              <Title level={5}>Monero</Title>
              {props.timeCount && (
                <>
                  <h5 className={style.cardBlock_Content_flex_EndTime}>
                    End Time: <span> 16-10-2023</span>
                  </h5>
                </>
              )}
            </div>
            <p className="title" style={{ paddingBottom: "29px" }}>
              All the Lorem Ipsum generators on the Internet tend{" "}
            </p>
            <Row gutter={[29, 15]}>
              {props.pointList?.map((point) => (
                <Col key={point.id} xs={24} sm={12}>
                  <p className={style.cardBlock_Content_contentText}>
                    <span>{point.title}</span>
                    <br />
                    {point.detail}
                  </p>
                </Col>
              ))}
            </Row>
            {props.DayCount && (
              <div className={`Durationblock ${style.cardBlock_Content_flex} `}>
                <p style={{ paddingTop: "29px" }}>
                  {props.subscription}Time left until subscription start:
                </p>
                <div className={style.cardBlock_Content_Duration}>
                  <span>
                    <strong>03</strong>Days
                  </span>
                  <span>
                    <strong>24</strong> Hours
                  </span>
                  <span>
                    <strong>16</strong> Mins
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      </Card>
    </>
  );
};

export default LaunchpadCard;
