import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import style from "../../AssetsTable/style.module.scss";
import { Tabs } from 'antd';
import TabPane from 'antd/lib/tabs/TabPane';
import LoginActivity from './LoginActivity';
import SecurityActivity from './SecurityActivity';

const AccountActivity = () => {
    const { t } = useTranslation();
    const [currentTab, setCurrentTab] = useState("login");

    const items = [
        {
            key: '1',
            label: 'Tab 1',
            children: 'Content of Tab Pane 1',
        },
        {
            key: '2',
            label: 'Tab 2',
            children: 'Content of Tab Pane 2',
        },
        {
            key: '3',
            label: 'Tab 3',
            children: 'Content of Tab Pane 3',
        },
    ];

    const onChangeTab = (key) => {
        setCurrentTab(key)
    };

    return (
        <>
            <div className="style_earn__9r+gR">
                <div className="activityDiv">
                    <div className={style.port}>
                        <div className="topHeading">
                            <h4 className="text-black-20">{t("account_activity_page.heading")}</h4>
                            <p className="text-black">{t("account_activity_page.desc")}</p>
                        </div>

                        <Tabs
                            className="blueTabs portTab"
                            defaultActiveKey={currentTab}
                            onChange={onChangeTab}
                            activeKey={currentTab}
                        >
                            <TabPane tab={t("account_activity_page.Login_activity")} key={"login"}>
                                {currentTab === "login" && <LoginActivity />}
                            </TabPane>
                            <TabPane tab={t("account_activity_page.Security_activity")} key={"security"}>
                                {currentTab === "security" && <SecurityActivity />}
                            </TabPane>
                        </Tabs>


                    </div>
                </div>
            </div>
        </>
    )
}

export default AccountActivity