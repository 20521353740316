import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../api";
import { buildQueryString } from "../../helpers";
import * as config from "../../utils/apiVersion";
import { startLoading, stopLoading } from "../feature";
import { errorToastSelector, successToastSelector } from "../selector";
import { getUser } from "./user";

export const createResetPass = createAsyncThunk(
  "setting/createResetPass",
  async (payload, { dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `identity/users/password/generate_code`;
      await API.post(config.barong)(url, payload);
      dispatch(stopLoading());
      dispatch(
        successToastSelector({
          message: "Email sent successfully",
        })
      );
      return { status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e));
      return false;
    }
  }
);

export const createResetPassPhone = createAsyncThunk(
  "setting/createResetPassPhone",
  async ({ otpClicked, ...rest }, { dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `identity/users/password/reset_password_otp`;
      await API.post(config.barong)(url, rest);
      dispatch(stopLoading());
      dispatch(
        successToastSelector({
          message: `OTP ${otpClicked ? "resent" : "sent"}  successfully`,
        })
      );
      return { status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e));
      return false;
    }
  }
);

export const validateResetPassPhoneOtp = createAsyncThunk(
  "setting/createResetPassPhoneOtp",
  async (payload, { dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `identity/users/password/validate_reset_password_otp`;
      let res = await API.post(config.barongWithHeader)(url, payload);
      dispatch(stopLoading());
      // dispatch(
      //   successToastSelector({
      //     message: "OTP sent successfully",
      //   })
      // );
      return { status: true, token: res.headers.reset_password_token};
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e));
      return false;
    }
  }
);

export const changePass = createAsyncThunk(
  "setting/createResetPass",
  async (payload, { dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `identity/users/password/confirm_code`;
      await API.post(config.barong)(url, payload);
      dispatch(stopLoading());
      dispatch(
        successToastSelector({
          message: "Password changed successfully",
        })
      );
      return { status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e));
      return false;
    }
  }
);

export const verifyToken = createAsyncThunk(
  "setting/verifyToken",
  async (payload, { dispatch }) => {
    try {
      let url = `identity/users/password/link_expire`;
      await API.post(config.barong)(url, payload);
      return { status: true };
    } catch (e) {
      dispatch(errorToastSelector(e));
      return false;
    }
  }
);

export const changePassword = createAsyncThunk(
  "setting/changePassword",
  async (payload, { dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `resource/users/password`;
      const res = await API.put(config.barong)(url, payload);
      dispatch(
        successToastSelector({
          message: `Password changed successfully`,
        })
      );
      dispatch(stopLoading());
      return { data: res, status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e));
      return false;
    }
  }
);
export const changeEmail = createAsyncThunk(
  "setting/changeEmail",
  async (payload, { dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `resource/users/change_email/verify`;
      const res = await API.post(config.barong)(url, payload);
      dispatch(
        successToastSelector({
          message: `Email changed successfully`,
        })
      );
      dispatch(stopLoading());
      return { data: res, status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e));
      return false;
    }
  }
);
export const getOtpCode = createAsyncThunk(
  "setting/getOtpCode",
  async ({ otpClicked, ...rest }, { dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `resource/users/password/otp_confirmation`;
      const res = await API.get(config.barong)(url, rest);
      dispatch(
        successToastSelector({
          message: `OTP ${otpClicked ? "resent" : "sent"} successfully`,
        })
      );
      dispatch(stopLoading());
      return { status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e));
      return false;
    }
  }
);

export const getEmailOtpCode = createAsyncThunk(
  "setting/getEmailOtpCode",
  async (payload, { dispatch }) => {
    const { email, otpClicked } = payload;
    console.log("payloadpayload",email);
    try {
      dispatch(startLoading());
      let url = `resource/users/change_email/code?${buildQueryString(
        payload
      )}`;
      const res = await API.get(config.barong)(url);
      dispatch(
        successToastSelector({
          message: `OTP ${otpClicked ? "resent" : "sent"} successfully`,
        })
      );
      dispatch(stopLoading());
      return { status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e));
      return false;
    }
  }
);

export const getProfileData = createAsyncThunk(
  "setting/getProfileData",
  async (payload, { dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `resource/profiles/me`;
      const res = await API.get(config.barong)(url);
      dispatch(stopLoading());
      return { status: true, data: res };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e));
      return false;
    }
  }
);

export const kycPostData = createAsyncThunk(
  "setting/kycPostData",
  async (payload, { dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `resource/profiles`;
      await API.post(config.barong)(url, payload);
      dispatch(stopLoading());
      return { status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e));
      return false;
    }
  }
);

export const kycUpdate = createAsyncThunk(
  "setting/kycUpdate",
  async (payload, { dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `resource/profiles`;
      await API.put(config.barong)(url, payload);
      dispatch(stopLoading());
      return { status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e));
      return false;
    }
  }
);

export const documentUpload = createAsyncThunk(
  "setting/documentUpload",
  async (payload, { dispatch }) => { 
    try {
      dispatch(startLoading());
      let url = `resource/documents`;
      await API.post(config.barong)(url, payload);
      dispatch(stopLoading());
      dispatch(getUser());
      dispatch(getProfileData());
      return { status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e));
      return false;
    }
  }
);
