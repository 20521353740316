export const confirmModalVal = {
  amount: "",
  apy: "",
  id: "",
  duration: "",
  name: "",
  earn: "",
  curId: "",
};

export const currencyProtocols = {
  "eth-mainnet": "erc20",
  "trx-mainnet": "trc20",
  "bsc-mainnet": "bep20"
}

export const convertNetworkName = (val) => {
  const networkNames = {
    "ETH": "ERC20",
    "TRX": "TRC20",
    "BSC": "BEP20"
  };
  return val && networkNames[val] ? networkNames[val] : val;
};
