import React from "react";
import { Button } from "antd";
import style from "./style.module.scss";
import CommonButton from "../../Ui/button/CommonButton";
import CommitModaldata from "./CommitModaldata";
import { ButtonCustom } from "../../Ui/button/ButtonCustom";

const StepContent = ({
  date,
  heading,
  mint,
  deducted,
  title,
  Detail,
  commitment,
  greenCommitment,
  modalopen,
  buttontext,
}) => {
  return (
    <div>
      <h6>{date}</h6>
      <p>{heading}</p>
      <div className={style.gradientborderCard}>
        {mint && (
          <h5>
            {commitment}
            <span>{greenCommitment}</span>
          </h5>
        )}
        <div className={style.gradientborderCard_flex}>
          <div>
            {deducted && (
              <h2>
                <span>Your committed MNT</span>
                <br />
                0.0000 MNT
              </h2>
            )}
            <h2>
              <span>{title}</span>
              <br />
              {Detail}
            </h2>
          </div>

          {modalopen && <CommitModaldata />}
          {buttontext && (
            <Button className="btn_custom grayBtn">View Locked MNT</Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default StepContent;
