import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { exchangeOrdersTabs, staticPeriod } from "../../../../staticObjects";
import ExchangeCmnBtn from "../ExchangeCmnBtn";
import CustomSelect from "../../../Ui/CustomSelect/CustomSelect";
import "./../Exchange.scss";
import RangeDatePickerCustom from "../../../Ui/RangeDatePickerCustom";
import { convertBackDate } from "../../../../helpers";
import { useTranslation } from "react-i18next";

const staticObj = {
  period: staticPeriod.day,
  from: convertBackDate(staticPeriod.day).from_date || "",
  to: convertBackDate(staticPeriod.day).to_date || "",
  symbol: "",
  side: "",
  status: "",
  type: "",
  asset: "",
  mode: "",
  page: 1,
};

function FuturePortfolioFilters(props) {
  const marketList = useSelector((state) => state?.exchange?.marketList);
  const currentMarketId = useSelector(
    (state) => state?.exchange?.currentMarketId
  );
  const { orderHistory } = exchangeOrdersTabs;
const {t} = useTranslation();
  const [filterObj, setFilterObj] = useState(staticObj);
  const [symbolObj, setSymbolObj] = useState([]);
  const [dateValue, setDateValue] = useState(null);

  useEffect(() => {
    const temp = [];
    marketList.length &&
      marketList?.map((item) => {
        temp.push({ label: item.name, value: item.id });
      });
    setSymbolObj(temp);
  }, [marketList]);

  useEffect(() => {
    setFilterObj({ ...filterObj, symbol: currentMarketId });
  }, [currentMarketId]);

  const sendData = (obj) => {
    return {
      from: obj.from || "",
      to: obj.to || "",
      symbol: obj.symbol || "",
      side: obj.side || "",
      status: obj.status || "",
      page: 1,
    };
  };

  //
  const statusObj = [
    { label: "All", value: "" },
    { label: "Filled", value: "filled" },
    { label: "Wait", value: "wait" },
    { label: "Cancelled", value: "cancel" },
    { label: "Partially Filled", value: "partially_filled" },
  ];

  const sideObj = [
    { label: "All", value: "" },
    { label: "Buy", value: "buy" },
    { label: "Sell", value: "sell" },
  ];
  const assetObj = [
    { label: "All", value: "" },
    { label: "BTC", value: "btc" },
    { label: "USDT", value: "usdt" },
  ];

  const typeObj = [
    { label: "All", value: "" },
    { label: "Buy", value: "buy" },
    { label: "Sell", value: "sell" },
  ];

  const modeObj = [
    { label: "All", value: "" },
    { label: "Buy", value: "buy" },
    { label: "Sell", value: "sell" },
  ];

  const { period, from, to, symbol, status, side, type, mode } = filterObj;

  const submitFilter = () => {
    props.cbFun(sendData(filterObj));
  };

  const resetData = () => {
    setDateValue(null);

    let newObj = {
      period: staticPeriod.day,
      from: convertBackDate(staticPeriod.day).from_date || "",
      to: convertBackDate(staticPeriod.day).to_date || "",
      symbol: currentMarketId,
    };
    setFilterObj({ ...staticObj, ...newObj });
    props.cbFun(sendData({ ...staticObj, ...newObj }));
  };

  const isDisable =
    dateValue || symbol || side || (props.currentTab === orderHistory && status)
      ? false
      : true;

  return (
    <div className="exchangeFilterDiv">
      {props.currentTab === "positionHistory" && (
        <div className="cstmSelect">
          <CustomSelect
            label="Mode"
            placeholder="All"
            drop_data={modeObj}
            onChange={(mode) => setFilterObj({ ...filterObj, mode: mode })}
            value={mode}
          />
        </div>
      )}
      {props.currentTab !== "openOrders" && (
        <RangeDatePickerCustom
          getDate={(val) => {
            setDateValue(val);
            setFilterObj({
              ...filterObj,
              from: moment(val[0]).format("YYYY-MM-DD"),
              to: moment(val[1]).format("YYYY-MM-DD"),
              period: "",
            });
          }}
          value={dateValue}
        />
      )}
      {props.currentTab !== "transactionHistory" &&
        props.currentTab !== "positionHistory" &&
        props.currentTab !== "fundingFee" && (
          <div className="cstmSelect">
            <CustomSelect
              label="Symbol"
              placeholder="All"
              drop_data={symbolObj}
              onChange={(currency) =>
                setFilterObj({ ...filterObj, symbol: currency })
              }
              value={symbol}
            />
          </div>
        )}
      {(props.currentTab === "fundingFee" ||
        props.currentTab === "transactionHistory") && (
        <div className="cstmSelect">
          <CustomSelect
            label="Asset"
            placeholder="All"
            drop_data={assetObj}
            onChange={(asset) => setFilterObj({ ...filterObj, asset: asset })}
            value={symbol}
          />
        </div>
      )}
      {(props.currentTab === "orderHistory" ||
        props.currentTab === "transactionHistory") && (
        <div className="cstmSelect">
          <CustomSelect
            label="Type"
            placeholder="All"
            drop_data={typeObj}
            onChange={(type) => setFilterObj({ ...filterObj, type: type })}
            value={type}
          />
        </div>
      )}
      {props.currentTab !== "transactionHistory" &&
        props.currentTab !== "positionHistory" &&
        props.currentTab !== "fundingFee" && (
          <div className="cstmSelect">
            <CustomSelect
              label="Side"
              placeholder="All"
              drop_data={sideObj}
              onChange={(side) => setFilterObj({ ...filterObj, side: side })}
              value={side}
            />
          </div>
        )}

      {props.currentTab === "orderHistory" && (
        <div className="cstmSelect">
          <CustomSelect
            label="Status"
            placeholder="All"
            drop_data={statusObj}
            onChange={(status) =>
              setFilterObj({ ...filterObj, status: status })
            }
            value={status}
          />
        </div>
      )}

      <ExchangeCmnBtn
        title={t("TradeSelect_Pair.Search")}
        onClick={() => {
          submitFilter();
        }}
        isDisable={isDisable}
      />
      <ExchangeCmnBtn
        title={"Reset"}
        onClick={() => {
          resetData();
        }}
        isDisable={isDisable}
      />
    </div>
  );
}
export default React.memo(FuturePortfolioFilters);
