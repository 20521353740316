import { showAlert } from "../feature";
export const successToastSelector = (e) => {
  return showAlert({
    message: [e.message],
    type: "success",
  });
};

// export const errorToastSelector = (e) => {
//   console.log(e);
//   return showAlert({
//     message: [e.message],
//     code: e?.code ? e?.code : 422,
//     type: "error",
//   });
// };

export const errorToastSelector = (e) => {
  console.log(e);

  if (
    e &&
    e.code === 422 &&
    Array.isArray(e.message) &&
    e.message.length === 0
  ) {
    return showAlert({
      message: ["Premature withdraw unsuccessful!"],
      code: e.code,
      type: "error",
    });
  } else {
    return showAlert({
      message: [e.message],
      code: e?.code ? e.code : 422,
      type: "error",
    });
  }
};
