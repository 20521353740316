import React from "react";
import { Input } from "antd";
import "./Custom_input.scss";

const Custom_input = React.forwardRef((props, ref) => {  // Ref should be the second argument
  const {
    label,
    placeholder,
    outpages_select,
    imgcontent,
    btcimges,
    currency,
    onlycurrency,
    onlyCurrencyStyle,
    onChange,
    name,
    value,
    marginTopNone,
    max,
    disable,
    type,
    rightTextClick,
    children,
    icon,
    iconfirst,
    rightTextClass,
    height,
    readOnly,
    customClass,
    autocomplete,
    triggerOnChange,
    triggerOnBlur,
  } = props;

  return (
    <div className={`input_textimg ${customClass ? customClass : ""}`}>
      {label ? <label className="label">{label}</label> : null}
      <Input
        style={{ height: height && height }}
        disabled={disable}
        type={type}
        readOnly={readOnly}
        maxLength={max}
        value={value}
        name={name}
        autoComplete={autocomplete}
        placeholder={placeholder}
        ref={ref}  // This is where the ref gets attached
        onChange={(e) => {
          onChange(e);
          triggerOnChange && triggerOnChange(e);
        }}
        onBlur={(e) => {
          triggerOnBlur && triggerOnBlur(e);
        }}
        className={`input_amount ${
          outpages_select ? "outpages_select" : ""
        } ${imgcontent ? "paddingRight" : ""} ${
          onlyCurrencyStyle ? "onlyCurrency" : ""
        } ${marginTopNone ? "marginTopNone" : ""} `}
      />
      {imgcontent ? (
        <span className="input_imgcontent">
          <img src={btcimges} alt="images" />
          <p>{currency}</p>
        </span>
      ) : null}
      {icon ? (
        <span className="input_imgcontent">
          <img src={iconfirst} alt="images" />
        </span>
      ) : null}
      {onlycurrency ? (
        <span className="input_imgcontent" onClick={rightTextClick}>
          <p className={rightTextClass}>{onlycurrency}</p>
        </span>
      ) : null}
      {children}
    </div>
  );
});

export default Custom_input;
