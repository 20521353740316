import React from "react";
import "./CHBCard.scss";
export default function CHBCard({ image, text, className }) {
  return (
    <div className={`cards ${className}`}>
        <div className="cardImg">
      <img src={image} width={25} height={28} alt="rewarded" />
      </div>
      <p>{text}</p>
    </div>
  );
}
