import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import CommonButton from "../../Ui/button/CommonButton";
import style from "./style.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { fetchBeneficiary, getBalance } from "../../../redux/services";
import {
  addBeneficiaryName,
  depositWithdrawDropdownType,
  fieldType,
  sendReceiveType,
} from "../../../staticObjects";
import CommonRenderFields from "../../Ui/commonRenderFields";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { addBeneficiarySchema } from "../../../Schema";
import CommonAssetNetworkList from "./commonAssetNetworkList";
import { filterCurrWithNetwork } from "../../../helpers";
import CommonModal from "../../CommonModal";
import GoogleAuthModal from "../../Common/Modals/GoogleAuthModal";
import { t } from "i18next";

const AddAddress = (props) => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user.data.otp);
  const [openModal, setOpenModal] = useState(false);
  const [openChangeModal, setOpenChangeModal] = useState(false);

  const openVerificationModal = () => {
    setOpenModal(true);
  };
  const { currenciesList } = useSelector((state) => state.commonApiData);
  const [newAddress, setNewAddress] = useState(false);
  const [currentObj, setCurrentObj] = useState({});
  const [currentNetwork, setCurrentNetwork] = useState({});
  const { asset, network } = depositWithdrawDropdownType;
  const { normalInput } = fieldType;
  const { addressLabel, address, code } = addBeneficiaryName;
  const { isOpenAddressModal, callFrom, closeAddressModal } = props;
  const isFromSendModal = callFrom === "sendModal";
  const [states, setStates] = useState(false);

  const filterCurrencyByType = (list) => {
    let filterArr = list?.filter((val, idx) => val?.currency_type === "coin");
    if (filterArr.length) {
      let newArray = filterCurrWithNetwork(currenciesList, list);
      setNewAddress(true);
      setCurrentObj({ ...newArray[0] });
    }
  };

  const actionsFun = async () => {
    let res = await dispatch(getBalance());
    if (res.payload.status) {
      filterCurrencyByType(res.payload.list);
    }
  };

  const {
    handleSubmit,
    control,
    formState: { errors, isValid: formIsvalid },
    reset,
  } = useForm({
    resolver: yupResolver(addBeneficiarySchema),
  });

  const closeModal = () => {
    if (isFromSendModal) {
      closeAddressModal();
    }
    setNewAddress(false);
    setCurrentObj({});
  };

  const onSubmit = (data) => {
    if (formIsvalid) {
      dispatch(fetchBeneficiary({ coin: currentObj?.currency }));
      props.cbFun({
        ...data,
        currencyObj: currentObj,
        currentNetwork: currentNetwork,
        onModalClose: () => closeModal(),
      });
    }
    closeModal()
  };

  const cbFunAssetNetwrok = (obj, type) => {
    if (type === asset) {
      if (obj.currency !== currentObj.currency) {
        setCurrentObj({ ...obj });
      }
    } else if (type === network) {
      if (obj?.blockchain_key !== currentNetwork.blockchain_key) {
        setCurrentNetwork({ ...obj });
      }
    }
  };

  useEffect(() => {
    if (isOpenAddressModal) {
      // actionsFun();
      setNewAddress(true);
    }
  }, [isOpenAddressModal]);

  useEffect(() => {
    reset();
  }, [newAddress]);

  return (
    <div>
      {!isFromSendModal && (
        <CommonButton
          title={t("addrsManagement.addNewAddress")}
          onClick={actionsFun}
        />
      )}
      <CommonModal isOpen={newAddress} onCancel={closeModal}>
        <h2 className="titleModal">{t("addrsManagement.addNewAddress")}</h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={style.addAdress}>
            <CommonRenderFields
              errors={errors}
              control={control}
              name={addressLabel}
              placeholder={"4-30 characters e.g Amy's"}
              label={t("addrsManagement.addrLabel")}
              type={normalInput}
            />
            <CommonAssetNetworkList
              className={`${style.pd0}`}
              cbFun={cbFunAssetNetwrok}
              balanceObj={currentObj}
              type={sendReceiveType.withdraw}
              isAddAddressModal={true}
              setStates={setStates}
            />
            <CommonRenderFields
              errors={errors}
              control={control}
              name={address}
              placeholder={t("addrsManagement.EnterAddresshere")}
              label={t("addrsManagement.addr")}
              type={normalInput}
            />
            <div className="mt10">
              <CommonRenderFields
                errors={errors}
                control={control}
                name={code}
                placeholder={t("withdraw_fiat.enter_Code")}
                label={t("addrsManagement.2fa")}
                type={normalInput}
              />
            </div>
            {userData === true ? (
              <CommonButton
                title={t("addrsManagement.save")}
                htmlType="submit"
              />
            ) : (
              <CommonButton
                title="Enable 2FA"
                onClick={() => openVerificationModal()}
              />
            )}
          </div>
        </form>
      </CommonModal>
      {openModal && (
        <GoogleAuthModal
          open={openModal}
          onCancel={() => setOpenModal(false)}
        />
      )}
    </div>
  );
};

export default AddAddress;
