import React, { useEffect, useState } from "react";
import { QRCodeSVG } from "qrcode.react";
import { StoreImages } from "../../Storeimgaes/StoreImages";
import CommonAssetNetworkList from "./commonAssetNetworkList";
import {
  depositWithdrawDropdownType,
  sendReceiveType,
} from "../../../staticObjects";
import style from "./style.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { copytoClipBoard, isLevel3, uppercase } from "../../../helpers";
import { useTranslation } from "react-i18next";
import Identityverify from "../../Common/Identityverify";
import { useParams } from "react-router-dom";
import { getProfileData } from "../../../redux/services";

const { copy } = StoreImages;
const { asset, network } = depositWithdrawDropdownType;

const Recived = (props) => {
  const currencyId = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { address: depositAddress } = useSelector((state) => state.sendReceive);
  const { data } = useSelector((state) => state.user);
  const [balanceObjState, setBalanceObjState] = useState({});
  const [currentNetwork, setCurrentNetwork] = useState({});
  const [states, setStates] = useState(false);
  useEffect(() => {
    setBalanceObjState(props.balanceObj);
  }, [props.balanceObj]);

  const cbFun = (obj, type) => {
    if (type === asset) {
      setBalanceObjState({ ...obj });
      setCurrentNetwork({ });
    } else if (type === network) {
      setCurrentNetwork({ ...obj });
    }
  };
  const { min_deposit_amount, deposit_fee } = currentNetwork; 
  const currency = uppercase(balanceObjState?.currency);
  if (!isLevel3(data?.level)) {
    return <Identityverify cbOnClick={props.closeModal} />;
  }
  return (
    <>
      <CommonAssetNetworkList
        className={`${style.deposit}`}
        balanceObj={balanceObjState}
        cbFun={cbFun}
        type={sendReceiveType.deposit}
        setStates={setStates}
      />
      <div className={style.recived}>
        <div className="text-center">
          <QRCodeSVG value={depositAddress} height={"200px"} width={"200px"} />
        </div>
        <div className={style.recived_input}>
          <h6 className="black14 ">
            <span>
              {currency} {t("mining_pool.address")}
            </span>
            <img
              className="curserPointer"
              src={copy}
              alt="icon"
              onClick={() => copytoClipBoard(depositAddress, t)}
            />
          </h6>
          <p className="grey twelve">{depositAddress}</p>
        </div>
        <h6 className="black14">{t("deposit.important")}</h6>
        <p className="grey twelve">
          {t("send&Receive.note1", {
            value: `${min_deposit_amount !==undefined ? min_deposit_amount:'0'} ${currency}`,
          })}
        </p>
        {Number(deposit_fee) > 0 && (
          <>
            <p className="grey twelve">
              {t("send&Receive.note2", { value: `${deposit_fee}%` })}
            </p>
            <p className="grey twelve">
              {t("send&Receive.note7")} <br />
              <b> {t("send&Receive.note8", { currency: currency })}</b>
            </p>
          </>
        )}

        <p className="grey twelve">
          {t("send&Receive.note3", { currency: currency })}
        </p>
        <p className="grey twelve">
          <b>{t("depositImp")} </b>
        </p>
      </div>
    </>
  );
};

export default Recived;
