import React from "react";
import style from "./style.module.scss";
import HeadComman from "../../Common/HeadComman";
import { Row, Col } from "antd";
import { StoreImages } from "../../Storeimgaes/StoreImages";

const PayWith = (props) => {
  const { axis } = StoreImages;
  const { onClick } = props;
  const amountList = [
    {
      id: 1,
      img: axis,
      bankName: "Axis Bank",
      acountNumber: "2343 XXXX XXXX XX56",
      amount: "$5.00",
    },
    {
      id: 2,
      img: axis,
      bankName: "HDFC Bank",
      acountNumber: "2343 XXXX XXXX XX56",
      amount: "$5.00",
    },
    {
      id: 3,
      img: axis,
      bankName: "Kotak Bank",
      acountNumber: "2343 XXXX XXXX XX56",
      amount: "$5.00",
    },
  ];

  return (
    <>
      <div className={`${style.PayWith} `}>
        <HeadComman title="Pay With" onClick={onClick} />
        <div className={style.PayWith_body}>
          {amountList.map((user, id) => (
            <Row key={id} className={style.PayWith_body_list}>
              <Col>
                <div className={style.PayWith_body_list_dflex}>
                  <img src={user.img} alt="" />
                  <h6>
                    {user.bankName}
                    <br />
                    <span>{user.acountNumber}</span>
                  </h6>
                </div>
              </Col>
              <Col>
                <p>
                  Balance:
                  <span>{user.amount}</span>
                </p>
              </Col>
            </Row>
          ))}
        </div>
      </div>
    </>
  );
};

export default PayWith;
