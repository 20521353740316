import React from "react";
import { Steps } from "antd";
import StepContent from "./StepContent";
import style from "./style.module.scss";

const LaunchpadStepList = () => {
  return (
    <div className={`customStep ${style.TimeLine}`}>
      <Steps
        direction="vertical"
        current={2}
        items={[
          {
            title: "Subscription Period",
            description: (
              <StepContent
                commitment="Your max commitment limit:"
                greenCommitment=" 0.4500 MNT"
                title="Your committed MNT"
                Detail="0.0000 MNT"
                date="2023-12-15 14:00"
                heading="Reminder: Only MNT in your spot wallet can be used to subscribe. If the MNT balance in
            your spot wallet is less than your max commitment limit. you can transfer MNT from other
            wallets to your spot wallet to use."
                mint
                modalopen
              />
            ),
          },
          {
            title: "Calculation Period",
            description: (
              <StepContent
                commitment="Your committed MNT:"
                greenCommitment=" 0.4500 MNT"
                title="Your token allocation"
                Detail="Calculating..."
                date="2023-12-15 17:00"
                heading="Your final token allocation is being calculated and will be revealed during the final token
                distribution phase"
                mint
                buttontext="dskjfjkds"
              />
            ),
          },
          {
            title: "Final Token Distribution",
            description: (
              <StepContent
                title="Your final allocation"
                Detail="2.7899 MNT"
                date="2023-12-15 20:00"
                heading="The allocation calculation is complete. We will deduct the corresponding MNT from your
                account based on your final EDU allocation. Which will be transferred to your spot account
                along with your remaining MNT."
                deducted
              />
            ),
          },
        ]}
      />
    </div>
  );
};

export default LaunchpadStepList;
