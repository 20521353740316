import React, { useState } from "react";
import style from "./style.module.scss";
import HeadComman from "../../Common/HeadComman";
import { Row, Col } from "antd";
import { StoreImages } from "../../Storeimgaes/StoreImages";
import Custom_input from "../../Ui/customInput/Custom_input";
import { CheckOutlined } from "@ant-design/icons";

const Asset = (props) => {
  const [query, setQuery] = useState("");
  const { bitCoin } = StoreImages;
  const { onClick } = props;
  const CoinList = [
    {
      id: 1,
      img: bitCoin,
      coinName: "Bitcoin",
      coinAmount: "0 BTC",
      shortName: "BTC",
      amount: "$0.00",
    },
    {
      id: 2,
      img: bitCoin,
      coinName: "Bitcoin",
      coinAmount: "0 BTC",
      shortName: "BTC",
      amount: "$0.00",
    },
    {
      id: 3,
      img: bitCoin,
      coinName: "Bitcoin",
      coinAmount: "0 BTC",
      shortName: "BTC",
      amount: "$0.00",
    },
    {
      id: 4,
      img: bitCoin,
      coinName: "Bitcoin",
      coinAmount: "0 BTC",
      shortName: "BTC",
      amount: "$0.00",
    },
    {
      id: 5,
      img: bitCoin,
      coinName: "Bitcoin",
      coinAmount: "0 BTC",
      shortName: "BTC",
      amount: "$0.00",
    },
  ];
  return (
    <>
      <div className={`${style.Asset} `}>
        <HeadComman title="Select Asset" onClick={onClick} />

        <div className={style.Asset_body}>
          <div className={style.Asset_body_search}>
            <Custom_input
              placeholder="Search"
              value={query}
              onChange={(event) => setQuery(event.target.value)}
            />
          </div>

          {CoinList.filter((item) => {
            if (query === "") {
              return item;
            } else if (
              typeof item.id === "string" && // check if id is defined and is a string
              item.id.toLowerCase().includes(query.toLowerCase()) &&
              item.type === "coin"
            ) {
              return item;
            }
          }).map((item) => (
            <Row
              key={item.id} // use id as the key attribute
              className={`${style.Asset_body_list} ${style.active}`}
            >
              <Col>
                <div className={style.Asset_body_list_imgSec}>
                  <h6>
                    <span>
                      {" "}
                      <img src={item.img} alt="" />
                    </span>
                    <span>
                      {" "}
                      {item.coinName}
                      <br />
                      <span className={style.shortName}>{item.shortName}</span>
                    </span>
                  </h6>
                </div>
              </Col>
              <Col>
                <div className={style.Asset_body_list_check}>
                  <p>
                    {item.coinAmount}
                    <br />
                    <span>{item.amount}</span>
                  </p>
                  <span style={{ paddingLeft: 14 }}>
                    <CheckOutlined style={{ color: "#00a79e" }} />
                  </span>
                </div>
              </Col>
            </Row>
          ))}
        </div>
      </div>
    </>
  );
};

export default Asset;
