import React, { useState } from "react";
import { Row, Tabs, Table } from "antd";
import style from "./style.module.scss";
import { convertBackDate } from "../../../helpers";
import { staticPeriod } from "../../../staticObjects";
import { useDispatch } from "react-redux";
import FuturePortfolioFilters from "../ExchangePages/FutureOrders/futurePortfolioFilters";
import { customLocale } from "../../../helpers/customLocale";
const initialStats = {
  from: convertBackDate(staticPeriod.day).from_date || "",
  to: convertBackDate(staticPeriod.day).to_date || "",
  symbol: "",
  side: "",
  status: "",
  limit: 10,
  page: 1,
};
const FutureTransferHistory = () => {
  const [selectedTab, setSelectedTab] = useState("openOrders");
  const [fitlerData, setFilterdata] = useState(initialStats);
  const [isCallFromFilter, setIsCallFromFilter] = useState(false);

  const dispatch = useDispatch();

  const handleTabChange = (tabKey) => {
    setSelectedTab(tabKey);
  };

  const renderTableColumns = () => {
    if (selectedTab === "openOrders") {
      return [
        { dataIndex: "symbol", title: "Symbol" },
        { dataIndex: "time", title: "Time" },
        { dataIndex: "orderType", title: "Order Type" },
        { dataIndex: "side", title: "Side" },
        { dataIndex: "price", title: "Price" },
        { dataIndex: "filled", title: "Filled" },
        { dataIndex: "orderAmount", title: "Order Amount" },
        { dataIndex: "triggerConditions", title: "Trigger Conditions" },
        { dataIndex: "active", title: "Active" },

        // Add more columns as needed
      ];
    } else if (selectedTab === "orderHistory") {
      return [
        { dataIndex: "symbol", title: "Symbol" },
        { dataIndex: "time", title: "Time" },
        { dataIndex: "orderType", title: "Order Type" },
        { dataIndex: "side", title: "Side" },
        { dataIndex: "price", title: "Price" },
        { dataIndex: "avgPrice", title: "Avg Price" },
        { dataIndex: "executed", title: "Executed" },
        { dataIndex: "amount", title: "Amount" },
        { dataIndex: "triggerConditions", title: "Trigger Conditions" },
        { dataIndex: "status", title: "Status" },

        // Add more columns as needed
      ];
    } else if (selectedTab === "tradeHistory") {
      return [
        { dataIndex: "symbol", title: "Symbol" },
        { dataIndex: "time", title: "Time" },
        { dataIndex: "side", title: "Side" },
        { dataIndex: "price", title: "Price" },
        { dataIndex: "quantity", title: "Quantity" },
        { dataIndex: "fee", title: "Fee" },
        { dataIndex: "realizedProfit", title: "Realized Profit" },
      ];
    } else if (selectedTab === "transactionHistory") {
      return [
        { dataIndex: "asset", title: "Asset" },
        { dataIndex: "time", title: "Time" },
        { dataIndex: "type", title: "Type" },
        { dataIndex: "amount", title: "Amount" },
        { dataIndex: "symbol", title: "Symbol" },
      ];
    } else if (selectedTab === "positionHistory") {
      return [];
    } else if (selectedTab === "fundingFee") {
      return [
        { dataIndex: "asset", title: "Asset" },
        { dataIndex: "time", title: "Time" },
        { dataIndex: "amount", title: "Amount" },
        { dataIndex: "symbol", title: "Symbol" },
      ];
    }
  };

  const renderTableData = () => {
    // Fetch and return data based on the selected tab and filters
    // For example:
    return [
      {
        key: "1",
        symbol: "BTC",
        asset: "USDT",
        time: "3023-09-13",
        orderType: "Market",
        side: "Sell",
        type: "Commission",
        quantity: "109 USDT",
        fee: "0.01287 USDT",
        realizedProfit: "0.01829 USDT",
        price: "-",
        avgPrice: "26500",
        executed: "26 USDT",
        amount: "26 USDT",
        triggerConditions: "-",
        status: "Filled",
      },
      {
        key: "2",
        symbol: "BNB",
        asset: "USDT",
        time: "3023-09-13",
        orderType: "Market",
        side: "Buy",
        type: "Transfer",
        quantity: "109 USDT",
        fee: "0.01287 USDT",
        realizedProfit: "0.01829 USDT",
        price: "-",
        avgPrice: "26500",
        executed: "26 USDT",
        amount: "26 USDT",
        triggerConditions: "-",
        status: "Filled",
      },
      // Add more data as needed
    ];
  };

  const filterFun = async (obj) => {
    setFilterdata({ ...fitlerData, ...obj });
    // await dispatch(
    //   getOrderHistory({
    //     state: obj?.status,
    //     market: obj?.symbol,
    //     ...commonProp,
    //     page: obj.page,
    //     ...(obj.from && { from: obj.from }),
    //     ...(obj.to && { to: obj.to }),
    //     ...(obj.side && { type: obj.side }),
    //     tradeType: tradingType,
    //   })
    // );
  };

  return (
    <div className={style.futureTransferHistory}>
      <div className={style.heading}>
        <h2>{getTabHeading(selectedTab)}</h2>
        <h6>
          <span>USD$-M</span>
        </h6>
      </div>
      <Row gutter={16}>
        <FuturePortfolioFilters
          currentTab={selectedTab}
          cbFun={(obj) => {
            filterFun(obj);
            setIsCallFromFilter(true);
          }}
        />
      </Row>
      <div className={style.futureTabs}>
        <Tabs activeKey={selectedTab} onChange={handleTabChange}>
          <Tabs.TabPane tab="Open Orders" key="openOrders">
            <Table
              columns={renderTableColumns()}
              dataSource={renderTableData()}
              pagination={{ pageSize: 10 }}
              locale={customLocale}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Order History" key="orderHistory">
            <Table
              columns={renderTableColumns()}
              dataSource={renderTableData()}
              pagination={{ pageSize: 10 }}
              locale={customLocale}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Trade History" key="tradeHistory">
            <Table
              columns={renderTableColumns()}
              dataSource={renderTableData()}
              pagination={{ pageSize: 10 }}
              locale={customLocale}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Transaction History" key="transactionHistory">
            <Table
              columns={renderTableColumns()}
              dataSource={renderTableData()}
              pagination={{ pageSize: 10 }}
              locale={customLocale}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Position History" key="positionHistory">
            <Table
              columns={renderTableColumns()}
              dataSource={renderTableData()}
              pagination={{ pageSize: 10 }}
              locale={customLocale}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Funding Fee" key="fundingFee">
            <Table
              columns={renderTableColumns()}
              dataSource={renderTableData()}
              pagination={{ pageSize: 10 }}
              locale={customLocale}
            />
          </Tabs.TabPane>
        </Tabs>
      </div>
    </div>
  );
};

const getTabHeading = (tab) => {
  switch (tab) {
    case "openOrders":
      return "Open Orders";
    case "orderHistory":
      return "Order History";
    case "tradeHistory":
      return "Trade History";
    case "transactionHistory":
      return "Transaction History";
    case "positionHistory":
      return "Position History";
    case "fundingFee":
      return "Funding Fee";
    default:
      return "";
  }
};

export default FutureTransferHistory;
