import React from "react";
import { useNavigate } from "react-router-dom";
import { publicRouteObj } from "../../../staticObjects";
import { rootName } from "../../../utils/constant";
import { StoreImages } from "../../Storeimgaes/StoreImages";
import style from "./style.module.scss";

function SignInHeader(props) {
  const { isSignIn = false } = props;
  const { logo } = StoreImages;
  const navigate = useNavigate();
  return (
    <div className={style.signinheader}>
      <img src={logo} alt="logo" onClick={() => navigate(`${rootName}`)} />
      {isSignIn && (
        <ul>
          <li
            onClick={() => {
              navigate(`${rootName}${publicRouteObj.login}`);
            }}
          >
            Log In
          </li>
        </ul>
      )}
    </div>
  );
}

export default SignInHeader;
