import React, { useCallback, useEffect, useState } from "react";
import { Select, Form, Button } from "antd";
import style from "./style.module.scss";
import { ButtonCustom } from "../../../Ui/button/ButtonCustom";
import { StoreImages } from "../../../Storeimgaes/StoreImages";
import { useDispatch, useSelector } from "react-redux";
import {
  depositPost,
  fetchAllBank,
  fetchBankDetail,
  getProfileData,
  qpayPost,
  socialpayPost,
} from "../../../../redux/services";
import { useTranslation } from "react-i18next";
import Custom_input from "../../../Ui/customInput/Custom_input";
import {
  copytoClipBoard,
  debouncing,
  isLevel3,
  multiple0BeforeDecimal,
  regex,
} from "../../../../helpers";
import { bankAmountReset } from "../../../../redux/feature";
import { QRCodeSVG } from "qrcode.react";
import copy from "../../../Assets/Images/copy.svg";
import Identityverify from "../../../Common/Identityverify";

const BankDepositeTab = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { MNT } = StoreImages;
  const [selectedBank, setSelectedBank] = useState("");
  const [selectedBankName, setSelectedBankName] = useState("");
  const { userData } = useSelector((state) => state.setting);
  const { data } = useSelector((state) => state.user);
  const [amount, setAmount] = useState("");
  const [errors, setErrors] = useState("");

  const { allBankList, payData, detailBankList } = useSelector(
    (state) => state?.sendReceive
  ); 

  const detailBank = detailBankList;
  const bankDetailList = detailBank?.length > 0 && detailBank[0];

  const barCode = payData?.qr_image;
  let userPhnNoFilter =
    data?.phones.length > 0 &&
    data?.phones.filter((item) => item.validated_at !== null);
  let userPhnNo = userPhnNoFilter.length > 0 ? userPhnNoFilter[0].number : "";
  // data?.phones[0]?.validated_at !== null &&
  //   data?.phones[0]?.validated_at !== undefined
  //   ? data?.phones[0]?.number
  //   : "";

  let profileType = userData?.account_type;
  let userUID =
    profileType !== "corporate"
      ? Object.keys(userData).length > 0 && userData?.id_number
      : "";

  let nameOfUser =
    profileType === "corporate"
      ? userData?.organization_details?.length > 0
        ? userData?.organization_details?.filter(
            (item) => item.another_organization_details === false
          )[0]?.name
        : userData?.organization_details?.name
      : userData?.first_name + " " + userData?.last_name;

  // let userName =
  //   data?.profiles?.length > 0 && data?.profiles[0]?.first_name !== null &&
  //     data?.profiles[0]?.last_name !== null
  //     ? ` ${data?.profiles[0]?.first_name} ${data?.profiles[0]?.last_name}`
  //     : ""; 
  const bankNameDropdown =
    allBankList?.length > 0 &&
    allBankList?.map((item) => {
      return {
        value: item.id,
        label:
          item.bank_name === "Q-pay"
            ? "qPay"
            : item.bank_name === "Social-pay"
            ? "SocialPay"
            : item.bank_name,
      };
    });

  useEffect(() => {
    dispatch(fetchAllBank());

    // dispatch(getProfileData());
  }, []);

  useEffect(() => {
    if (allBankList.length) {
      let defaultBank = allBankList?.filter(
        (item) => item.bank_name === "Khan Bank"
      );
      setSelectedBank(defaultBank[0]?.id);
      setSelectedBankName(defaultBank[0]?.bank_name);
    }
    // dispatch(getProfileData());
  }, [allBankList]);

  useEffect(() => {
    if (selectedBankName === "qPay" || selectedBankName === "SocialPay") {
      dispatch(bankAmountReset());
    } else if (selectedBank !== "") {
      dispatch(
        fetchBankDetail({
          id: selectedBank,
        })
      );
    }
    setAmount("");
  }, [selectedBank]);

  const listing = [
    {
      id: 1,
      title: `${t("BankDeposit.maintabs.BankAccount")}`,
      main: `${bankDetailList.account_number}`,
    },
    {
      id: 2,
      title: `${t("BankDeposit.maintabs.AccountName")}`,
      main: `${bankDetailList.bank_account_holder_name}`,
    },
    {
      id: 3,
      title: `${t("BankDeposit.maintabs.ReceivedBank")}`,
      main: `${bankDetailList.bank_name}`,
    },
    {
      id: 4,
      title: `${t("BankDeposit.maintabs.TransactionValue")}`,
      main: (
        <span
          style={{ color: "#00A79E", cursor: "pointer" }}
          onClick={() => copytoClipBoard(`+${userPhnNo} ${userUID}`,t)}
        >
          {`${userPhnNo ? "+" + userPhnNo : ""} ${userUID}`}
          <img className="copyIcon" src={copy} />
        </span>
      ),
    },
  ];

  const onChange = (value, label) => {
    let data = label.label;
    setSelectedBank(value);
    setSelectedBankName(data);
  };
  const onSearch = (value) => {
    console.log("search:", value);
  };
  const delayToCallApi = useCallback(
    debouncing((entered_val) => {
      selectedBankName === "qPay"
        ? dispatch(
            qpayPost({
              amount: entered_val,
            })
          )
        : dispatch(
            socialpayPost({
              amount: entered_val,
            })
          );
    }, 1000),
    [selectedBankName]
  );
  const handleChange = (e) => {
    let entered_val = e.target.value;
    let name = e.target.name;
    const amountRegex = regex(6, 10);

    if (name === "amount") {
      entered_val = multiple0BeforeDecimal(entered_val);
    }

    if (entered_val === "" || amountRegex.test(entered_val)) {
      setAmount(Number(entered_val));
    }

    if (entered_val !== "") {
      delayToCallApi(entered_val);
    }
  };
  const onSubmit = (type) => {
    let sendData = {
      currency: "mnt",
      bank_name: selectedBankName,
      id: selectedBank,
    };
    if (type === "withAmount") {
      sendData["amount"] = amount;
    }
    dispatch(depositPost(sendData));
  };
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  if (!isLevel3(data?.level)) {
    return <Identityverify />;
  }
  return (
    <>
      <div className={style.bar}>
        <h4>
          <span>{t("BankDeposit.maintabs.Currency")}</span>
          <img src={MNT} alt="icon" />
          MNT
        </h4>
      </div>
      {selectedBank !== "" && (
        <div className={style.DepositContent}>
          <Form.Item
            label={t("BankDeposit.maintabs.ChooseBankAccountlabel")}
            name=""
          >
            <Select
              style={{ width: "100%" }}
              className="customSelect"
              showSearch
              placeholder={t("BankDeposit.maintabs.ChooseBankAccountlabel")}
              optionFilterProp="children"
              defaultValue={selectedBank}
              value={selectedBank}
              onChange={onChange}
              onSearch={onSearch}
              filterOption={filterOption}
              options={bankNameDropdown}
            />
          </Form.Item> 
          {selectedBankName === "qPay" || selectedBankName === "SocialPay" ? (
            <>
              <div>
                <Custom_input
                  label={`Amount`}
                  placeholder={t("market.enter_amount")}
                  name="amount"
                  value={amount}
                  onChange={handleChange}
                  max={100}
                  height={"50px"}
                  customClass={"customInputWithdarw"}
                />
                {errors && (
                  <span className={`errorCls ${style.errorText}`}>
                    {errors}
                  </span>
                )}
              </div>
              {barCode && (
                <div className={style.barCodeImage}>
                  <img alt="QR Code" src={"data:image/png;base64," + barCode} />
                </div>
              )}
              {payData.qr_text && selectedBankName === "SocialPay" && (
                <div className={style.barCodeImage}>
                  <QRCodeSVG
                    value={payData.qr_text}
                    height={"200px"}
                    width={"200px"}
                  />
                </div>
              )}
            </>
          ) : (
            <>
              <ul className={style.DepositContent_bankList}>
                {listing.map((text) => (  
                  <li
                    key={text.id}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>{text.title}</span>
                    <span>{text.main}</span>
                  </li>
                ))}
              </ul>
            </>
          )}
          <p>{t("BankDeposit.maintabs.note")}</p>
          {/* {selectedBankName === "qPay" || selectedBankName === "SocialPay" ? (
          <ButtonCustom
            label={t("BankDeposit.maintabs.dbtn")}
            greenbtn
            disabled={amount !== "" && amount > 999 ? false : true}
            onClick={(tyoe) => onSubmit( tyoe= "withAmount" )}
          />
        ) : (
          <ButtonCustom
            label={t("BankDeposit.maintabs.dbtn")}
            greenbtn
            onClick={onSubmit}
          />
        )} */}
        </div>
      )}
    </>
  );
};

export default BankDepositeTab;
