import React, { useEffect } from "react";
import "./style.scss";

function Privacy() {
  useEffect(() => {
    document.body.classList.add("policyPages");
    return () => {
      document.body.classList.add("policyPages");
    };
  }, []);
  return (
    <div>
      <div class="page">
        <div class="header">
          <div class="container">
            <p class="title">“COINHUB” НУУЦЛАЛЫН БОДЛОГО</p>
          </div>
        </div>
        <div class="translations-content-container">
          <div class="container">
            <div
              class="tab-content translations-content-item en visible"
              id="en"
            >
              {/* <h1>Privacy Policy</h1> */}
              <p className="textRight mb-15">
                Сүүлд өөрчлөлт оруулсан огноо: 2022 оны 10-р сарын 13-ны өдөр
              </p>
              <p className="mb-15">
                Энэхүү Нууцлалын бодлого Таны (“Та” эсвэл “Харилцагч”)-г
                “COINHUB” цахим биржид нэвтэрч, санал болгож буй үйлчилгээг
                ашиглах үед Виртуал хөрөнгийн үйлчилгээ үзүүлэгч (“COINHUB”)-ээс
                Таны хувийн мэдээллийг цуглуулах, боловсруулах, ашиглах,
                дамжуулахтай холбоотой харилцааг зохицуулна.
              </p>
              <p className="mb-15">
                Харилцагч цахим биржид нэвтэрч, виртуал хөрөнгийн үйлчилгээ авах
                үед Монгол Улсын Хүний хувийн мэдээлэл хамгаалах тухай хуульд
                нийцүүлэн Таны хувийн мэдээллийг “COINHUB” цуглуулж,
                боловсруулж, ашиглана.
              </p>
              <p className="mb-15">
                Та “Нууцлалын бодлого”-той бүрэн танилцах үүрэгтэй.
              </p>
              <p className="mb-15">
                Харилцагч Нууцлалын бодлогод заасан хувийн мэдээллийг цуглуулах,
                боловсруулах, ашиглах, дамжуулахтай холбоотой бүхий л
                нөхцөлүүдийг бүрэн хүлээн зөвшөөрсөн тохиолдолд “Би зөвшөөрч
                байна” гэсэн хэсгийг дарснаар цахим биржийн үйлчилгээг ашиглах
                явцад өөрийн хувийн мэдээллийг цуглуулж, боловсруулж, ашиглах,
                дамжуулах цахим зөвшөөрлийг COINHUB-д олгосон гэж үзнэ.
              </p>
              <h2 className="mb-15">
                ХАРИЛЦАГЧИЙН ХУВИЙН МЭДЭЭЛЛИЙГ ЦУГЛУУЛАХ, БОЛОВСРУУЛАХ, АШИГЛАХ
                ҮНДЭСЛЭЛ, ЗОРИЛГО
              </h2>
              <ol className="">
                <li>
                  <p>
                    “COINHUB” Таныг www.coinhub.mn вэбсайт, iOS болон Аndroid
                    үйлдлийн системийг дэмждэг төхөөрөмжүүдэд зориулсан
                    аппликейшн болон цахим хэрэгслүүдээр таньж, баталгаажуулсны
                    үндсэн дээр виртуал хөрөнгийн арилжаа болон бусад үйлчилгээг
                    үзүүлнэ.
                  </p>
                </li>
                <li>
                  <p>
                    “СOINHUB” Таныг таньж, баталгаажуулах, харилцагчийн бүртгэл
                    үүсгэх, санал болгож буй үйлчилгээг шууд хүргэх явцад Танаас
                    олгосон цахим зөвшөөрлийг үндэслэн хувийн мэдээллийг
                    цуглуулж, боловсруулж, ашиглана.
                  </p>
                </li>
                <li>
                  <p>
                    Цахим биржийн тасралтгүй, найдвартай ажиллагааг хангах,
                    системийг хөгжүүлэх, Харилцагчийн хүсэл, сонирхолд нийцсэн
                    үйлчилгээг нэвтрүүлэх, хэрэглэхэд хялбар, шинэлэг шийдлийг
                    хүргэх зорилгоор Харилцагчийн хувийн мэдээллийг цуглуулж,
                    боловсруулж ашиглана.
                  </p>
                </li>
                <li>
                  <p>
                    Харилцагч цахим биржээс шаардсан мэдээллийг үнэн зөв оруулах
                    үүрэгтэй, мэдээллийг дутуу эсхүл зөрүүтэй оруулснаас үүдэж
                    цахим биржийн үйлчилгээг авах боломжгүй болох, үүнээс үүдэж
                    “COINHUB” болон бусад Харилцагчид учирсан хохирлыг бие даан
                    хариуцна.
                  </p>
                </li>
                <li>
                  <p>
                    “COINHUB” Харилцагчийн мэдээллийг цуглуулж, боловсруулах,
                    ашиглах ажиллагаанд Таны хувийн мэдээллийн үнэн зөв, бүрэн
                    бүтэн байдлыг алдагдуулахгүй байх, мэдээллийн аюулгүй
                    байдлыг хангах зарчмуудыг баримтална.
                  </p>
                </li>
                <li>
                  <p>
                    Харилцагч Нууцлалын бодлогод заасан нөхцөл, хэрэгжилттэй
                    холбоотой асуудлаар “Койн Хаб” ХХК-ийн support.coinhub.mn
                    вэбсайт болон 75076060 утасны дугаарт холбогдож, санал,
                    хүсэлт, гомдлоо шийдвэрлүүлэх эрхтэй.
                  </p>
                </li>
              </ol>

              <h2 className="mb-15">
                ЦУГЛУУЛЖ, БОЛОВСРУУЛЖ, АШИГЛАХ ХУВИЙН МЭДЭЭЛЛИЙН ЖАГСААЛТ
              </h2>
              <ol>
                <li>
                  <p>
                    “СOINHUB” Таны мэдээллийг дараах цахим эх үүсвэрүүдээс
                    хүлээн авч, хадгалж, боловсруулна. Үүнд: www.coinhub.mn,
                    COINHUB APP, “COINHUB” цахим хэтэвч,  болон Танд санал
                    болгож буй үйлчилгээг үзүүлэхэд хамтарч буй веб сайт, гар
                    утасны программ хангамж болон бусад цахим технологи
                    хамаарна.
                  </p>
                </li>
                <li>
                  <p className="mb-15">
                    “COINHUB”-аас санал болгож буй үйлчилгээг хүргэх зорилгоор
                    Харилцагчаас оруулсан дараах мэдээллүүд:
                   </p>
                    <ol>
                      <li>
                        <p className="mb-15"> 
                        Харилцагчийг таньж, баталгаажуулах зорилгоор иргэн бол
                        овог, нэр, төрсөн огноо, оршин суугаа газрын хаяг,
                        иргэний үнэмлэх, гадаад паспорт, зураг хуулийн этгээд
                        бол хүчин төгөлдөр улсын бүртгэлийн гэрчилгээ, дүрэм,
                        эрх бүхий этгээд, эцсийн өмчлөгчийн иргэний үнэмлэх
                        зэрэг шууд болон шууд бусаар тодорхойлох, эсхүл
                        тодорхойлох боломжтой бусад мэдээллийг;
                        </p>
                      </li>
                      <li>
                        “СOINHUB”-аас санал болгож буй үйлчилгээг ашиглах явцад
                        бий болсон арилжаа болон бусад үйлчилгээний мэдээлэл;
                      </li>
                      <li>
                        Харилцагчийн бүртгэлийн мэдээлэл, харилцагчийн хувийн
                        нууц код, бүртгэлийн дугаар, баталгаажуулах и-мэйл хаяг,
                        цахим гарын үсэг;
                      </li>
                      <li>
                        Банкны дансны мэдээлэл, , гүйлгээний түүх, арилжааны
                        мэдээлэл, татварын мэдээлэл зэрэг санхүүгийн мэдээлэл;
                      </li>
                      <li>
                        Хүлээн авагчийн нэр, Таны нэр, мөнгөн дүн, гүйлгээ
                        шилжүүлсэн болон баталгаажсан огноо зэрэг цахим бирж
                        дээр хийгдсэн гүйлгээний мэдээлэл;
                      </li>
                      <li>
                        Таны ирүүлсэн асуулт, хүсэлтийн мэдээлэл, түүнийг
                        шийдвэрлэж хариу өгсөн талаарх мэдээлэл;
                      </li>
                    </ol>
                </li>
                <li>
                  <p className="mb-15">
                    Харилцагчийг цахим биржид нэвтэрсэн цагаас эхлэн тодорхой
                    үйлчилгээг авч байх хугацаанд тухайн төрлийн үйлчилгээг
                    сайжруулж, хөгжүүлэх, Танд хялбаршуулсан хэрэглээг бий
                    болгох, зөвшөөрөлгүй хандалтыг тодорхойлох зорилгоор тухай
                    бүр дараах мэдээллийг автоматаар цуглуулна. Үүнд:
                    </p>
                    <ol>
                      <li>
                        <p>
                          Газар зүйн байршил, үйлдлийн систем, веб хөтчийн нэр
                          болон хувилбар, IP хаяг, цахим протокол, цахим биржид
                          нэвтэрсэн хугацааны мэдээлэл, хандсан мэдээллийн
                          урсгал;
                        </p>
                      </li>
                      <li>
                        <p>
                          Таныг баталгаажуулах нууц код, цахим өгөгдөл, аюулгүй
                          байдлын асуулт, хариулт, хандсан мэдээллийн урсгал,
                          мэдээллийг хуваалцсан, коммент, тайлбарын өгөгдлийн
                          сан;
                        </p>
                      </li>
                      <li>
                        <p>
                          Таны Харилцагчийн идэвхтэй ашиглаж буй төхөөрөмжийн
                          мэдээлэл;
                        </p>
                      </li>
                      <li>
                        <p>
                          Cookies файлын тусламжтайгаар (вэбсайт эсхүл
                          төхөөрөмжийн хөтөч дээр хадгалагдсан мэдээлэл, хандсан
                          мэдээллийн эх сурвалж) цуглуулсан дараах мэдээллүүд.
                          Үүнд:
                          <ol>
                            <li>
                              <p>
                                Цахим биржийн найдвартай тасралтгүй үйл
                                ажиллагаа болон аюулгүй байдлыг хангах хүрээнд
                                үйлчилгээ авах, системд нэвтрэх, бүртгэл хийх
                                холбоотой мэдээлэл;
                              </p>
                            </li>
                            <li>
                              <p>
                                Цахим биржийн бүтээгдэхүүн, үйлчилгээг
                                сайжруулах зорилгоор Харилцагчийн хамгийн их
                                эсхүл хамгийн бага хандалттай бүтээгдэхүүн,
                                үйлчилгээний мэдээлэл;
                              </p>
                            </li>
                            <li>
                              <p>
                                Цахим биржийг чөлөөтэй ашиглах нөхцөлийг
                                бүрдүүлэх үүднээс Таны сонгосон улсын хэл дээр
                                мэдээллийг санал болгох;
                              </p>
                            </li>
                          </ol>
                        </p>
                      </li>
                    </ol>
                </li>
                <li>
                  <p className="mb-15">
                    Гуравдагч этгээд хууль, тогтоомжид заасан үндэслэлээр Таны
                    дараах мэдээллийг цуглуулж, боловсруулж болно. Үүнд:
                    </p>
                    <ol>
                      <li>
                        <p>
                          Мөнгө угаах болон терроризмыг санхүүжүүлэхтэй тэмцэх
                          тухай хууль, зохицуулагч байгууллагаас баталсан журамд
                          заасны дагуу харилцагчийн хувийн болон арилжааны
                          талаарх мэдээлэл;
                        </p>
                      </li>
                      <li>
                        <p>
                          Блокчэйн өгөгдөлд дүн шинжилгээ, судалгаа хийж,
                          хөгжүүлэх зорилгоор нийт Харилцагчийн гүйлгээний чиг
                          хандлагад хийсэн дүн шинжилгээний мэдээлэл;
                        </p>
                      </li>
                      <li>
                        <p>Монгол Улсын хуулиар зөвшөөрсөн бусад мэдээлэл;</p>
                      </li>
                      <li>
                        <p>
                          “ВХҮҮ-ийн үйл ажиллагааны журам”-ын 3.2-ын дагуу
                          3,000,000 (гурван сая) төгрөг болон түүнээс дээш
                          дүнтэй виртуал хөрөнгийг шилжүүлэхэд бүртгэлийн болон
                          хэтэвчний холбогдох мэдээллийг хүлээн авагч талын
                          эсвэл энэхүү мэдээллийг дамжуулах чиг үүрэг бүхий
                          гуравдагч этгээдэд хүргүүлж болно.
                        </p>
                      </li>
                    </ol>
\                </li>
              </ol>
              <h2 className="mb-15">
                МЭДЭЭЛЛИЙГ БОЛОВСРУУЛАХ, АШИГЛАХ НӨХЦӨЛ, ХУГАЦАА
              </h2>
              <ol>
                <li>
                  <p className="mb-15">
                    “COINHUB” Таны хэрэгцээнд нийцсэн үйлчилгээг санал болгох,
                    цахим биржийг хөгжүүлэх, хувийн мэдээллийг хамгаалах, цахим
                    халдлагаас сэргийлэх арга хэмжээг боловсронгуй болгох,
                    залилах болон мөнгө угаах, терроризмыг санхүүжүүлэх гэмт
                    хэргээс урьдчилан сэргийлэх үйл ажиллагааг тасралтгүй
                    хэрэгжүүлэх үүднээс дараах тохиолдлуудад таны хувийн
                    мэдээллийг боловсруулж ашиглана. Үүнд:
                    </p>
                    <ol>
                      <li>
                        <p>
                          Харилцагчийг таньж мэдэх, баталгаажуулах үйл
                          ажиллагаанд;
                        </p>
                      </li>
                      <li>
                        <p>
                          Үйлчилгээний нөхцөлд заасан эрхийн хэрэгжилт, үүргийн
                          биелэлтэд хөндлөнгийн хяналт тавих явцад;
                        </p>
                      </li>
                      <li>
                        <p>
                          Гэрээ байгуулсан тохиолдолд гэрээний үүргийн
                          биелэлтийг хангуулах шаардлага гаргахад;
                        </p>
                      </li>
                      <li>
                        <p>
                          “COINHUB”-ын үйлчилгээг сайжруулах судалгааг авч, үр
                          дүнг хэрэгжүүлэхэд;
                        </p>
                      </li>
                      <li>
                        <p>
                          Гэмт хэргээс урьдчилан сэргийлэх болон илрүүлэх
                          ажиллагаанд;
                        </p>
                      </li>
                      <li>
                        Төлбөрийн гүйлгээг хийх үед мэдээллийг дамжуулах, хянан
                        баталгаажуулах тохиолдолд;
                      </li>
                      <li>
                        <p>
                          “COINHUB”-ийн санал болгож буй үйлчилгээг хэрэглэх
                          бүрд;
                        </p>
                      </li>
                      <li>
                        <p>
                          “COINHUB”-ыг Тантай холбох, үүссэн асуудлыг шийдвэрлэх
                          явцад;
                        </p>
                      </li>
                      <li>
                        <p>Мэдээллийн аюулгүй байдлыг хангахад;</p>
                      </li>
                      <li>
                        <p>Цахим биржийг хөгжүүлэх бүхий л шатанд;</p>
                      </li>
                      <li>
                        <p>Маркетингийн үйл ажиллагааг зохион байгуулах үед;</p>
                      </li>
                      <li>
                        <p>
                          Харилцагчид зориулсан шинэ, хялбар хэрэглээг
                          нэвтрүүлэх, хөгжүүлэх ажлыг гүйцэтгэх явцад;
                        </p>
                      </li>
                      <li>
                        <p>
                          Арилжаа, худалдан авалт, төлбөрийн гүйлгээг хөнгөвчлөх
                          ажлыг зохион байгуулах, ажлын үр дүнг хэрэглээнд
                          нэвтрүүлэх үед;
                        </p>
                      </li>
                      <li>
                        <p>
                          Харилцагч болон бусад этгээдээс ирүүлсэн гомдлыг
                          шалгах явцад;
                        </p>
                      </li>
                    </ol>
                </li>
                <li>
                  <p>
                    “COINHUB” Таны хувийн мэдээллийг харилцагчаар бүртгүүлсэн
                    өдрөөс эхлэн бүртгэлийг хаах хүртэлх хугацаанд боловсруулж,
                    ашиглана.
                  </p>
                </li>
                <li>
                  <p>
                    “COINHUB” Харилцагчийн хувийн халдашгүй байдлыг хүндэтгэн
                    дээдлэх зарчмыг баримталж, хувийн мэдээллийг хууль
                    тогтоомжид заасан хугацаагаар хадгална.
                  </p>
                </li>
                <li>
                  <p>
                    Харилцагчийн хувийн мэдээллийн аюулгүй байдлыг хангах
                    тогтолцоог хөгжүүлэх арга хэмжээг тогтмол зохион байгуулна.
                    Таны хувийн мэдээллийн бүрэн бүтэн аюулгүй байдал алдагдаж,
                    бүртгэлийн аккаунтад зөвшөөрөлгүй нэвтрэх, мэдээллийг
                    задруулах эрсдэлийг буруулах үүднээс аюулгүй байдлыг хангах
                    арга хэмжээг авч ажиллана.
                  </p>
                </li>
                <li>
                  <p>
                    Харилцагчийн хувийн мэдээлэл хандах эрх бүхий албан
                    тушаалтан Таны хувийн мэдээллийг гуравдагч этгээдэд
                    задруулах эсхүл өөрийн хувийн сонирхолд нийцүүлэн
                    задруулахгүй байх үүргийг хүлээнэ. Хориглосон зохицуулалтын
                    зөрчсөн эрх бүхий албан тушаалтан хууль зүйн хариуцлага
                    хүлээнэ.
                  </p>
                </li>
                <li>
                  <p>
                    Хэрэв Та өөрийн хайхрамжгүй байдлаас шалтгаалж хандах эрхийн
                    мэдээллээ алдсан эсхүл бусдад шилжүүлсний улмаас үүсэх үр
                    дагаврыг өөрөө хариуцна. Иймд Та өөрийн нэвтрэх нэр, нууц
                    үг, цахим гарын үсэг, баталгаажуулах асуулт, хариулт,
                    гүйлгээний нэг удаагийн код зэрэг мэдээллийг бусдад
                    дамжуулахгүй байх арга хэмжээг авч, хувийн мэдээллийн
                    нууцлалыг хадгална уу.
                  </p>
                </li>
                <li>
                  <p>
                    Хэрэв Та хувийн мэдээллээ алдсан эсхүл гуравдагч этгээд Таны
                    бүртгэлийн аккаунтад нэвтэрсэн байх тохиолдолд тэр даруйд
                    “COINHUB”-д мэдэгдэх үүрэгтэй.
                  </p>
                </li>
                <li>
                  <p>
                    Мэдээлэл цуглуулах, боловсруулах явцад илэрсэн зөрчил нь
                    Харилцагчийн эрх, хууль ёсны ашиг сонирхолд хохирол
                    учруулахаар бол зөрчлийг мэдсэн даруйд Харилцагчид мэдэгдэл
                    хүргүүлнэ. Тус мэдэгдэлд үүссэн зөрчил, түүний улмаас учирч
                    болзошгүй үр дагавар, сөрөг үр дагаврыг арилгах талаар авсан
                    хариу арга хэмжээ болон СOINHUB-тай холбоо барих мэдээллийг
                    тус тус тусгана.
                  </p>
                </li>
              </ol>
              <h2 className="mb-15">МЭДЭЭЛЛИЙГ НИЙТЭД ИЛ БОЛГОХ НӨХЦӨЛ</h2>
              <ol>
                <li>
                  <p>
                    Харилцагчийн хувийн мэдээллийг гуравдагч этгээдээс нууцлах
                    зарчмыг баримтална.
                  </p>
                </li>
                <li>
                  <p>
                    Хуульд тусгайлан заагаагүй бол “COINHUB” бусад байгууллага,
                    хувь хүний шаардлагаар,
                  </p>
                </li>
                <li>
                  <p>
                    эсхүл өөрсдийн санаачилгаар Хэрэглэгчийн хувийн мэдээллийг
                    нийтэд ил болгохгүй.
                  </p>
                </li>
              </ol>
              <h2 className="mb-15">МЭДЭЭЛЛИЙГ ДАМЖУУЛАХ НӨХЦӨЛ</h2>
              <ol>
                <li>
                  <p>
                    Монгол Улсын хуулийн дагуу Таны хувийн мэдээллийг Төрийн эрх
                    бүхий байгууллагаас шаардсан тохиолдолд “COINHUB” мэдээллийг
                    шилжүүлэх үүрэгтэй.
                  </p>
                </li>
                <li>
                  <p>
                    “СOINHUB” Таны хэрэгцээнд нийцүүлэн үйлчилгээ үзүүлэх
                    зорилгоор бусад байгууллагаас санал болгож буй виртуал
                    хөрөнгийн арилжаанд оролцох, бусад виртуал хөрөнгийн
                    үйлчилгээг авах боломжийг бүрдүүлнэ. Энэ нөхцөлд Таны
                    олгосон энэ цахим зөвшөөрлийн дагуу таныг тодорхойлох
                    боломжгүй болгох замаар хувийн мэдээлэлд боловсруулалт хийж,
                    хэрэглэгчийн зан төлөв, хандлагын судалгааны үр дүнг виртуал
                    хөрөнгө гаргагч, үйлчилгээ үзүүлэгч нарт дамжуулна. Тухайн
                    мэдээллийг хүлээн авсан байгууллага нь Нууцлалын бодлогыг
                    чанд мөрдөнө. Үүргээ биелүүлээгүйн улмаас Харилцагчид
                    учирсан хохирлыг тухайн этгээд хариуцна.
                  </p>
                </li>
                <li>
                  <p>
                    “COINHUB” хуульд зааснаас бусад тохиолдолд Таны хувийн
                    мэдээллийг төрийн байгууллага болон тус цахим биржид үйл
                    ажиллагаа явуулдаггүй байгууллагад гаргаж өгөхгүй, Таны
                    мэдээллийн нууцлалыг хадгална.
                  </p>
                </li>
                <li>
                  <p>
                    Таны хувийн мэдээллийг гуравдагч этгээдэд ашиг олох
                    зорилгоор дамжуулахгүй, түгээхгүй, худалдахгүй бөгөөд
                    үйлчилгээний хэрэгцээнээс гадуур ашиг олох зорилгоор
                    ашиглахгүй болно.u
                  </p>
                </li>
              </ol>
              <h2 className="mb-15">
                ХАРИЛЦАГЧИЙН ХУВИЙН ХАЛДАШГҮЙ БАЙХ ЭРХИЙН ХАМГААЛАЛТ
              </h2>
              <ol>
                <li>
                  <p>
                    Харилцагч хувийн халдашгүй байх эрхээ хэрэгжүүлэх хүрээнд
                    цахим зөвшөөрлөө хэдийд ч цуцлах эрхтэй. Цахим зөвшөөрлийг
                    цуцлах хүсэлтийг www.support.coinhub.mn веб сайтаар илгээж,
                    шийдвэрлүүлнэ.
                  </p>
                </li>
                <li>
                  <p>
                    Харилцагчийн гаргасан цахим зөвшөөрлийг цуцлах хүсэлтийг
                    ажлын 10 хоногийн дотор шийдвэрлэж, хариу мэдэгдэх болно.
                  </p>
                </li>
                <li>
                  <p>
                    COINHUB Харилцагчийн хүсэлтээр түүний мэдээлэлд өөрчлөлт
                    оруулах, засах арга хэмжээг зохион байгуулна.
                  </p>
                </li>
              </ol>
              <h2 className="mb-15">
                ХҮҮХДИЙН ХУВИЙН МЭДЭЭЛЛИЙГ ХАМГААЛАХ ЖУРАМ
              </h2>
              <ol>
                <li>
                  <p>
                    “COINHUB” 18 насанд хүрээгүй хүний мэдээллийг цуглуулж,
                    боловсруулж, ашиглахгүй байх зарчмыг баримтална.
                  </p>
                </li>
                <li>
                  <p>
                    “COINHUB” 18 насанд хүрээгүй иргэн харилцагчаар
                    бүртгүүлснийг илрүүлсэн эсхүл бусад Харилцагчаас ирүүлсэн
                    мэдээллийг судалсны үндсэн дээр насанд хүрээгүй хүн болох нь
                    тогтоогдсон тохиолдолд тухайн Харилцагчийн мэдээллийг устгах
                    арга хэмжээг авна.
                  </p>
                </li>
              </ol>
              <h2 className="md-15">НУУЦЛАЛЫН БОДЛОГЫН ШИНЭЧЛЭЛТ</h2>
              <ol>
                <li>
                  <p>
                    “COINHUB” Нууцлалын бодлогод нэмэлт өөрчлөлт оруулах эрхтэй
                    ба Нууцлалын бодлогод нэмэлт өөрчлөлт оруулсан тохиолдолд
                    тухай бүр www.coinhub.mn цахим биржид нээлттэй нийтэлнэ.
                    Харилцагч тухай бүр оруулсан нэмэлт, өөрчлөлттэй танилцах
                    үүрэг хүлээнэ.
                  </p>
                </li>
                <li>
                  <p>
                    Таныг үйлчилгээ авах явцад Нууцлалын бодлогод нэмэлт,
                    өөрчлөлт орсон тохиолдолд хамгийн сүүлд өөрчлөгдсөн
                    Нууцлалын бодлогыг баримтална.
                  </p>
                </li>
              </ol>
            </div>
          </div>
        </div>

        {/* <div class="footer">
          <div class="container">
            <p>
              Generated using
              <a
                href="https://www.freeprivacypolicy.com/free-privacy-policy-generator/"
                target="_blank"
              >
                Free Privacy Policy Generator
              </a>
            </p>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default Privacy;
