import React, { useEffect } from "react";
import style from "./style.module.scss";
import CommonButton from "../../Ui/button/CommonButton";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Trans, useTranslation } from "react-i18next";
import Kyc from "../../Assets/Images/kyc.png";
import { primaryRoutes } from "../../../staticObjects";
import { rootName } from "../../../utils/constant";
import { useNavigate } from "react-router-dom";
import { getLabel } from "../../../helpers";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../../../redux/services";
function Identityverify({ cbOnClick, title }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const geturl = window.location.pathname;
  const { data } = useSelector((state) => state?.user);
  const navigate = useNavigate();
  const { level, labels } = data;
  const labelObj = getLabel(labels, "document");

  // useEffect(() => {
  //   dispatch(getUser({ isNull: true }));
  // }, []);

  const goSettingpage = () => {
    if (typeof cbOnClick === "function") {
      cbOnClick();
    }
    navigate(`${rootName}${primaryRoutes.setting}`);
  };

  return (
    <div className={`${style.profileKycSec} ${style.profileKycmodal}`}>
      <img src={Kyc} />

      {!labelObj || ["rejected"].includes(labelObj?.value) ? (
        <h4 className="kycHeadingTExt">
          {" "}
          Your KYC has been rejected by compliance,<br/> Please Re-Submit your KYC.
        </h4>
      ) : (
        <h4 className="kycHeadingTExt"> Please complete your KYC</h4>
      )}

      <p>
        Please take a moment to verify some additional information to complete
        your account
      </p>
      <CommonButton
        title={
          geturl === "/setting"
            ? title ?? "Start Your KYC"
            : "Go To Setting page"
        }
        onClick={geturl === "/setting" ? cbOnClick : goSettingpage}
      />
    </div>
  );
}
export default Identityverify;
