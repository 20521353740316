import { Table } from 'antd';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import CustomPagination from '../../customHooks/customPagination';
import { getSecurityActivity } from '../../../redux/services/securityServie';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { customLocale } from '../../../helpers/customLocale';

const SecurityActivity = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { secureActivityList, totalSecureActivity } = useSelector(state => state.security);

    const [pageNo, setPageNo] = useState(1);
    const [limit, setLimit] = useState(10);

    useEffect(() => {
        dispatch(getSecurityActivity({
            limit: limit,
            page: pageNo,
            data: "session"
        }))
    }, [limit, pageNo])

    const tableCol = [
        { dataIndex: "created_at", title: <p className="coins">{t("account_activity_page.Date")}</p> },
        { dataIndex: "source", title: <p className="coins">{t("account_activity_page.Source")}</p> },
        { dataIndex: "activity", title: <p className="coins">{t("account_activity_page.Activity")}</p> },
        { dataIndex: "status", title: <p className="coins">{t("account_activity_page.Status")}</p> },
        { dataIndex: "user_ip", title: <p className="coins">{t("account_activity_page.IP_address")}</p> },
    ];

    const datax = () => {
        let newData = [];
        secureActivityList?.length > 0 &&
            secureActivityList?.map((items, idx) => {
                let temp = {
                    keyId: idx,
                    "created_at": moment(items?.created_at).format("DD-MM-YYYY  HH:mm") || "-",
                    "source": items?.user_agent || "-",
                    "activity": items?.activity || "Delete trusted device",
                    "status": items.action === "delete" ? "Completed" : "" || "-",
                    "user_ip": items?.user_ip || "-",
                };

                newData.push(temp);
            });
        return newData;
    };

    return (
        <div className="securityInnerDiv">
            <div className={`assetTable`}>
                <Table
                    rowKey={(obj) => obj.keyId}
                    columns={tableCol}
                    dataSource={datax()}
                    pagination={false}
                    className="portfolioTable cursorInitial"
                    locale={customLocale}
                />
                <CustomPagination
                    pageNo={pageNo}
                    limit={limit}
                    total={totalSecureActivity}
                    onChange={(pageNo, pageSize) => {
                        setPageNo(pageNo);
                        setLimit(pageSize);
                    }}
                />
            </div>
        </div>
    )
}

export default SecurityActivity