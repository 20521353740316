import React, { useState } from "react";
import { RightOutlined } from "@ant-design/icons";
import { convertType } from "../../../staticObjects";
import ConvertListLikeDropdown from "../../Common/ConvertListLikeDropdown";
import style from "./style.module.scss";
import { isObjHasValue, uppercase } from "../../../helpers";

const initDivs = {
  fromDiv: false,
  toDiv: false,
};
const CommonCurrFromTo = (props) => {
  const [divStatus, setDivStatus] = useState(initDivs);
  const { from, to } = convertType;
  const { convertIcon, cbFun, firstCurr, secondCurr, fromAmt, toAmt } = props;
  const { fromDiv, toDiv } = divStatus;

  const openCloseDivsFun = (status, type = "") => {
    let tempDiv = { ...divStatus };
    if (!status) {
      setDivStatus(initDivs);
    } else {
      if (type === from) {
        tempDiv["fromDiv"] = true;
      } else if (type === to) {
        tempDiv["toDiv"] = true;
      }
      setDivStatus({ ...tempDiv });
    }
  };

  const isFrom = divStatus.fromDiv;

  return (
    <>
      <div className={`${style.Buy} ${style.pdlr}`}>
        <div className={` ${style.Buy_btn} card`}>
          <ul className={`${convertIcon}`}>
            <li onClick={() => openCloseDivsFun(true, from)}>
              <span className="wd20">From</span>
              <span className="wd60">
                {isObjHasValue(firstCurr) && (
                  <>
                    <img
                      className={style.Buy_btn_icon}
                      src={firstCurr?.currency_icon}
                      alt="icon"
                    />
                    {Number(fromAmt) > 0
                      ? `${fromAmt} ${uppercase(firstCurr?.currency)}`
                      : firstCurr?.currency_name}
                  </>
                )}
              </span>
              <RightOutlined className="wd20" />
            </li>

            <li onClick={() => openCloseDivsFun(true, to)}>
              <span
                className="convertIconSpan"
                onClick={(e) => {
                  e.stopPropagation();
                  cbFun({}, "swapCurr");
                }}
              />
              <span className="wd20 labelto">To</span>
              <span className="wd60">
                {isObjHasValue(secondCurr) && (
                  <>
                    <img
                      className={style.Buy_btn_icon}
                      src={secondCurr?.currency_icon}
                      alt="icon"
                    />
                    {Number(toAmt) > 0
                      ? `${toAmt} ${uppercase(secondCurr?.currency)}`
                      : secondCurr?.currency_name}
                  </>
                )}
              </span>
              <RightOutlined className="wd20" />
            </li>
          </ul>
        </div>
      </div>

      {(fromDiv || toDiv) && (
        <div className={style.positionedContent}>
          <ConvertListLikeDropdown
            currObj={isFrom ? firstCurr : secondCurr}
            type={isFrom ? from : to}
            cbFun={cbFun}
            onClick={() => openCloseDivsFun(false)}
          />
        </div>
      )}
    </>
  );
};

export default CommonCurrFromTo;
