import React, { useEffect, useState } from "react";
import { Tabs } from "antd";
import { StoreImages } from "../../Storeimgaes/StoreImages";
import BuyList from "./BuyList";
import BuyContent from "./BuyContent";
import PayWith from "./PayWith";
import Asset from "./Asset";
import { buySellConvertTabsKey, openCloseDivs } from "../../../staticObjects";
import styles from "./style.module.scss";
import Convert from "./Convert";
import { useDispatch, useSelector } from "react-redux";
import { getBalance, getCurrencies } from "../../../redux/services";
import Identityverify from "../../Common/Identityverify";
import ComingSoon from "../../Comingsoon";

const BuySellConvertTab = (props) => {
  const { TabPane } = Tabs;
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.user);

  const { bitCoin, masterCard, history } = StoreImages;
  const { coin, pay, convert } = openCloseDivs;
  const { buy, sell, convert: convertKey } = buySellConvertTabsKey;
  const initDivs = {
    coinDiv: false,
    payDiv: false,
    convertDiv: false,
  };
  const [divStatus, setDivStatus] = useState(initDivs);
  const [currentTab, setCurrentTab] = useState(
    props.fromWhere === "swap" ? convertKey : buy
  );
  const isOpenFromSwap = props.fromWhere === "swap";

  useEffect(() => {
    dispatch(getCurrencies());
    dispatch(getBalance());
  }, []);

  const openCloseDivsFun = (status, type = "") => {
    let tempDiv = { ...divStatus };
    if (!status) {
      setDivStatus(initDivs);
    } else {
      if (type === coin) {
        tempDiv["coinDiv"] = true;
      } else if (type === pay) {
        tempDiv["payDiv"] = true;
      } else if (type === convert) {
        tempDiv["convertDiv"] = true;
      }
      setDivStatus({ ...tempDiv });
    }
  };

  const onChangeTab = (tabName) => {
    setCurrentTab(tabName);
  };

  const Data = [
    { name: "Buy", title: "Bitcoin", img: bitCoin, type: coin },
    {
      name: "Pay with",
      title: "Axis Bank",
      img: masterCard,
      type: pay,
    },
  ];
  const sellData = [
    { name: "Buy", title: "Bitcoin", img: bitCoin, type: coin },
    {
      name: "Receive to",
      title: "Axis Bank",
      img: masterCard,
      type: pay,
    },
  ];

  const isLevel3Return = (compo) => {
    let isLevel3 = data?.level === 3;
    return isLevel3 ? compo : <Identityverify cbOnClick={() => {props.onCb()}} />;
  };

  const { coinDiv, payDiv, convertDiv } = divStatus;

  return (
    <div style={{ position: "relative" }}>
      <Tabs
        className="blueTabs buySellTab"
        defaultActiveKey={currentTab}
        onChange={onChangeTab}
      >
        {isOpenFromSwap && (
          <TabPane tab="Convert" key={convertKey}>
            {isLevel3Return(currentTab === convertKey ? <Convert /> : "")}
          </TabPane>
        )}
        <TabPane tab="Buy" key={buy}>
          {isLevel3Return(
            currentTab === buy ? (
              <ComingSoon style={{ height: "515px" }} />
            ) : (
              // <BuyContent
              //   exchangeSec
              //   onClickCoin={() => openCloseDivsFun(true, coin)}
              //   onClickPay={() => openCloseDivsFun(true, pay)}
              //   Btn="One time purchase"
              //   icon={history}
              //   listData={Data}
              //   title="Buy Bitcoin"
              //   currentTab={currentTab}
              //   amount
              // />
              ""
            )
          )}
        </TabPane>
        <TabPane tab="Sell" key={sell}>
          {isLevel3Return(
            currentTab === sell ? (
              <ComingSoon style={{ height: "515px" }} />
            ) : (
              // <BuyContent
              //   exchange
              //   onClickCoin={() => openCloseDivsFun(true, coin)}
              //   onClickPay={() => openCloseDivsFun(true, pay)}
              //   desc="You don't have any Bitcoin to sell. Try buying some to get started."
              //   listData={sellData}
              //   exchangeSec
              //   title="Sell Bitcoin"
              //   currentTab={currentTab}
              //   amount
              // />
              ""
            )
          )}
        </TabPane>
        {/* {!isOpenFromSwap && (
          <TabPane tab="Convert" key={convertKey}>
            {isLevel3Return(currentTab === convertKey ? <Convert /> : "")}
          </TabPane>
        )} */}
      </Tabs>

      {coinDiv && (
        <div className={styles.positionedContent}>
          <BuyList onClick={() => openCloseDivsFun(false)} />
        </div>
      )}
      {payDiv && (
        <div className={styles.positionedContent}>
          <PayWith onClick={() => openCloseDivsFun(false)} />
        </div>
      )}
      {convertDiv && (
        <div className={styles.positionedContent}>
          <Asset onClick={() => openCloseDivsFun(false)} />
        </div>
      )}
    </div>
  );
};

export default BuySellConvertTab;
