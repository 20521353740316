import React, { useEffect } from "react";
import style from "./style.module.scss";
import { Row, Col, Button, Space, Breadcrumb } from "antd";
import NewCard from "./NewCard";
import { useDispatch, useSelector } from "react-redux";
import { getSinglePost } from "../../../redux/services/blogPostService.js";
import { useNavigate, useParams } from "react-router-dom";
import { rootName } from "../../../utils/constant.js";
import { capitalize } from "../../../helpers/capitalize.js";
import dummy from "../../Assets/Images/dummy.svg";

const NewsDetail = () => {
  const dispatch = useDispatch();
  const param = useParams();
  const navigate = useNavigate();
  const { data } = useSelector((state) => state.blogs?.singlePost);
  const { list } = useSelector((state) => state.blogs);

  useEffect(() => {
    if (param?.id) {
      dispatch(
        getSinglePost({
          id: param?.id,
        })
      );
    }
  }, [param?.id]);

  return (
    <div>
      <section className={`${style.cardSecblock} cardSecblockInner`}>
        <div className="container">
          <Row justify="center" gutter={{ xs: 10, lg: 20, xl: 40 }}>
            <Col lg={15}>
              <div className={style.DetailInfo}>
                <div className={`projectdetail `}>
                  <Breadcrumb style={{ paddingBottom: "unset" }} separator=">">
                    <Breadcrumb.Item href={rootName}>Home </Breadcrumb.Item>
                    <Breadcrumb.Item href={`${rootName}ourSubscription`}>
                      {" "}
                      News
                    </Breadcrumb.Item>
                  </Breadcrumb>
                </div>
                <h2>{capitalize(data?.title)}</h2>
                <img src={data?.image} alt="img" />
                <p style={{ paddingBottom: "20px" }}>{data?.description}</p>
              </div>
            </Col>
            <Col lg={7}>
              <div className={`${style.cardSecblock_details} detail`}>
                {list?.length > 0 &&
                  list.slice(0, 4).map((text) => (
                    <div key={text.id} style={{ maxWidth: "326px" }}>
                      <NewCard
                        img={text?.image}
                        title={capitalize(text?.title)}
                        Detail={text?.description.slice(0, 100) + "..."}
                        onClick={() =>
                          navigate(`${rootName}newsDetail/${text?.id}`)
                        }
                      />
                    </div>
                  ))}
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </div>
  );
};

export default NewsDetail;
