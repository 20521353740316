import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../api";
import * as config from "../../utils/apiVersion";
import { startLoading, stopLoading } from "../feature";
import { errorToastSelector, successToastSelector } from "../selector";
import { buildQueryString } from "../../helpers";
import { getBalance } from "./commonServices";

export const getWithdrawHistory = createAsyncThunk(
  "transHistories/getWithdrawHistory",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `account/withdraws?${buildQueryString(payload)}`;
      let res = await API.get(config.peatioWithHeader)(url);
      dispatch(stopLoading());
      return { list: res.data, total: res.headers.total, status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const getDepositHistory = createAsyncThunk(
  "transHistories/getDepositHistory",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `account/deposits?${buildQueryString(payload)}`;
      let res = await API.get(config.peatioWithHeader)(url);
      dispatch(stopLoading());
      return { list: res.data, total: res.headers.total, status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const getConvertHistory = createAsyncThunk(
  "transHistories/getConvertHistory",
  async (payload, { rejectWithValue, dispatch }) => { 
    try {
      dispatch(startLoading());
      // let url = `account/adjustments?${buildQueryString(payload)}`;
      let url = `account/adjustments`;
      let res = await API.get(config.peatioWithHeader)(url);
      dispatch(stopLoading());
      return { list: res.data, total: res.headers.total, status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const createConvert = createAsyncThunk(
  "transHistories/createConvert",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `account/adjustments`;
      await API.post(config.peatio)(url, payload);
      dispatch(successToastSelector({ message: "Convert successful" }));
      await dispatch(getBalance());
      await dispatch(getConvertHistory({ limit: 10 }));
      dispatch(stopLoading());
      return { status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const getMarketList = createAsyncThunk(
  "transHistories/getMarketList",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `market/list`;
      let res = await API.get(config.peatio)(url, payload);
      dispatch(stopLoading());
      return { list: res, status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const downloadData = createAsyncThunk(
  "transHistories/downloadData",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `/account/csv`;
      let res = await API.post(config.peatio)(url, payload);
      dispatch(stopLoading());
      return { status: true, link:res };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);


export const getAdjustment = createAsyncThunk(
  "commonServices/getAdjustment",
  async (payload, { dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `account/balance_adjustments?${buildQueryString(payload)}`; 

      let res = await API.get(config.peatioWithHeader)(url);
      dispatch(stopLoading());
      return { list: res.data, total: res.headers.total, status: true };
    } catch (e) {
      dispatch(errorToastSelector(e));
      return false;
    }
  }
);


export const getAirdrop = createAsyncThunk(
  "commonServices/getAirdrop",
  async (payload, { dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `account/airdrop/list?${buildQueryString(payload)}`; 
      let res = await API.get(config.peatioWithHeader)(url);
      dispatch(stopLoading());
      return { list: res.data, total: res.headers.total, status: true };
    } catch (e) {
      dispatch(errorToastSelector(e));
      return false;
    }
  }
);