export const thousandDigit = (nums, precision) => {
  const options = {
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
  };
  const formatted = Number(nums).toLocaleString("en", options);
  const trimmed = formatted;
  return trimmed;
};

export const convertToThousandDigit = (num) => {
  const formatted = new Intl.NumberFormat("en", {
    maximumFractionDigits: 20,
  }).format(num);
  return formatted;
};
export const convertToThousandDigit2 = (num, precision) => {
  const formatted = new Intl.NumberFormat("en", {
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
  }).format(num);
  return formatted;
};

