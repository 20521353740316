import React from "react";
import { Link } from "react-router-dom";
import { Table, Button } from "antd";
import { useNavigate } from "react-router-dom";
import { BackIcon, TokenIcon } from "../../Assets/Svg/Svg.jsx";
import SignInHeader from "../../Layout/Header/SignInHeader.jsx";
import Footer from "../../Layout/Footer/Footer.jsx";
function LaunchpadTable() {
  const navigate = useNavigate();
  const previous = () => {
    navigate(-1);
  };
  const dataSource = [
    {
      key: "1",
      projectName: "Monero",
      description: (
        <span>
          There are many variations of <br /> passages of Lorem Ipsum <br />
          available, but the majority.
        </span>
      ),
      tokenName: (
        <span
          style={{ display: "inline-flex", gap: "5px", alignItems: "center" }}
        >
          <TokenIcon />
          Monero
        </span>
      ),
      tokenpurchased: "200",
      myholdings: "400",
      buyprice: "$140",
      endDate: "2023-12-15 14:00",
      otherDetails: "Monero1192",
      subscriber: "400",
    },

    {
      key: "2",
      projectName: "Monero",
      description: (
        <span>
          There are many variations of <br /> passages of Lorem Ipsum <br />
          available, but the majority.
        </span>
      ),
      tokenName: (
        <span
          style={{ display: "inline-flex", gap: "5px", alignItems: "center" }}
        >
          <TokenIcon />
          Monero
        </span>
      ),
      tokenpurchased: "200",
      myholdings: "400",
      buyprice: "$140",
      endDate: "2023-12-15 14:00",
      otherDetails: "Monero1192",
      subscriber: "400",
    },
    {
      key: "3",
      projectName: "Monero",
      description: (
        <span>
          There are many variations of <br /> passages of Lorem Ipsum <br />
          available, but the majority.
        </span>
      ),
      tokenName: (
        <span
          style={{ display: "inline-flex", gap: "5px", alignItems: "center" }}
        >
          <TokenIcon />
          Monero
        </span>
      ),
      tokenpurchased: "200",
      myholdings: "400",
      buyprice: "$140",
      endDate: "2023-12-15 14:00",
      otherDetails: "Monero1192",
      subscriber: "400",
    },
    {
      key: "4",
      projectName: "Monero",
      description: (
        <span>
          There are many variations of <br /> passages of Lorem Ipsum <br />
          available, but the majority.
        </span>
      ),
      tokenName: (
        <span
          style={{ display: "inline-flex", gap: "5px", alignItems: "center" }}
        >
          <TokenIcon />
          Monero
        </span>
      ),
      tokenpurchased: "200",
      myholdings: "400",
      buyprice: "$140",
      endDate: "2023-12-15 14:00",
      otherDetails: "Monero1192",
      subscriber: "400",
    },
  ];

  const columns = [
    {
      title: "Project Name",
      dataIndex: "projectName",
      key: "projectName",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Token Name",
      dataIndex: "tokenName",
      key: "tokenName",
    },
    {
      title: "Tokens Purchased",
      dataIndex: "tokenpurchased",
      key: "tokenpurchased",
    },
    {
      title: "My Holdings",
      dataIndex: "myholdings",
      key: "myholdings",
    },
    {
      title: "Buy Price",
      dataIndex: "buyprice",
      key: "buyprice",
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
    },
    {
      title: "Other details",
      dataIndex: "otherDetails",
      key: "otherDetails",
    },
    {
      title: "# Successful Subscriber",
      dataIndex: "subscriber",
      key: "subscriber",
    },
  ];

  return (
    <>
      <SignInHeader />
      <div className="launchpadHis">
        <div className="container ">
          <Button
            className={`backbtn `}
            style={{ marginBottom: "28px" }}
            type="text"
            onClick={() => {
              navigate(-1);
            }}
          >
            <BackIcon style={{ marginRight: "5px" }} /> Back to previous
          </Button>
          {/* <div className="backbtn">
            <Link to="#" onClick={previous}>
              <BackIcon /> Back to previous
            </Link>
          </div> */}
          <div className="launchpadTable">
            <Table
              dataSource={dataSource}
              columns={columns}
              pagination={false}
            />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default LaunchpadTable;
