import CoinDetail from "../Components/Pages/CoinDetail/CoinDetail.jsx";
import {
  Dashboard,
  Login,
  ChangePassword,
  ResetPassword,
  Earn,
  Swap,
  CreateAcount,
  Listing,
  MarketUpdates,
  OurToken,
  ListingApplication,
  VerifyEmail,
  Identity,
  Portfolio,
  bank,
  VerifyIdentity,
  Setting,
  Order,
  Exchange,
  // Referral,
  ListedCoins,
  ReferralViewAll,
  RewardViewAll,
  EmailConfirmation,
  ViewAll,
  Landing,
  AdressManagement,
  ViewAllHistory,
  Applications,
  CoinListingIndex,
  Transactions,
  RetailKyc,
  CorporateKyc,
  ConvertMNG,
  ConvertHistory,
  LogoutCompo,
  LaunchpadViewAll,
} from "./lazyRoute";
import DeviceManagement from "../Components/Pages/Setting/DeviceManagement.jsx";
import AccountActivity from "../Components/Pages/Setting/AccountActivity.jsx";
import {
  sidebarRouteObj,
  publicRouteObj,
  primaryRoutes,
} from "../staticObjects/routing";
import BanksDeposite from "../Components/Pages/Funds/Banks/BanksDeposite.jsx";
import CryptoDesposite from "../Components/Pages/Funds/Crypto/CryptoDesposite.jsx";
import FutureTransferHistory from "../Components/Pages/Portfolio/FutureTransferHistory.jsx";
import LaunchpadDetail from "../Components/Pages/LaunchpadViewAll/LaunchpadDetail.jsx";
import LaunchpadTable from "../Components/Pages/LaunchpadViewAll/LaunchpadTable.jsx";
import CHBToken from "../Components/Pages/CHBToken/CHBToken.jsx";
import OurSubscription from "../Components/Pages/OurSubscription/OurSubscription.jsx";
import NewsDetail from "../Components/Pages/OurSubscription/NewsDetail.jsx";
import Privacy from "../Components/Pages/Privacy/Privacy.jsx";
import TermsConditions from '../Components/Pages/Privacy/TermsConditions/TermsConditions.jsx'
import Policies from "../Components/Pages/Privacy/Policies/Policies.jsx";
import Agreement from "../Components/Pages/Privacy/ Agreement /Agreement.jsx";

// Public routing array

const {
  login,
  changePassword,
  resetPassword,
  createAccount,
  emailVerify,
  emailConfirmation,
  identity,
  verifyIdentity,
  launchpadViewAll,
  launchpadDetail,
  launchpadHistory,
  whyCoinhub,
  ourSubscription,
  newsDetail,
  ourToken,
  privacy,
  terms,
  policies,
  agreement
} = publicRouteObj;

const {
  overview,
  setting,
  deviceManagement,
  accountActivity,
  kycRetail,
  kycCorporate,
  order,
  notfication,
  viewAll,
  viewHistory,
  addressManagement,
  intro,
  introWithoutId,
  referralViewAll,
  rewardViewAll,
  convertMNG,
  convertHistory,
  authLanding,
  logout,
  fTransaferHistory,
  advTradePublic
} = primaryRoutes;

export const commonRoutesData = [
  {
    path: "listing",
    component: <Listing />,
  },
  {
    path: "socialresponsibility",
    component: <MarketUpdates />,
  },
  {
    path: ourToken,
    component: <OurToken />,
  },
  {
    path: whyCoinhub,
    component: <CHBToken />,
  },
  {
    path: launchpadViewAll,
    component: <LaunchpadViewAll />,
  },
  {
    path: launchpadDetail,
    component: <LaunchpadDetail />,
  },
  {
    path: launchpadHistory,
    component: <LaunchpadTable />,
  },
  {
    path: ourSubscription,
    component: <OurSubscription />,
  },
  {
    path: `${newsDetail}/:id`,
    component: <NewsDetail />,
  },
  {
    path: "listingApplication",
    component: <ListingApplication />,
  },
  // Add other common routes here
];

export const publicRoutesData = [
  {
    path: primaryRoutes.landing,
    component: <Landing />,
  },
  {
    path: login,
    component: <Login />,
  },

  {
    path: `${changePassword}/:id`,
    component: <ChangePassword />,
  },
  {
    path: resetPassword,
    component: <ResetPassword />,
  },
  {
    path: createAccount,
    component: <CreateAcount />,
  },
  {
    path: privacy,
    component: <Privacy />,
  },
  {
    path: terms,
    component: <TermsConditions />,
  },
  {
    path: policies,
    component: <Policies />,
  },
  {
    path: `${createAccount}/agreement`,
    component: <Agreement />,
  },

  // {
  //   path: "listing",
  //   component: <Listing />,
  // },
  // {
  //   path: "marketupdates",
  //   component: <MarketUpdates />,
  // },
  // {
  //   path: ourToken,
  //   component: <OurToken />,
  // },
  // {
  //   path: "listingApplication",
  //   component: <ListingApplication />,
  // },
  {
    path: emailVerify,
    component: <VerifyEmail />,
  },
  {
    path: `${emailConfirmation}/:id`,
    component: <EmailConfirmation />,
  },

  {
    path: identity,
    component: <Identity />,
  },
  {
    path: verifyIdentity,
    component: <VerifyIdentity />,
  },
  // {
  //   path: launchpadViewAll,
  //   component: <LaunchpadViewAll />,
  // },
  // {
  //   path: launchpadDetail,
  //   component: <LaunchpadDetail />,
  // },
  // {
  //   path: launchpadHistory,
  //   component: <LaunchpadTable />,
  // },
  // {
  //   path: whyCoinhub,
  //   component: <CHBToken />,
  // },
  // {
  //   path: ourSubscription,
  //   component: <OurSubscription />,
  // },
  {
    path: 'trading',
    component: <Exchange />,
  },
  {
    path: `trading/:marketId`,
    component: <Exchange />,
  },
];

// Private routing array

const {
  home,
  port,
  swap,
  advTrade,
  earn,
  p2p,
  coinListing,
  referral,
  listedCoins,
  transactions,
  cryoto_deposit,
  bank_deposit,
} = sidebarRouteObj;


export const privateRoutesData = [
  {
    path: ``,
    component: <Dashboard />,
  },
  {
    path: logout,
    component: <LogoutCompo />,
  },
  {
    path: authLanding,
    component: <Landing />,
  },
  {
    path: `${home}`,
    component: <Dashboard />,
  },
  {
    path: `${port}`,
    component: <Portfolio />,
  },
  // =======================deposite====================
  {
    path: `${cryoto_deposit}`,
    component: <CryptoDesposite />,
  },
  {
    path: `${cryoto_deposit}/:id`,
    component: <CryptoDesposite />,
  },
  {
    path: `${bank_deposit}`,
    component: <BanksDeposite />,
  },
  // ==============================================
  // {
  //   path: `${swap}`,
  //   component: <Swap />,
  // },
  {
    path: `${advTrade}`,
    component: <Exchange />,
  },
  {
    path: `${advTrade}/:marketId`,
    component: <Exchange />,
  },
  {
    path: `${earn}`,
    component: <Earn />,
  },
  {
    path: `${transactions}`,
    component: <Transactions />,
  },
  {
    path: `${fTransaferHistory}`,
    component: <FutureTransferHistory />,
  },
  // {
  //   path: `${coinListing}`,
  //   component: <Applications />,
  // },
  // {
  //   path: `${listedCoins}`,
  //   component: <ListedCoins />,
  // },
  // {
  //   path: `${intro}`,
  //   component: <CoinListingIndex />,
  // },
  // {
  //   path: `${introWithoutId}`,
  //   component: <CoinListingIndex />,
  // },
  // {
  //   path: `${referral}`,
  //   component: <Referral />,
  // },
  {
    path: `${referralViewAll}`,
    component: <ReferralViewAll />,
  },
  {
    path: `${rewardViewAll}`,
    component: <RewardViewAll />,
  },

  {
    path: `${overview}/:id`,
    component: <CoinDetail />,
  },
  {
    path: `${setting}`,
    component: <Setting />,
  },
  {
    path: `${deviceManagement}`,
    component: <DeviceManagement />,
  },
  {
    path: `${accountActivity}`,
    component: <AccountActivity />,
  },
  {
    path: `${kycRetail}`,
    component: <RetailKyc />,
  },
  {
    path: `${kycCorporate}`,
    component: <CorporateKyc />,
  },

  {
    path: `${order}`,
    component: <Order />,
  },
  {
    path: `${viewAll}`,
    component: <ViewAll />,
  },
  {
    path: `${viewHistory}`,
    component: <ViewAllHistory />,
  },
  {
    path: `${convertMNG}`,
    component: <ConvertMNG />,
  },
  {
    path: `${convertHistory}`,
    component: <ConvertHistory />,
  },
  { path: `${addressManagement}`, component: <AdressManagement /> },
];
