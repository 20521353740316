import { createSlice } from "@reduxjs/toolkit";
import { getBlogPosts, getSinglePost } from "../../services";

const blogPostslice = createSlice({
    name: "blogs",
    initialState: {
        error: false,
        loading: false,
        list: [],
        singlePost: {},
        total: 0,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getBlogPosts.pending, (state) => {
                state.error = false;
                state.list = [];
            })
            .addCase(getBlogPosts.fulfilled, (state, action) => {
                state.list = action?.payload?.list;
                state.total = action?.payload?.total ?? 0;
            })
            .addCase(getBlogPosts.rejected, (state) => {
                state.list = [];
                state.error = true;
            })
            .addCase(getSinglePost.pending, (state) => {
                state.error = false;
                state.singlePost = {};
            })
            .addCase(getSinglePost.fulfilled, (state, action) => {
                state.singlePost = action?.payload;
            })
            .addCase(getSinglePost.rejected, (state) => {
                state.singlePost = {};
                state.error = true;
            })

    },
});

export const { } = blogPostslice.actions;
export default blogPostslice.reducer;
