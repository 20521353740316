import React, { useState, useEffect } from "react";
import "../style.scss";
import CheckboxCustom from "../../../Ui/checkbox/CheckboxCustom";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";
import { publicRouteObj } from "../../../../staticObjects";
import { postAgreement } from "../../../../redux/services";
import { useDispatch } from "react-redux";

const Agreement = ({ setIsModalOpen }) => {
  const [showText, setShowText] = useState(false);
  const [concentVal, setConcentVal] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    const handleScroll = () => {
      // Check if the user has scrolled to the bottom of the page
      const isAtBottom =
        window.innerHeight + window.scrollY >= document.body.offsetHeight;

      // Update the state to show or hide text based on the scroll position
      setShowText(isAtBottom);
    };

    // Attach the scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleSubmit = async () => {
    const res = await dispatch(postAgreement(concentVal));
    if (res?.payload?.status) {
      setIsModalOpen(false);
      return;
    }
  };
  useEffect(() => {
    document.body.classList.add("policyPages");
    return () => {
      document.body.classList.add("policyPages");
    };
  }, []);
  return (
    <div class="page">
      <div class="header">
        <div class="container">
          <p class="title">ГЭРЭЭ БАЙГУУЛАХ УРЬДЧИЛСАН НӨХЦӨЛ</p>
        </div>
      </div>
      <div class="translations-content-container">
        <div class="container">
          <div class="tab-content translations-content-item en visible" id="en">
            <h2 className="mb-15">/ЭРСДЭЛИЙН МЭДЭЭЛЭЛ/</h2>
            <p className="mb-15">
              Та “COINHUB” Харилцагчийн гэрээг баталгаажуулахаас өмнө Монгол
              Улсын Виртуал хөрөнгийн үйлчилгээ үзүүлэгчийн тухай хуулийн 8
              дугаар зүйлийн 8.1.3-т заасны дагуу виртуал хөрөнгө, түүн хамаарах
              үйлчилгээнд холбогдох дараах эрсдэлүүдийг мэдсэн байх
              шаардлагатайг анхаарна уу.
            </p>
            <p className="mb-15">
              ✔ олон улсын хэм хэмжээ, дотоодын хууль тогтоомжоос хамаарч
              виртуал хөрөнгийн үйлчилгээ болон үнэ цэнэ огцом өөрчлөгдөх;
            </p>
            <p className="mb-15">
              ✔ виртуал хөрөнгийн гүйлгээ нь буцаах боломжгүй, залилан мэхлэх
              шинжтэй, эсхүл алдаатай гүйлгээнээс үүдэн гарах үр дагавар, нөхөн
              төлөгдөхгүй байх;
            </p>
            <p className="mb-15">
              ✔ виртуал хөрөнгийн үнэ цэн нь зах зээлд оролцогчдын эрэлт
              хэрэгцээгээр тодорхойлогдох тул үнэ цэний хэлбэлзлээс хамаарч
              алдагдал хүлээх;
            </p>
            <p className="mb-15">
              ✔ виртуал хөрөнгө, виртуал хөрөнгийн үйлчилгээ нь залилан мэхлэх
              болон цахим халдлагад өртөх;
            </p>
            <p className="mb-15">
              ✔ виртуал хөрөнгийн үйлчилгээ үзүүлэгч болох COINHUB-д технологийн
              хүндрэл гарсан тохиолдолд харилцагч өөрийн виртуал хөрөнгийг
              ашиглах, хэрэглэх боломжгүй болох;
            </p>
            <p className="mb-15">
              ✔ виртуал хөрөнгийн үйлчилгээ үзүүлэгчийн өмчилж, эсхүл эзэмшиж
              байгаа хөрөнгө нь харилцагчид учирсан хохирлыг бүрэн барагдуулж
              хүрэлцэхгүй;
            </p>
            <p className="mb-15">
              ✔ COINHUB-г виртуал хөрөнгийн үйлчилгээ үзүүлэгчээр бүртгэснийг
              тухайн виртуал хөрөнгөд өгч байгаа баталгаа гэж үзэхгүй бөгөөд
              виртуал хөрөнгийн үйлчилгээнд хамаарах үйл ажиллагааны үр дүнд
              үүссэн хохирлыг төр хариуцахгүй.
            </p>
            <p className="mb-15">
              ТА ДЭЭРХ ЭРСДЭЛИЙГ ОЙЛГОЖ, ХҮЛЭЭН ЗӨВШӨӨРСНӨӨР “COINHUB”
              ХАРИЛЦАГЧИЙН ГЭРЭЭ-Г БАТАЛГААЖУУЛАХЫГ АНХААРНА УУ.
            </p>
            <h2 className="mb-15 textCenter">“COINHUB” ХАРИЛЦАГЧИЙН ГЭРЭЭ</h2>
            <p className="textRight mb-15">
              <b>Сүүлд өөрчлөлт оруулсан огноо:</b> 2023 оны 8-р сарын 29-ний
              өдөр
            </p>
            <p className="mb-15">
              Энэхүү гэрээ (“Гэрээ”) нь Харилцагч Та (цаашид “Та” эсвэл
              “Харилцагч” гэх) болон Монгол Улсад үүсгэн байгуулагдсан, 6693326
              регистрийн дугаартай, Монгол Улс, Улаанбаатар хот, Сүхбаатар
              дүүрэг, 1-р хороо, Соёл амралтын хүрээлэн, Жамьян Гүний гудамж, Ай
              Си Си цамхаг /20-р байр/, 3 давхарт үйл ажиллагаа явуулдаг, “КОЙН
              ХАБ” ХХК (цаашид “COINHUB”, “Бид”, “Бирж” гэх) нарын хооронд
              цахимаар байгуулагдана.
            </p>
            <p className="mb-15">
              Таныг www.coinhub.mn вэбсайт эсвэл гар утасны аппликейшнаар
              дамжуулан харилцагчаар бүртгүүлж, бүртгэл баталгаажуулахын өмнө
              Эрсдэлийн мэдээлэл, Үйлчилгээний нөхцөл, Нууцлалын бодлого,
              Гэрээний нөхцөлийг харуулах тул анхааралтай уншиж, танилцсаны
              дараа баталгаажуулна уу.
            </p>
            <p className="mb-15">
              Та Гэрээний нөхцөлийг хүлээн зөвшөөрөхгүй, Гэрээнд заасан
              нөхцөлийг хангаагүй тохиолдолд баталгаажуулахгүй байхыг Танд
              зөвлөж байна.
            </p>
            <h2 className="mb-15">НЭР ТОМЬЁОНЫ ТОДОРХОЙЛОЛТ</h2>
            <ol>
              <li>
                <p>
                  Гэрээнд хэрэглэсэн дараах нэр томьёог дор дурдсан агуулгаар
                  ойлгоно. Үүнд:
                </p>
              </li>
              <ol>
                <li>
                  <p>
                    <b>“COINHUB” гэж </b> “COINHUB”-ын вэбсайт (домайн нэр
                    буюу coinhub.mn), “COINHUB”-аас хөгжүүлж, харилцагчид
                    виртуал хөрөнгийн үйлчилгээг санал болгож буй гар утасны
                    аппликейшн, платформуудыг;
                  </p>
                </li>
                <li>
                  <p>
                    <b>“COINHUB” Үйлчилгээ</b> гэж www.coinhub.mn вэбсайт, гар
                    утасны аппликейшн болон бусад хэлбэрээр виртуал хөрөнгийн
                    Арилжаа, шилжүүлэг, хадгалалт, удирдлага, олон нийтэд санал
                    болгох, худалдах хүрээнд “COINHUB”-аас санал болгож буй
                    виртуал хөрөнгийн үйлчилгээг;
                  </p>
                </li>
                <li>
                  <p>
                    <b>“Арилжаа” </b>гэж “COINHUB” дээр виртуал хөрөнгө, фиат
                    хооронд, нэг виртуал хөрөнгийг нөгөө виртуал хөрөнгөөр
                    арилжихыг;
                  </p>
                </li>
                <li>
                  <p>
                    <b>“Виртуал Хөрөнгө”</b> гэж аливаа улсын албан ёсны мөнгөн
                    тэмдэгт, үнэт цаасны дижитал хэлбэр, эсхүл Монголбанкны
                    зөвшөөрөлтэй цахим мөнгөнөөс бусад дижиталаар шилжүүлэх,
                    арилжаалах боломжтой төлбөрийн, эсхүл хөрөнгө оруулалтын
                    зорилгоор ашиглагдах үнэ цэний дижитал илэрхийлэл бүхий
                    эдийн бус хөрөнгийг;
                  </p>
                </li>
                <li>
                  <p>
                    <b>“Фиат” </b>гэж аливаа улсын албан ёсны мөнгөн тэмдэгтийг;
                  </p>
                </li>
                <li>
                  <p>
                    <b>“МУТС”</b> гэж мөнгө угаах, терроризмыг санхүүжүүлэх;
                  </p>
                </li>
                <li>
                  <p>
                    <b>“Өндөр эрсдэлтэй харилцагч”</b> гэж улс төрд нөлөө бүхий
                    этгээд, МУТС-тэй тэмцэх чиг үүрэг бүхий олон улсын
                    байгууллагаас МУТС-тэй тэмцэх хяналтын хангалтгүй
                    тогтолцоотой гэж зарлагдсан улсын хүн, хуулийн этгээд,
                    МУТС-тай тэмцэх үндэсний эрсдэлийн үнэлгээгээр өндөр
                    эрсдэлтэй гэж үнэлэгдсэн салбарт үйл ажиллагаа явуулж байгаа
                    хүн, хуулийн этгээдийг;
                  </p>
                </li>
                <li>
                  <p>
                    <b>“Улс төрд нөлөө бүхий этгээд”</b> гэж Нийтийн албанд
                    нийтийн болон хувийн ашиг сонирхлыг зохицуулах, ашиг
                    сонирхлын зөрчлөөс урьдчилан сэргийлэх тухай хуулийн 20.2-т
                    заасан этгээд, эсхүл гадаад улсын түүнтэй адилтгах албан
                    тушаалтан болон холбогдох эрх бүхий байгууллагаас баталсан
                    бусад журам, заавар заасан албан тушаалтныг ойлгоно;
                  </p>
                </li>
                <li>
                  <p>
                    <b>“Үйлчилгээний нөхцөл”</b> гэж “COINHUB”-аас харилцагч
                    нарт одоо болон ирээдүйд санал болгож буй тухайлсан виртуал
                    хөрөнгийн үйлчилгээний мэдээлэл, шалгуур, харилцагч
                    үйлчилгээг ашиглахад мөрдөх журам зэрэг үндсэн болон бусад
                    нөхцөлийг тусгасан нийтэд нээлттэй байршуулсан цахим баримт
                    бичгийг;
                  </p>
                </li>
                <li>
                  <p>
                    <b>“Харилцагч”</b> гэж Гэрээ болон Бүртгэлийн шалгуур,
                    Харилцагчид тавигдах шаардлагыг хангаж, танин
                    баталгаажуулалт хийсэн, тухайлсан виртуал хөрөнгийн
                    Үйлчилгээний нөхцөлийг хангаж буй хувь хүн, ашгийн төлөө
                    хуулийн этгээдийг;
                  </p>
                </li>
                <li>
                  <p>
                    “Хэтэвч” гэж COINHUB Үйлчилгээг авах үед фиат, виртуал
                    хөрөнгийг удирдахад Харилцагчид зориулан “COINHUB” дээр
                    виртуал байдлаар үүсгэж, хадгалж буй дансыг;
                  </p>
                </li>
              </ol>
            </ol>
            <h2 className="mb-15">ХАРИЛЦАГЧИД ТАВИГДАХ ШААРДЛАГА</h2>
            <ol>
              <li>
                <p>
                  Та “COINHUB” Үйлчилгээ авах, Гэрээг баталгаажуулах үед
                  Харилцагчийн хувьд дараах шаардлагыг бүрэн дүүрэн хангасан
                  байна. Үүнд:
                </p>
              </li>
              <ol>
                <li>
                  <p>
                    18 насанд хүрсэн буюу холбогдох хууль тогтоомжийн дагуу
                    болон Гэрээ, Үйлчилгээний нөхцөл, Нууцлалын бодлого, Cookies
                    бодлого болон заасан нөхцөл, шаардлага, үүргийг биелүүлэх
                    эрх зүйн бүрэн чадамжтай байх;
                  </p>
                </li>
                <li>
                  <p>
                    Гэрээг баталгаажуулах эрх зүйн бүрэн чадамжтай, бүрэн эрх
                    олгогдсон хувь хүн, хуулийн этгээд байх;
                  </p>
                </li>
                <li>
                  <p>
                    Өмнө нь  өөрийн буруугаас Хэтэвчийг Биржээс хааж байсан;
                  </p>
                </li>
                <li>
                  <p>Одоо ашиглаж буй “COINHUB”-д бүртгэл байхгүй байх;</p>
                </li>
                <li>
                  <p>
                    Хуулийн этгээдийг төлөөлж байгаа тохиолдолд тухайн хуулийн
                    этгээд нь Гэрээг бүхэлд нь хэрэгжүүлэх бүрэн чадамжтай,
                    хуулийн этгээдийг төлөөлөх бүрэн эрхтэй этгээд байх;
                  </p>
                </li>
                <li>
                  <p>
                    Зөвхөн өөрийн нэрийн өмнөөс “COINHUB” үйлчилгээг авч байгаа
                    бөгөөд өөр бусад аливаа этгээдийн нэрийн өмнөөс, ашиг
                    сонирхлыг нь төлөөлөөгүй байх;
                  </p>
                </li>
                <li>
                  <p>
                    “COINHUB” Үйлчилгээг ашиглаж буй Таны үйлдэл нь аливаа хууль
                    тогтоомжийг зөрчихгүй байх. Тухайлбал мөнгө угаах,
                    терроризмыг санхүүжүүлэхтэй тэмцэх, авлигын эсрэг болон
                    бусад холбогдох хууль тогтоомжууд;
                  </p>
                </li>
                <li>
                  <p>
                    Хувь хүн бол оршин суугаа, хуулийн этгээд бол үүсгэн
                    байгуулагдаж, үйл ажиллагаа явуулж буй улс нь виртуал
                    хөрөнгийн арилжаа болон биржийн үйл ажиллагааг хориглоогүй
                    байх;
                  </p>
                </li>
              </ol>
              <li>
                <p>
                  Харилцагч дээрх шаардлагуудыг хангасан гэж худал мэдээлсэн,
                  хуурамч баримт бичиг бүрдүүлснийг Бирж тогтоосон тохиолдолд
                  “COINHUB” үйлчилгээг аливаа сануулгагүйгээр шууд зогсоож,
                  Харилцагчийн хэтэвчийг хаана.
                </p>
              </li>
              <li>
                <p>
                  Харилцагчийн буруутай үйлдлээс шалтгаалж “COINHUB”-д хохирол
                  учрах, ажил хэргийн нэр хүндэд сөрөг үр дагавар үүссэн
                  тохиолдолд хууль тогтоомжийн дагуу хариуцлага тооцуулах,
                  хохирлыг барагдуулах, эрх сэргээлгэх арга хэмжээг авна.
                </p>
              </li>
            </ol>
            <h2 className="mb-15">
              ХАРИЛЦАГЧИЙН БҮРТГЭЛ БА ХАРИЛЦАГЧИЙГ ТАНЬЖ МЭДЭХ
            </h2>
            <ol>
              <li>
                <p>
                  “COINHUB”-д харилцагчийн бүртгэлд шаардлагатай мэдээллийг
                  гаргаж өгөхөөс өмнө Та Эрсдэлийн мэдээлэл, Үйлчилгээний
                  нөхцөл, Нууцлалын бодлого, энэ Гэрээний нөхцөлтэй бүрэн
                  танилцаж баталгаажуулна. Үүний дараа Та дараах мэдээлэл
                  (“Хувийн мэдээлэл”)-ийг Биржийн шаардсан хэлбэрийн дагуу өгч,
                  бүртгэл баталгаажихыг хүлээнэ. Үүнд:
                </p>
              </li>
              <ol>
                <li>
                  <p>
                    Харилцагч хувь хүн бол иргэний үнэмлэх, гадаад паспортын аль
                    нэгийг шаардах ба нэмэлтээр дараах мэдээллийг гаргуулж
                    болно. Үүнд:
                  </p>
                </li>
                <ol id="lower-alpha">
                  <li>
                    <p>Одоо оршин суугаа газрын хаяг;</p>
                  </li>
                  <li>
                    <p>
                      Өөрийн нэр дээрх, тогтмол ашигладаг холбоо барих утасны
                      дугаар;
                    </p>
                  </li>
                  <li>
                    <p>Ажлын газрын нэр, хаяг, албан тушаал;</p>
                  </li>
                  <li>
                    <p>
                      Нэмэлтээр и-мэйл хаяг, орлогын эх үүсвэр зэрэг зэрэг
                      Биржээс хууль тогтоомжийн нийцлийг хангах, эрсдэлээс
                      сэргийлэх хүрээнд шаардлагатай гэж үзсэн бусад мэдээллийг
                      шаардаж болно.
                    </p>
                  </li>
                  <li>
                    <p>
                      Гадаад улсын иргэний хувьд хэрвээ Монгол улсад байнгын
                      оршин суугч бол бол Монгол Улсад оршин суух үнэмлэхний
                      хуулбар
                    </p>
                  </li>
                </ol>
                <li>
                  <p>
                    Харилцагч хуулийн этгээд бол дараах бичиг баримтыг шаардана.
                    Үүнд:
                  </p>
                </li>
                <ol id="lower-alpha">
                  <li>
                    <p>Улсын бүртгэлийн гэрчилгээний хуулбар;</p>
                  </li>
                  <li>
                    <p>Хүчин төгөлдөр Дүрмийн хуулбар;</p>
                  </li>
                  <li>
                    <p>
                      Эцсийн өмчлөгчийн иргэний үнэмлэх эсхүл гадаад паспорт;
                    </p>
                  </li>
                  <li>
                    <p>
                      Нэмэлтээр Гүйцэтгэх захирлын овог, нэр /бүтнээр/,
                      регистрийн дугаар, иргэншил, утасны дугаар, байгууллагын
                      үйл ажиллагаа явуулдаг хаяг, утасны дугаар, байгууллагын
                      эцсийн өмчлөгчийн овог, нэр, регистрийн дугаар болон
                      утасны дугаар зэрэг Биржээс хууль тогтоомжийн нийцлийг
                      хангах, эрсдэлээс сэргийлэх хүрээнд шаардлагатай гэж үзсэн
                      бусад мэдээллийг шаардаж болно.
                    </p>
                  </li>
                </ol>
              </ol>
              <li>
                <p>
                  Биржийн харилцагчаар бүртгүүлэхэд гаргаж өгсөн таны Хувийн
                  мэдээллийг та өөрөө, бүрэн, үнэн зөв гаргаж, баталгаажуулсан
                  гэж үзнэ.
                </p>
              </li>
              <li>
                <p>
                  Өөр этгээд таны Хувийн мэдээллийг ашиглаж Биржид харилцагчаар
                  бүртгүүлсэн, бүртгүүлэх оролдлого хийх тохиолдол бүрийг
                  Биржээс шууд тогтоох боломжгүй тул Та өөрийн Хувийн мэдээллийг
                  өөрөө хамгаалах үүрэг хүлээж буйн хувьд энэ талаарх холбогдох
                  санал, хүсэлтийг Биржид гаргаж шийдвэрлүүлэх боломжтой.
                </p>
              </li>
              <li>
                <p>
                  Бүртгүүлэхэд авсан Таны хувийн мэдээллийг Таныг таньж мэдэх,
                  мөнгө угаах, терроризмыг санхүүжүүлэх, залилан мэхлэх, бусад
                  санхүүгийн гэмт хэргийг илрүүлэх, хуульд нийцсэн бусад
                  зорилгоор ашиглана.
                </p>
              </li>
              <li>
                Таныг таньж мэдэх хүрээнд шаардлагатай тохиолдолд мэдээллийг
                шалган баталгаажуулах хугацаа тогтоох болон нэмэлт бусад
                мэдээллийг Биржээс шаардаж болно.
              </li>
              <li>
                <p>
                  Таны өгсөн мэдээлэл худал, буруу, хуучирсан зэрэг үндэслэл
                  гарвал засах, устгах мэдэгдлийг Бид илгээж болох бөгөөд,
                  ийнхүү илгээх үүргийг Бирж хүлээхгүй.
                </p>
              </li>
              <li>
                <p>
                  Таны мэдээлэл худал, буруу, хуучирсан, шинэчлэгдээгүй
                  шалтгаанаар Таныг таньж мэдэх, баталгаажуулалт хийх процесс
                  хийгдэхгүй байж болно. Энэ тохиолдолд Таны “COINHUB”
                  Үйлчилгээг авах, Биржид нэвтрэх, хэтэвчинд орлого, зарлага
                  хийх бүх төрлийн гүйлгээ, шилжүүлэг хийгдэхгүй байх боломжтой.
                </p>
              </li>
              <li>
                <p>
                  Та харилцагчаар бүртгүүлэхэд өгч буй өөрийн мэдээллийг үнэн
                  зөв, бүрэн оруулах бөгөөд эдгээр мэдээллийн үнэн зөв,
                  эргэлзээгүй байдлыг хангахын тулд мэдээллээ байнга шинэчлэх
                  ёстой.
                </p>
              </li>
              <li>
                <p>
                  Таны бүртгүүлсэн холбоо барих мэдээллийн дагуу Бид тантай
                  холбогдож чадаагүй тохиолдолд түүний үр дагавраар бий болсон
                  аливаа үр дагавар, хохирлыг Та өөрөө бүрэн хариуцна.
                </p>
              </li>
              <li>
                <p>
                  Харилцагч бүр зөвхөн нэг удаа бүртгүүлэх бөгөөд Хэтэвчээр
                  хийгдэх бүх гүйлгээ, шилжүүлгийг өөрөө дангаар, бүрэн
                  хариуцна.
                </p>
              </li>
              <li>
                <p>
                  Бид Таны гаргаж өгсөн хувийн мэдээллийг Нууцлалын бодлого-д
                  заасны дагуу цуглуулж, ашиглаж бас хуваалцана.
                </p>
              </li>

              <li>
                <p>
                  Бирж Таны бүртгэлийг баталгаажуулахаас татгалзах бүрэн эрхтэй.
                </p>
              </li>
              <li>
                <p>
                  Таны бүртгэлийг Биржээс баталгаажуулсны дараа Таныг “COINHUB”
                  Үйлчилгээг авахад зориулсан Хэтэвч автоматаар нээгдэнэ.
                </p>
              </li>
              <li>
                <p>
                  Бирж нь таны 3,000,000 (гурван сая) төгрөг болон түүнээс дээш
                  дүнтэй виртуал хөрөнгийг шилжүүлэхэд бүртгэлийн болон
                  хэтэвчний холбогдох мэдээллийг хүлээн авагч талын эсвэл энэхүү
                  мэдээллийг дамжуулах чиг үүрэг бүхий гуравдагч этгээдэд
                  хүргүүлж болно.
                </p>
              </li>
            </ol>
            <h2 className="mb-15">“COINHUB” ҮЙЛЧИЛГЭЭ</h2>
            <ol>
              <li>
                <p>
                  “COINHUB” Үйлчилгээ авах Таны эрх нь Үйлчилгээний нөхцөл,
                  Гэрээнд заасан нөхцөлүүдээр тодорхойлогдож хязгаарлагдах,
                  бусдад үл шилжих, онцгой бус эрх байна.
                </p>
              </li>
              <li>
                <p>
                  Та “COINHUB” Үйлчилгээг Гэрээ болон Үйлчилгээний нөхцөлд
                  заагдаагүй бусад арга болон зорилгоор ашиглахыг хориглоно.
                </p>
              </li>
              <li>
                <p>
                  “COINHUB” Үйлчилгээтэй холбоотой зураг, дүрс, дуу, видео,
                  бичлэг, текст, программ хангамж, бүтээгдэхүүн, мэдээлэл,
                  баримт бичиг, компьютерын код, загвар, бүтэц зэрэг бүхий л
                  зүйлс нь “COINHUB”-ийн дангаар эзэмших оюуны өмч байна.
                </p>
              </li>
              <li>
                <p>
                  Харилцагчаас и-мэйл болон Биржийн платформоор дамжуулан
                  ирүүлсэн “COINHUB” Үйлчилгээний талаарх мэдэгдэл, санал,
                  хүсэлт зэрэг нь бүгд Биржийн өмч байна.
                </p>
              </li>
              <li>
                <p>
                  Та өөрийн авч буй “COINHUB” Үйлчилгээтэй холбогдох бүхий л
                  нууц мэдээллийг өөрөө хадгалах, нууцлах, бүртгэлийн мэдээллээ
                  тухай бүр шинэчлэх үүрэгтэй бөгөөд энэхүү үүргээ
                  биелүүлээгүйгээс Танд хохирол учирвал хохирол, үр дагаврыг
                  Бирж хариуцахгүй.
                </p>
              </li>
              <li>
                <p>
                  Та Биржээс “COINHUB” Үйлчилгээтэй холбоотой гаргасан албан
                  ёсны мэдэгдэл, мэдээ, танилцуулга зэрэг бусад материалтай цаг
                  тухай бүр нь танилцаж, тэдгээрт анхааруулсан асуудлаар тухай
                  бүр хувийн арга хэмжээ авч байх үүрэгтэй бөгөөд аливаа үйлдэл,
                  эс үйлдэхүйгээс Танд учирсан хохирол, алдагдлыг Бирж
                  хариуцахгүй.
                </p>
              </li>
            </ol>
            <h2 className="mb-15">ХЭТЭВЧНИЙ ХЭРЭГЛЭЭ</h2>
            <ol>
              <li>
                <p>
                  Хэтэвчний хэрэглээ гэдэгт Харилцагч “COINHUB” Үйлчилгээг авах
                  зорилгоор хэтэвчинд орлого, зарлага хийх зэргээр бүх төрлийн
                  гүйлгээ хийж, ашиглаж байгааг ойлгоно. Хэтэвчийг зөвхөн
                  бүртгүүлсэн Харилцагч өөрөө ашиглах ба хэтэвчинд орлого,
                  зарлага хийх эрхийг зөвхөн тухайн Харилцагч эдэлнэ.
                </p>
              </li>
              <li>
                <p>
                  Хэтэвчийг бүртгүүлсэн Харилцагчаас өөр этгээд ашиглаж байгаа
                  тохиолдолд Биржээс Хэтэвчийг хаах эрхтэй.
                </p>
              </li>
              <li>
                <p>
                  Таны хайнга, санаатай, болгоомжгүй байдлын үр дагавраар аливаа
                  гуравдагч этгээд Таны Хэтэвчийг ашигласнаас үүссэн алдагдал,
                  хохирлыг Бирж хариуцахгүй.
                </p>
              </li>
              <li>
                <p>
                  Бирж нь Харилцагчийн виртуал хөрөнгийн шилжүүлгийн аюулгүй
                  байдлыг хамгаалахад анхаарна.
                </p>
              </li>
              <li>
                <p>
                  Харилцагч Биржид нэвтрэх нэр, нууц үгээ аливаа гуравдагч
                  этгээдэд задруулахгүй, дамжуулахгүй байх, хувийн мэдээллийн
                  аюулгүй байдлаа хангах, хамгаалах үүрэгтэй.
                </p>
              </li>
              <li>
                <p>
                  Харилцагч өөрийн нэвтрэх нэр, нууц үгийн аюулгүй байдлыг
                  хамгаалах хүрээнд Хэтэвчнээс хийгдэж буй шилжүүлэг, гүйлгээ
                  бүрд хариуцлага хүлээнэ.
                </p>
              </li>
              <li>
                <p>
                  Хэтэвчийг Таны хяналтаас гадуур, аливаа эрхгүй этгээд
                  ашигласан, ашиглаж байгаа тохиолдолд мэдсэн даруй Та Биржид
                  мэдэгдэх үүрэгтэй.
                </p>
              </li>
              <li>
                <p>
                  Харилцагч “COINHUB” Үйлчилгээг авч, дуусан системээс гарах үед
                  хэрэглэсэн төхөөрөмжид нэвтрэх нэр, нууц үгийн мэдээлэл
                  хадгалагдсан эсэх, эсхүл системээс гаралгүй интернэт хөтчийг
                  хаасан эсэхээ байнга шалгаж, нягтлах замаар аюулгүй байдлыг
                  хангах үүрэгтэй.
                </p>
              </li>
              <li>
                <p>
                  Та өөрийн Хэтэвч дэх үлдэгдлийг хянах бүрэн эрхтэй бөгөөд
                  аливаа “COINHUB” Үйлчилгээг авахад шаардлагатай хэмжээгээр
                  хэтэвчийг цэнэглэх, үлдэгдлийг зохицуулах нь зөвхөн Таны
                  үйлдлээс шууд хамаарах тул Бирж үүнд холбогдох аливаа
                  хариуцлага хүлээхгүй.
                </p>
              </li>
              <li>
                <p>
                  Та Гэрээний нөхцөлийг зөрчсөн, аливаа хууль бус ажиллагаа
                  явуулсан болон Гэрээнд тодорхойлон заасан үндэслэлээр Таны
                  “COINHUB” Хэтэвчний хэрэглээг Биржээс зогсоох, царцаах, цуцлах
                  арга хэмжээг авна.
                </p>
              </li>
              <li>
                <p>
                  Таны “COINHUB” Хэтэвчний хэрэглээ, захиалгын түүх нь Биржийн
                  харилцагчийн талбарт хадгалагдана.
                </p>
              </li>
              <li>
                <p>
                  Харилцагч Биржээс тогтоосон аливаа хязгаарлалтад ороогүй,
                  “COINHUB” Үйлчилгээг зөрчилгүй, хэвийн авч буй үед түүний
                  анхны шаардлагаар Хэтэвч дэх фиат, виртуал хөрөнгийг
                  шилжүүлэх, зарлагын гүйлгээг гүйцэтгэнэ.
                </p>
              </li>
              <li>
                <p>
                  Та “COINHUB” Хэтэвчинд орлого хийх, зарлага гаргах хугацаа нь
                  Харилцагчийн ашиглаж буй эсхүл Биржийн ашиглаж буй банкны
                  дансны үйлчилгээ үзүүлэгч гуравдагч этгээдийн үйл
                  ажиллагаанаас хамаарч удаашрах, цуцлагдах хариуцлагыг Бирж
                  хүлээхгүй.
                </p>
              </li>
              <li>
                <p>
                  Биржээс “COINHUB” Үйлчилгээний аюулгүй байдлыг хангах, МУТС,
                  залилан мэхлэх, бусад санхүүгийн гэмт хэргийн эрсдэлээс
                  сэргийлэх, хэвийн үйл ажиллагаанд сөрөг нөлөөлөл үзүүлэхээс
                  сэргийлэх зорилгоор Таны “COINHUB” Хэтэвчний үлдэгдлийн доод
                  хэмжээ, өдрийн, долоо хоногийн, сарын зэрэг тодорхой
                  хугацаатай гүйлгээ, зарлагын хэмжээнд дээд хязгаар тогтоож
                  болно.
                </p>
              </li>
              <li>
                <p>
                  Харилцагч Хэтэвчнээс шилжүүлэг хийх тохиолдолд урьдчилан
                  цэнэглэсэн байх бөгөөд гүйлгээ, шилжүүлгийн хэмжээ нь
                  Хэтэвчний үлдэгдлийн хэмжээгээр хязгаарлагдана.
                </p>
              </li>
              <li>
                <p>
                  Та Хэтэвчнээс шилжүүлэг хийх хэмжээ, үнийн дүнг нягтлах
                  үүрэгтэй бөгөөд захиалгыг буруу, алдаатай оруулснаас үүсэх үр
                  дагаврыг Бирж хариуцахгүй.
                </p>
              </li>
              <li>
                <p>
                  Харилцагчийн авч буй “COINHUB” Үйлчилгээнээс хамаарч Хэтэвчний
                  хэрэглээнд ялгаатай шаардлага, нөхцөлд тавигдаж болох бөгөөд
                  тэдгээрийг Үйлчилгээний нөхцөлд тухайлан зааж болно.
                </p>
              </li>
            </ol>
            <h2 className="mb-15">
              БИРЖИЙН ҮЙЛ АЖИЛЛАГАА БА ҮЙЛЧИЛГЭЭНИЙ ШИМТГЭЛ
            </h2>
            <ol>
              <li>
                <p>
                  “COINHUB” нь виртуал хөрөнгийн Арилжаа, шилжүүлэг, хадгалалт,
                  удирдлага, олон нийтэд санал болгох, худалдах виртуал
                  хөрөнгийн үйлчилгээ үзүүлэгч цахим Бирж юм.
                </p>
              </li>
              <li>
                <p>
                  Бирж нь “COINHUB” Үйлчилгээгээр дамжиж буй мэдээллийг хадгалах
                  боловч үнэн зөв байдал, түүний холбоотой, хэрэгцээт байдалд
                  баталгаа өгөхгүй бөгөөд тэдгээрийн агуулгаас шууд болон шууд
                  бусаар учирсан аливаа хохирлыг хариуцахгүй.
                </p>
              </li>
              <li>
                <p>
                  Биржийн “COINHUB” Үйлчилгээний талаарх мэдээлэл нь
                  Харилцагчийг хараат бусаар шийдвэр гаргахад нь туслалцаа
                  үзүүлэхэд чиглэх боловч хөрөнгө оруулалтын болон арилжааны
                  зөвлөх үйлчилгээ биш тул Биржийн цахим хуудсанд байршуулсан
                  мэдээллийг харилцагч ашиглах болон тайлбарлахад ямар нэг
                  хариуцлага хүлээхгүй.
                </p>
              </li>
              <li>
                <p>
                  Та Биржийн цахим хуудсанд тусгайлан эсхүл Үйлчилгээний нөхцөлд
                  тодорхойлсон тухайн “COINHUB” Үйлчилгээнд хамаарах шимтгэлийг
                  Биржид төлөх бөгөөд шимтгэлийг Үйлчилгээг ашиглах үед Таны
                  Хэтэвчнээс шууд суутгана.
                </p>
              </li>
              <li>
                <p>
                  Бирж “COINHUB” Үйлчилгээний шимтгэлийн хэмжээг хэзээ ч өөрчилж
                  болох ба шинэчлэгдсэн тухай мэдээллийг Биржийн цахим хуудсанд
                  нийтэлсэн өдрөөс эхлэн мөрдөгдөнө.
                </p>
              </li>
            </ol>
            <h2 className="mb-15">ХАРИЛЦАГЧИЙН ЭРХ, ҮҮРЭГ</h2>
            <ol>
              <li>
                <p>Харилцагч дараах эрхтэй. Үүнд:</p>
              </li>
              <ol>
                <li>
                  <p>
                    Бирж дэх бүртгэлээ идэвхгүй төлөвт шилжүүлэх хүсэлт гаргах;
                  </p>
                </li>
                <li>
                  <p>Бүртгэлийн мэдээлэлд өөрчлөлт орох бүрт шинэчлэх;</p>
                </li>
                <li>
                  <p>
                    Шаардлага хангасан тохиолдолд “COINHUB” Үйлчилгээг авах;
                  </p>
                </li>
                <li>
                  <p>Гэрээнд заасан хүрээнд өөрийн Хэтэвчийг хэрэглэх;</p>
                </li>
              </ol>
              <li>
                <p>Харилцагч дараах үүрэгтэй. Үүнд:</p>
              </li>
              <ol>
                <li>
                  <p>
                    “COINHUB” Үйлчилгээг аливаа хууль тогтоомж, Гэрээ болон
                    “COINHUB” Үйлчилгээний нөхцөл, шаардлагад нийцүүлж ашиглах;
                  </p>
                </li>
                <li>
                  <p>
                    “COINHUB” Үйлчилгээг ашиглахдаа нийтийн эрх ашиг, ёс
                    суртахуун, нийтээр хүлээн зөвшөөрөгдсөн хэм хэмжээг болон
                    бусад этгээдийн хууль ёсны эрх, ашиг сонирхлыг зөрчих,
                    сөргөөр нөлөөлөх үйлдэл хийхгүй бөгөөд “COINHUB” Үйлчилгээг
                    бусад Харилцагч ашиглахад хориг саад учруулахгүй байх;
                  </p>
                </li>
                <li>
                  <p>
                    “COINHUB” Үйлчилгээний явцад залилан хийхгүй, арилжааг
                    урвуулан ашиглах (тухайлбал, хуурамч арилжаа хийсэн, эсхүл
                    үнэ ханшийг зохиомлоор тогтоосон, эсхүл харилцагчийг
                    арилжаанд оролцуулах, эсхүл оролцуулахгүй байх зорилгоор
                    хууран мэхэлсэн, хэт хөөргөдөл болон огцом уналтын
                    төлөвлөгөө боловсруулах, хууль бус худал мэдээлэл түгээх,
                    өөртэйгөө арилжаа хийх, мэдээллийг урьдчилж авах тохиролцоо
                    хийх, их хэмжээний хуурамч захиалга хийх, халхавчлах эсвэл
                    давхар захиалга хийх зэргээр хуулиар хориглосон эсэхээс үл
                    хамаарч энэ төрлийн үйл ажиллагаанууд)-гүй байх;
                  </p>
                </li>
                <li>
                  <p>
                    Бирж дэх виртуал хөрөнгийн ханшийн мэдээлэл, зах зээлийн
                    талаарх аливаа мэдээллийг ашиг олох зорилгоор Биржийн
                    зөвшөөрөлгүйгээр ашиглах;
                  </p>
                </li>
                <li>
                  <p>
                    Бирж дэх виртуал хөрөнгийн ханшийн мэдээлэл, зах зээлийн
                    талаарх аливаа мэдээллийг Биржийн зөвшөөрөлгүйгээр ашиглаж
                    мэдээллээр хангах, мэдээлэл түгээх үйлчилгээ явуулах;
                  </p>
                </li>
                <li>
                  <p>
                    Бирж дэх виртуал хөрөнгийн ханшийн мэдээлэл, зах зээлийн
                    талаарх аливаа мэдээллийг ашиглаж, аливаа төлбөр авдаг буюу
                    орлого олох аппликейшн, цахим хуудас, үйлчилгээ
                    /сурталчилгааны болон харилцагчийн мэдээлэл өгөх хөлс авах
                    гэх мэт үүгээр хязгаарлагдахгүй бусад/ үзүүлэхгүй байх;
                  </p>
                </li>
                <li>
                  <p>
                    Биржээс бичгээр авсан зөвшөөрөлгүйгээр түүний өмчлөлийн
                    аливаа мэдээлэл, зөвшөөрөл, загвар, тэмдэглэгээ, бараан
                    тэмдэг зэргийг хэсэгчилсэн болон бүхэлд нь хувилж олшруулах,
                    хуулбарлах, өөрчлөх, хадгалах, шилжүүлэх, олж авах, нийтлэх,
                    дамжуулах, устгах;
                  </p>
                </li>
                <li>
                  <p>
                    Биржид бүртгүүлэх өөрийн хувийн мэдээллийг үнэн зөв, бүрэн
                    дүүрэн гаргаж өгөх;
                  </p>
                </li>
                <li>
                  <p>
                    “COINHUB” Үйлчилгээ авсны үр дагавраар өөрт үүсэх эрсдэлийг
                    дангаар хариуцах;
                  </p>
                </li>
                <li>
                  <p>
                    Биржийн албан ёсны цахим хуудсанд байршуулсан Гэрээний
                    нөхцөл, Үйлчилгээний нөхцөл, Нууцлалын бодлого, Cookies
                    бодлого, тэдгээрийн өөрчлөлтүүдтэй байнга танилцах;
                  </p>
                </li>
                <li>
                  <p>
                    Техникийн алдаа, алдаатай шилжүүлгийн улмаас өөрийн
                    Хэтэвчинд үндэслэлгүйгээр виртуал хөрөнгө шилжиж орсон
                    тохиолдолд даруй Биржид мэдэгдэж, Биржээс арга хэмжээ авч
                    дуустал захиран зарцуулахгүй байх;
                  </p>
                </li>
                <li>
                  <p>
                    Гэрээ, Үйлчилгээний нөхцөл, Нууцлалын бодлогод заасан аливаа
                    үүргийг зөрчсөнөөс Бирж, бусад харилцагчид учирсан хохирлыг
                    барагдуулах.
                  </p>
                </li>
              </ol>
            </ol>
            <h2 className="mb-15">БИРЖИЙН ЭРХ, ҮҮРЭГ</h2>
            <ol>
              <li>
                <p>Бирж дараах эрхтэй. Үүнд:</p>
              </li>
              <ol>
                <li>
                  <p>
                    Гэрээ, Үйлчилгээний нөхцөл, Нууцлалын бодлого, Cookies
                    бодлого зэрэг холбоотой бусад баримт бичгүүдийн нөхцөлийг
                    хэдийд ч шинэчлэх, сайжруулах, хүчингүй болгох, өөрчлөх;
                  </p>
                </li>
                <li>
                  <p>
                    Гэрээ, Үйлчилгээний нөхцөл, Нууцлалын бодлогод заасан
                    үндэслэлээр Харилцагчийн ашиглаж буй “COINHUB” Үйлчилгээ
                    болон Хэтэвчний хэрэглээнд хязгаарлалт хийх, зогсоох, хаах;
                  </p>
                </li>
                <li>
                  <p>
                    “COINHUB” Үйлчилгээнд хамаарах бусад холбоотой баримт бичиг,
                    дүрэм, журмыг баталж, мөрдүүлэх;
                  </p>
                </li>
              </ol>
              <li>
                <p>Бирж дараах үүрэгтэй. Үүнд:</p>
              </li>
              <ol>
                <li>
                  <p>
                    “COINHUB” Үйлчилгээний техникийн хэвийн үйл ажиллагааг
                    хангах;
                  </p>
                </li>
                <li>
                  <p>
                    Үндэсний болон олон улсын хууль тогтоомж, эрх бүхий
                    байгууллагын шийдвэрт нийцүүлэн “COINHUB” Үйлчилгээнд
                    өөрчлөлт оруулах;
                  </p>
                </li>
                <li>
                  <p>
                    Өөрийн ажилтны санаатай, хууль зөрчсөн үйлдлийн улмаас
                    Харилцагчид учруулсан хохирлыг барагдуулах;
                  </p>
                </li>
                <li>
                  <p>
                    Биржийн ажилтны буруутай техникийн үйл ажиллагааны алдаанаас
                    хамаарч Харилцагчийн хэтэвчнээс виртуал хөрөнгийг шилжүүлсэн
                    тохиолдолд шилжүүлсэн виртуал хөрөнгийг шуурхай Харилцагчийн
                    хэтэвчинд буцаан шилжүүлэх;
                  </p>
                </li>
              </ol>
            </ol>
            <h2 className="mb-15">
              “COINHUB” ҮЙЛЧИЛГЭЭ, ХЭТЭВЧНИЙ ХЯЗГААРЛАЛТ
            </h2>
            <ol>
              <li>
                <p>
                  Та “COINHUB” Үйлчилгээг авах үедээ холбогдох хууль тогтоомж,
                  Гэрээний нөхцөл, Үйлчилгээний нөхцөл, Нууцлалын бодлогыг
                  зөрчсөн гэж Бирж сэжиглэсэн тохиолдолд Таны Таны авч буй
                  “COINHUB” Үйлчилгээг зогсоон, Хэтэвчний гүйлгээг царцаах
                  эрхтэй. Энэ тохиолдолд Таны Хэтэвчийг царцааснаас үүсэх үр
                  дагаврыг Бирж хариуцахгүй.
                </p>
              </li>
              <li>
                <p>
                  Бирж дараах тохиолдолд Хэтэвчний хяналтыг заавал хэрэгжүүлнэ.
                  Үүнд:
                </p>
              </li>
              <ol>
                <li>
                  <p>
                    Хэтэвчний гүйлгээ нь засгийн газрын байгууллагын үйл
                    ажиллагаа, эрүүгийн хэргийн мөрдөн шалгах ажиллагаа эсвэл
                    шүүхээр шийдвэрлэж буй аливаа маргаанд хамааралтай байх;
                  </p>
                </li>
                <li>
                  <p>
                    Хэтэвчний гүйлгээтэй холбоотой хууль бус үйл ажиллагааг
                    илрүүлсэн;
                  </p>
                </li>
                <li>
                  <p>Хэтэвчинд зөвшөөрөлгүй нэвтэрсэн тохиолдлыг илрүүлсэн;</p>
                </li>
                <li>
                  <p>Шүүх болон эрх бүхий байгууллагаас шаардсан;</p>
                </li>
                <li>
                  <p>
                    Техникийн алдаа болон алдаатай шилжүүлгийн улмаас бий болсон
                    үр дагаврыг арилгах зорилгоор;
                  </p>
                </li>
              </ol>
              <li>
                <p>
                  Харилцагч нас барсан тохиолдолд түүний хууль ёсны өв
                  залгамжлагч хуульд заасны дагуу Харилцагчийн эд хөрөнгийг
                  өвлөсөн тухай хууль ёсны баримт бичгийг үндэслэн Биржид
                  бүртгүүлж, Хэтэвч нээлгэх хүртэл хугацаанд нас барсан
                  Харилцагчийн Хэтэвчийг царцаана. Өв залгамжлагчийн Биржид
                  шинээр нээгдсэн Хэтэвчинд нас барсан Харилцагчийн Хэтэвчний
                  үлдэгдлийг шилжүүлж, нас барсан Харилцагчийн хэтэвчийг хаана.
                </p>
              </li>
              <li>
                <p>
                  Бирж дараах тохиолдолд Таны авч буй “COINHUB” Үйлчилгээг
                  зогсоон Хэтэвчийг шууд хаах эрхтэй. Үүнд:
                </p>
              </li>
              <ol>
                <li>
                  <p>
                    Та бусад этгээд, хүний нэрийг ашиглан Харилцагчаар шууд
                    болон шууд бусаар дахин бүртгүүлсэн, бүртгүүлэхээр оролдсон;
                  </p>
                </li>
                <li>
                  <p>
                    Таны бүртгэл дэх мэдээлэл худал, хуучирсан, бүрэн бус
                    байсан;
                  </p>
                </li>
                <li>
                  <p>
                    Гэрээний нөхцөлд нэмэлт, өөрчлөлт ороход Та тухайн нэмэлт
                    өөрчлөлтийг зөвшөөрөхгүй үндэслэлээр Хэтэвчээ хаалгах хүсэлт
                    гаргасан;
                  </p>
                </li>
                <li>
                  <p>Та бүртгэл, Хэтэвчээ хаалгах хүсэлт гаргасан;</p>
                </li>
                <li>
                  <p>
                    Гэрээнд хориглосон хориглолтыг зөрчсөн, хүлээсэн үүргийг
                    биелүүлээгүй болох нь тогтоогдсон;
                  </p>
                </li>
                <li>
                  <p>
                    Харилцагч бүртгүүлснээс хойш 1 (нэг) жилийн турш Хэтэвчийг
                    аливаа хэлбэрээр ашиглаагүй буюу идэвхгүй байсан;
                  </p>
                </li>
                <li>
                  <p>
                    Биржийн зүгээс Хэтэвчийг хаах бусад үндэслэл тогтоогдсон;
                  </p>
                </li>
              </ol>
              <li>
                <p>
                  Таны Хэтэвчийг хаах үед Хэтэвч дэх үлдэгдлээс Биржид төлөх
                  шимтгэлийг тэргүүн ээлжид суутгаж төлүүлэх бөгөөд үлдэгдлийг
                  Хэтэвчийг хааснаас хойш ажлын 10 хоногийн дотор авах
                  боломжтой.
                </p>
              </li>
              <li>
                <p>
                  Гэрээний нөхцөл, Үйлчилгээний нөхцөл, хууль тогтоомжийг
                  зөрчсөн үндэслэлээр Хэтэвчийг хаасан тохиолдолд үлдэгдлийг эрх
                  залгамжлагч эсхүл эрхий бүхий байгууллагаас шаардсан
                  тохиолдолд Үйлчилгээний шимтгэлээ суутгасан дүнг шилжүүлнэ.
                </p>
              </li>
              <li>
                <p>
                  Биржээс Гэрээ болон Үйлчилгээний нөхцөлд заасан үндэслэлээр
                  Таны авч буй “COINHUB” Үйлчилгээг зогсоож, Хэтэвчийг хаах,
                  царцаах зэрэг арга хэмжээ авсны үр дагаврыг Бирж аливаа
                  хэлбэрээр хариуцахгүй.
                </p>
              </li>
              <li>
                <p>
                  Техникийн алдаа, алдаатай шилжүүлгийн улмаас харилцагчийн
                  Хэтэвчинд үндэслэлгүйгээр виртуал хөрөнгө шилжиж орсон
                  тохиолдолд Бирж тухайн хэтэвчинд хязгаарлалт тогтоож, үр
                  дагаврыг арилгах зорилгоор шаардлагатай бүхий л арга хэмжээг
                  авах эрхтэй.
                </p>
              </li>
            </ol>
            <h2 className="mb-3">
              ВИРТУАЛ ХӨРӨНГӨ БОЛОН АРИЛЖААНЫ НИЙТЛЭГ ЭРСДЭЛ
            </h2>
            <ol>
              <li>
                <p>
                  Биржийн арилжааны жагсаалт дахь виртуал хөрөнгө, түүнд
                  хамаарах үйлчилгээ нь дараах үндсэн шинж, эрсдэлийг агуулна.
                  Үүнд:
                </p>
              </li>
              <ol>
                <li>
                  <p>Аль улсын хууль ёсны мөнгөн тэмдэгтэд хамаарахгүй;</p>
                </li>
                <li>
                  <p>
                    Аль нэг улсын Засгийн газар эсвэл Төв банкнаас
                    баталгаажихгүй;
                  </p>
                </li>
                <li>
                  <p>Зээл, үнэт цаас, бонд биш;</p>
                </li>
                <li>
                  <p>
                    Аливаа хуулийн этгээдийн эзэмшил, удирдлагад хэрэгжүүлэх
                    аливаа эрх, тэдгээрийг хэрэгжүүлэх эсвэл үүрэг үүсгэх ямар
                    нэг эрх биш;
                  </p>
                </li>
                <li>
                  <p>
                    Гүйлгээ нь буцаах боломжгүй, залилан мэхлэх шинжтэй, эсхүл
                    алдаатай гүйлгээнээс үүдэн гарах үр дагавар, нөхөн
                    төлөгдөхгүй;
                  </p>
                </li>
                <li>
                  <p>
                    Гүйлгээ хийгдсэн хугацаанаас үл хамааран бүртгэлийн системд
                    бүртгэгдсэн хугацаагаар гүйлгээ хийгдсэнд тооцогддог;
                  </p>
                </li>
                <li>
                  <p>
                    Виртуал хөрөнгийн талаарх олон улсын хэм хэмжээ, дотоодын
                    хууль тогтоомжоос хамаарч виртуал хөрөнгийн үйлчилгээ болон
                    үнэ цэнэ огцом өөрчлөгдөх /буурах, өсөх/;
                  </p>
                </li>
                <li>
                  <p>
                    Залилан мэхлэх болон цахим халдлагад өртөх, алдаатай болон
                    гэмт хэргийн шинжтэй гүйлгээнээс үүсэх үр дагаварт бий болох
                    хохирол нөхөн төлөгдөхгүй байх;
                  </p>
                </li>
                <li>
                  <p>
                    Зах зээлд оролцогчдын эрэлт хэрэгцээнээс хамаарч үнэ цэний
                    хэлбэлзлийн үр дагавраар алдагдал хүлээх;
                  </p>
                </li>
                <li>
                  <p>
                    Хадгаламжийн даатгал, үнэт цаасны хөрөнгө оруулагчдыг
                    хамгаалах аливаа даатгалд хамаардаггүй;
                  </p>
                </li>
                <li>
                  <p>
                    “COINHUB” Үйлчилгээнд технологийн хүндрэл гарсан тохиолдолд
                    харилцагч өөрийн виртуал хөрөнгийг ашиглах, хэрэглэх
                    боломжгүй болох;
                  </p>
                </li>
                <li>
                  <p>
                    Биржийн өмчилж, эсхүл эзэмшиж байгаа хөрөнгө нь харилцагчид
                    учирсан хохирлыг бүрэн барагдуулж хүрэлцэхгүй байх;
                  </p>
                </li>
                <li>
                  <p>
                    Харилцагчийн хөрөнгийг залилан, алдаатай гүйлгээ, төрийн эрх бүхий
                    байгууллагаас хөрөнгийн хязгаарлалт тогтоосон эсхүл хуульд зааснаас
                    бусад тохиолдолд анхны шаардлагаар түүний виртуал хөрөнгийн
                    үйлчилгээнд хамаарах мөнгөн хөрөнгийг гаргаж өгөх, эсхүл виртуал
                    хөрөнгийг шилжүүлнэ.
                  </p>
                </li>
                <li>
                  <p>
                     Виртуал хөрөнгийн үйлчилгээ үзүүлэгчээр бүртгэснийг тухайн виртуал
                    хөрөнгөд өгч байгаа баталгаа гэж үзэхгүй бөгөөд виртуал хөрөнгийн
                    үйлчилгээнд хамаарах үйл ажиллагааны үр дүнд үүссэн хохирлыг төр
                    хариуцахгүй.
                  </p>
                </li>

              </ol>
              <li>
                <p>
                  Харилцагч нь “COINHUB” Үйлчилгээг авахтай холбоотой үүсэх
                  хууль зүйн, санхүүгийн болон татварын үр дагаврын талаар
                  хараат бус, мэргэжлийн байгууллага, хувь хүнээс зөвлөгөө,
                  мэдээлэл авсан, мэдлэгтэй байна.
                </p>
              </li>
              <li>
                <p>
                  Харилцагч нь нь “COINHUB” Үйлчилгээнд холбогдох бүхий л
                  эрсдэл, үр дагаврыг дангаар хариуцна.
                </p>
              </li>
              <li>
                <p>
                  Харилцагч нь “COINHUB” Үйлчилгээний хувийн нэвтрэх нэр, нууц
                  үгийн мэдээллийг бусдад алдахгүй, аюулгүй байдлыг өөрөө
                  дангаар хариуцах бөгөөд Бирж үүнтэй холбоотой аливаа үр
                  дагавар, хохирол, алдагдалд хариуцлага хүлээхгүй талаарх
                  мэдлэгтэй байна.
                </p>
              </li>
              <li>
                <p>
                  Харилцагч нь виртуал хөрөнгө, арилжааны туршлага, мэдлэгтэй,
                  виртуал хөрөнгийг эзэмших, шилжүүлэхэд гарч болох эрсдэлийн
                  талаар мэдлэгтэй байна.
                </p>
              </li>
            </ol>
            <h2 className="mb-15">
              БИРЖИЙН ҮЙЛ АЖИЛЛАГААНД ХҮНДРЭЛ ҮҮСЭХ, ҮЙЛ АЖИЛЛАГААГ ЗОГСООХ,
              БҮРТГЭЛЭЭС ХАСАГДАХ ҮЕД ХЭРЭГЖҮҮЛЭХ ТӨЛӨВЛӨГӨӨ
            </h2>
            <ol>
              <li>
                <p>
                  Кибер халдлага, түрэмгийлэлд өртөх зэрэг онцгой нөхцөл байдал
                  үүссэн, Биржийн үйл ажиллагааг хэвийн үргэлжлүүлэх боломжгүй
                  хүндрэл учирсан тохиолдолд хэрэгжүүлэх арга хэмээний
                  нарийвчилсан төлөвлөгөөний талаар албан ёсны цахим хуудсанд
                  нээлттэй байршуулна.
                </p>
              </li>
              <li>
                <p>
                  Зохицуулагч байгууллагаас Биржийн үйл ажиллагааг түр зогсоох
                  эсхүл бүртгэлээс хасах шийдвэр гарч, хүчин төгөлдөр болсон
                  тохиолдолд хэрэгжүүлэх арга хэмжээний нарийвчилсан
                  төлөвлөгөөний талаар албан ёсны цахим хуудсанд нээлттэй
                  байршуулна.
                </p>
              </li>
              <li>
                <p>
                  Харилцагч 11.1, 11.2-т заасан тохиолдол бий болсон үед
                  төлөвлөгөөний дагуу холбогдох тайлбар, мэдэгдэлд үндэслэн
                  “COINHUB” Үйлчилгээг дуусгавар болгох хэтэвчийг хаалгах
                  хүсэлтийг Харилцагчийн гомдлыг шийдвэрлэх, хариу өгөх журмын
                  дагуу шийдвэрлэнэ.
                </p>
              </li>
            </ol>
            <h2 className="mb-15">ХЯЗГААРЛАГДМАЛ ХАРИУЦЛАГА</h2>
            <ol>
              <li>
                <p>
                  Бирж нь өөрийн болон ажилтнуудын хууль зөрчсөн болон хэт
                  хайхрамжгүй, болгоомжгүй алдааны улмаас Харилцагчийн
                  хэтэвчнээс гарсан эсвэл орох байсан виртуал хөрөнгийн тоо,
                  хэмжээгээр хариуцлага хүлээнэ.
                </p>
              </li>
              <li>
                <p>
                  Бирж нь зөвхөн Гэрээнд заасан хязгаарлагдмал хүрээнд
                  хариуцлага хүлээх тул Харилцагчийн авсан “COINHUB”
                  Үйлчилгээний үр дагавартай холбоотой шууд болон шууд бусаар
                  Харилцагчид бий болох бизнесийн боломж, орлого, ашиг,
                  алдагдал, хохирлыг хариуцах, барагдуулах үүрэг хүлээхгүй.
                </p>
              </li>
              <li>
                <p>
                  Харилцагчийн санаатай болон хайнга, санамсаргүй, болгоомжгүй
                  хийсэн алдаа, үйлдэл, эс үйлдэхүйн улмаас бий болсон үр
                  дагаврыг Бирж хариуцахгүй.
                </p>
              </li>
              <li>
                <p>
                  Бирж нь “COINHUB” Үйлчилгээнд нэвтрэх, бүртгүүлэх, ашиглах,
                  захиалга хийх, виртуал хөрөнгийн үнэ, ханшийн мэдээлэл,
                  гуравдагч этгээдийн халдлага зэрэг арилжаатай холбоотой бүхий
                  л асуудалд аливаа баталгаа өгөхгүй бөгөөд тэдгээрийн шууд
                  болон шууд бус үр дагавраар харилцагчид учирсан хохирол,
                  алдагдал, олох байсан орлого, ашиг зэрэг эдгээрээр
                  хязгаарлагдахгүй аливаа төрлийн хариуцлагыг хүлээхгүй.
                </p>
              </li>
              <li>
                <p>
                  Бирж нь Харилцагчийн брокер, зуучлагч, төлөөлөгч эсвэл зөвлөх
                  биш тул Харилцагч “COINHUB” Үйлчилгээг авах эсэх шийдвэр
                  гаргалтад зөвлөн туслахгүй, түүнтэй холбогдох аливаа үүрэг,
                  хариуцлага хүлээхгүй.
                </p>
              </li>
              <li>
                <p>
                  Биржээс гаргасан мэдэгдэл, мэдээ, мэдээлэл нь хөрөнгө
                  оруулалт, санхүү, арилжааны зэрэг аливаа бусад төрлийн
                  зөвлөгөө биш тул түүний үр дагаврыг Бирж хариуцахгүй.
                </p>
              </li>
            </ol>
            <h2 className="mb-15">ГЭРЭЭГ ШИНЭЧЛЭХ</h2>
            <ol>
              <li>
                <p>
                  Бирж нь Гэрээний нөхцөлийг шинэчилсэн тухай мэдэгдлийг Таныг
                  Биржид нэвтэрч үйлчилгээ авах үед автоматаар харуулж,
                  танилцсан тухай тэмдэглэл хийлгэнэ.
                </p>
              </li>
              <li>
                <p>
                  Гэрээний аливаа өөрчлөлт нь Биржийн вэбсайтад нийтлэгдсэн үеэс
                  эхлэн хүчин төгөлдөр үйлчилнэ.
                </p>
              </li>
              <li>
                <p>
                  Та шинэчилсэн Гэрээтэй танилцах үүрэгтэй тул тухай бүр
                  танилцаж байхыг Танд зөвлөж байна.
                </p>
              </li>
              <li>
                <p>
                  Хэрэв Та шинэчлэгдсэн Гэрээг мөрдөх боломжгүй гэж үзвэл Та
                  “COINHUB” Үйлчилгээг авахаа зогсоох шаардлагатай.
                </p>
              </li>
              <li>
                <p>
                  Гэрээ шинэчлэгдсэний дараа ч Та “COINHUB”-ын Үйлчилгээг
                  үргэлжлүүлэн авч байгаа тохиолдолд Таныг Гэрээг хүлээн
                  зөвшөөрсөн гэж үзнэ.
                </p>
              </li>
            </ol>
            <h2 className="mb-15">ГОМДОЛ, МАРГААН ШИЙДВЭРЛЭХ</h2>
            <ol>
              <li>
                <p>
                  Харилцагчаас “COINHUB” Үйлчилгээтэй холбоотой гаргасан санал,
                  хүсэлт, гомдлыг Биржийн  Лавлах утас (976)-75076060, Сошиал
                  сувгууд, callcenter@coinhub.mn  хаягаар хүлээн авч
                  “Харилцагчийн гомдлыг шийдвэрлэх, хариу өгөх журам”-ын дагуу
                  шийдвэрлэж, хариу өгнө.
                </p>
              </li>
              <li>
                <p>
                  Гэрээтэй холбоотой гарсан маргаантай асуудлыг Биржийн
                  “Харилцагчийн гомдлыг шийдвэрлэх, хариу өгөх журам”-ын дагуу
                  шийдвэрлэснийг Харилцагч хүлээн зөвшөөрөхгүй тохиолдолд Монгол
                  Улсын хуулийн дагуу Монгол Улсын Олон Улсын Арбитрт хандаж
                  маргааныг эцэслэн шийдвэрлүүлнэ.
                </p>
              </li>
              <li>
                <p>
                  Маргааныг шийдвэрлэхэд Таны болон Биржээс ирүүлсэн, харилцан
                  солилцсон и-мэйл болон цахим мэдэгдлүүдийг маргааныг
                  шийдвэрлэх нотлох баримт гэж үзнэ.
                </p>
              </li>
            </ol>
            <h2 className="mb-15">БУСАД</h2>
            <ol>
              <li>
                <p>
                  Гэрээ нь Та болон Биржийн хооронд бий болох харилцаанд хуулиар
                  тодорхой заагаагүй тохиолдолд тэргүүн ээлжид баримтлах
                  зохицуулалтын баримт бичиг болно.
                </p>
              </li>
              <li>
                <p>
                  Гэнэтийн болон Давагдашгүй хүчин зүйлс жишээ нь: байгалийн
                  гамшигт үзэгдэл, цахилгаан, эрчим хүчний уналт, тасалдал,
                  төрийн байгууллагын шийдвэр, иргэдийн бослого хөдөлгөөн,
                  террорист халдлага, гал түймэр, цар тахал зэрэг эдгээрээр
                  хязгаарлагдахгүй. Биржийн боломжит хяналтаас гадуурх аливаа
                  үйлдэл, эс үйлдэхүй, хүчин зүйлсийн үр дагаварт хариуцлага
                  хүлээхгүй.
                </p>
              </li>
              <li>
                <p>
                  Гэрээний аль нэг заалт, хэсэг нь хүчин төгөлдөр бус болохыг
                  эрх бүхий байгууллагаас тогтоосон нь Гэрээг бүхэлд нь болон
                  бусад хэсгийг хүчингүй болгох үндэслэл болохгүй бөгөөд тэдгээр
                  нь үйлчилсэн хэвээр байна.
                </p>
              </li>
              <li>
                <p>
                  Та “COINHUB” Үйлчилгээ авахтай холбоотой аливаа эрх, үүргийг
                  бусад этгээдэд Биржийн бичгээр өгсөн зөвшөөрлөөс бусад
                  тохиолдолд шилжүүлэхийг хориглоно.
                </p>
              </li>
            </ol>
          </div>
        </div>
      </div>
      <div className="CheckboxText">
        <CheckboxCustom
          checkboxTxt={
            <p>
              <Trans i18nKey="signup.term">
                <Link></Link>
              </Trans>
            </p>
          }
          onChange={(e) => setConcentVal(e.target.checked)}
          checked={concentVal}
        />
      </div>
      {
        <div className="btmBtnSec">
          <button
            className="scroll-to-bottom-button"
            onClick={handleSubmit}
            disabled={!concentVal}
          >
            Submit
          </button>
        </div>
      }
    </div>
  );
};

export default Agreement;
