import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import CommonButton from "../../Ui/button/CommonButton";
import { StoreImages } from "../../Storeimgaes/StoreImages";
import style from "./style.module.scss";
import { uppercase } from "../../../helpers";
import CommonModal from "../../CommonModal";
import AddAddress from "./AddAddress";
import { addBeneficiary, fetchBeneficiary } from "../../../redux/services";
import { popupStatus } from "../../../redux/feature";

const AddressBook = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { beneficiaryList } = useSelector((state) => state.sendReceive);
  const { selectAddress } = StoreImages;
  const { addressBook, onCancel, cbFun, currCurrency, currNetwork } = props;
  const [isOpenAddressModal, setIsOpenAddressModal] = useState(false); 
useEffect(()=>{
  if (currCurrency) {
    
    dispatch(fetchBeneficiary({ coin: currCurrency }));
  }
},[])
  const onAdd = async (data) => {
    const sendData = {
      data: {
        address: data.address,
        name: data.addressLabel,
      },
      currency: data.currencyObj.currency,
      blockchain_key: data.currentNetwork.blockchain_key,
      name: data.addressLabel,
      pin: data.code,
    };

    let res = await dispatch(addBeneficiary({ ...sendData }));
    if (res.payload.status) {
      await dispatch(fetchBeneficiary({ coin: currCurrency }));
      data.onModalClose();
      dispatch(popupStatus(false))
    }
  };

  return (
    <>
      <CommonModal isOpen={addressBook} onCancel={onCancel}>
        <h2 className="titleModal">{t("addressBook.head")}</h2>
        {beneficiaryList && beneficiaryList.length > 0 ? (
          <div className={style.addressbook}>
            {beneficiaryList
              .filter(items => items?.blockchain_key?.split("-")[0] === currNetwork)
              .map((item, idx) => (
                <div
                  className={`${style.addressbook_list} cursorPointer`}
                  key={idx}
                  onClick={() => {
                    cbFun(item?.data?.address, item?.internal, item?.protocol);
                    onCancel();
                  }}
                >
                  <div className={style.radioSec}>
                    <ul>
                      <li>
                        <p>Name:</p>
                        <span>{item?.data?.name}</span>
                      </li>
                    </ul>
                  </div>
                  <ul>
                    <li>
                      <p>Address:</p>
                      <span title={item?.data?.address}>
                        {" "}
                        {item?.data?.address}
                      </span>
                    </li>
                    <li>
                      <p>Network:</p>
                      <span>
                        {uppercase(item?.blockchain_key?.split("-")[0])}
                      </span>
                    </li>
                  </ul>
                </div>
              ))}
 
            {beneficiaryList
              .filter(items => items?.blockchain_key?.split("-")[0] === currNetwork)
              .length === 0 && (
                <div className={style.addressModal}>
                  <img src={selectAddress} alt="pic" />
                  <p className="text-grey-14">{t("addressBook.desc")}</p>
                </div>
              )}
          </div>
        ) : (
          <div className={style.addressModal}>
            <img src={selectAddress} alt="pic" />
            <p className="text-grey-14">{t("addressBook.desc")}</p>
          </div>
        )}
        <div className={style.btnOver}>
          <CommonButton
            title={t("buttons.add_New_Address")}
            onClick={() => {
              setIsOpenAddressModal(true);
              dispatch(popupStatus(true));
            }}
          />
        </div>
      </CommonModal>

      <AddAddress
        cbFun={onAdd}
        callFrom="sendModal"
        isOpenAddressModal={isOpenAddressModal}
        closeAddressModal={() => { setIsOpenAddressModal(false); dispatch(popupStatus(false)) }}
      />
    </>
  );
};

export default AddressBook;
