import { createSlice } from "@reduxjs/toolkit";
import { getIdentity } from "../../services";

export const loginSlice = createSlice({
  name: "login",
  initialState: {
    error: false,
    identityData: {},
    phoneStatus: false
  },
  reducers: {
    setPhoneStatus: (state, action) => {
      state.phoneStatus = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getIdentity.pending, (state) => {
        state.error = false;
      })
      .addCase(getIdentity.fulfilled, (state, action) => {
        state.identityData = action?.payload?.data;
      })
      .addCase(getIdentity.rejected, (state) => {
        state.error = true;
      });
  },
});
export default loginSlice.reducer;
export const { setPhoneStatus } = loginSlice.actions;
