import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../api";
import { buildQueryString } from "../../helpers";
import * as config from "../../utils/apiVersion";
import { startLoading, stopLoading } from "../feature";
import { errorToastSelector, successToastSelector } from "../selector";
import { getBalance } from "./commonServices";

export const getAddress = createAsyncThunk(
  "sendReceive/getAddress",
  async (payload, { dispatch }) => {
    try {
      let url = `account/deposit_address/${payload.id}?blockchain_key=${payload.blockchain_key}`;
      const res = await API.get(config.peatio)(url);
      return { data: res.address, status: true };
    } catch (e) {
      return false;
    }
  }
);

export const fetchBeneficiary = createAsyncThunk(
  "sendReceive/fetchBeneficiary",
  async (payload, { dispatch }) => {
    try {
      let url = `account/beneficiaries?${buildQueryString(payload)}`;
      const res = await API.get(config.peatio)(url);
      return {
        data: res,
        status: true,
      };
    } catch (e) {
      dispatch(errorToastSelector(e));
      return false;
    }
  }
);

export const fetchWithdrawFees = createAsyncThunk(
  "sendReceive/fetchWithdrawFees",
  async (payload, { dispatch }) => {
    try {
      let url = `public/withdraw_fees?${buildQueryString(payload)}`;
      const res = await API.get(config.peatio)(url);
      return {
        data: res,
        status: true,
      };
    } catch (e) {
      dispatch(errorToastSelector(e));
      return false;
    }
  }
);

export const fetchAllBeneficiary = createAsyncThunk(
  "sendReceive/fetchAllBeneficiary",
  async (payload, { dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `account/beneficiaries?${buildQueryString(payload)}`;
      const res = await API.get(config.peatioWithHeader)(url);
      dispatch(stopLoading());
      return {
        data: res.data,
        total: res.headers.total,
        status: true,
      };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e));
      return false;
    }
  }
);

export const fetchIsInternalAddress = createAsyncThunk(
  "sendReceive/fetchIsInternalAddress",
  async (payload, { dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `account/beneficiaries/validate/internal_transaction?address=${payload}`;
      const res = await API.get(config.peatioWithHeader)(url);
      dispatch(stopLoading());
      return {
        data: res.data,
        // total: res.headers.total,
        // status: true,
      };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e));
      return false;
    }
  }
);
export const addBeneficiary = createAsyncThunk(
  "sendReceive/addBeneficiary",
  async (payload, { dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `account/beneficiaries`;
      const res = await API.post(config.peatio)(url, payload);
      dispatch(
        successToastSelector({
          message: "Beneficiary address added successfully",
        })
      );
      dispatch(fetchAllBeneficiary({}));
      dispatch(stopLoading());
      return { status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e));
      return false;
    }
  }
);

export const removeBeneficiary = createAsyncThunk(
  "sendReceive/removeBeneficiary",
  async (payload, { dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `account/beneficiaries/${payload.id}`;
      await API.delete(config.peatio)(url);
      dispatch(
        successToastSelector({
          message: "Beneficiary address deleted successfully",
        })
      );
      dispatch(stopLoading());
      return { status: true };
    } catch (e) {
      dispatch(errorToastSelector(e));
      dispatch(stopLoading());
      return false;
    }
  }
);

export const createWithdraw = createAsyncThunk(
  "sendReceive/createWithdraw",
  async (payload, { dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `account/withdraws`;
      await API.post(config.peatio)(url, payload);
      // dispatch(successToastSelector({ message: "Send successfully" }));
      dispatch(getBalance());
      dispatch(stopLoading());
      return { status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e));
      return false;
    }
  }
);

export const transferAmount = createAsyncThunk(
  "sendReceive/transferAmount",
  async (payload, { dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `account_operation/swap/balance`;
      await API.put(config.p2p)(url, payload);
      dispatch(successToastSelector({ message: "Transfer successful" }));
      dispatch(getBalance());
      dispatch(stopLoading());
      return { status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e));
      return false;
    }
  }
);

// bank deposit

export const fetchAllBank = createAsyncThunk(
  "sendReceive/fetchAllBank",
  async (payload, { dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `account/bank_details/banks`;
      const res = await API.get(config.peatio)(url);
      dispatch(stopLoading());
      return {
        data: res,
        status: true,
      };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e));
      return false;
    }
  }
);

export const fetchBankDetail = createAsyncThunk(
  "sendReceive/fetchBankDetail",
  async (payload, { dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `account/bank_details/banks?${buildQueryString(payload)}`;
      const res = await API.get(config.peatio)(url);
      dispatch(stopLoading());
      return {
        data: res,
        status: true,
      };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e));
      return false;
    }
  }
);

export const fetchBankbeneficiary = createAsyncThunk(
  "sendReceive/fetchBankbeneficiary",
  async (payload, { dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `account/bank_details/beneficiary`;
      const res = await API.get(config.peatio)(url);
      dispatch(stopLoading());
      return {
        data: res,
        status: true,
      };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e));
      return false;
    }
  }
);

export const fetchWithdrawLimit = createAsyncThunk(
  "sendReceive/fetchWithdrawLimit",
  async (payload, { dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `account/withdraw_limit?${buildQueryString(payload)}`;
      const res = await API.get(config.peatio)(url);
      dispatch(stopLoading());
      return {
        data: res,
        status: true,
      };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e));
      return false;
    }
  }
);

export const addBankbeneficiary = createAsyncThunk(
  "sendReceive/addBankbeneficiary",
  async (payload, { dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `/account/bank_details/beneficiary`;
      const res = await API.post(config.peatio)(url, payload);
      dispatch(
        successToastSelector({
          message: "Bank add successfully",
        })
      );
      dispatch(fetchBankbeneficiary());
      dispatch(stopLoading());
      return {
        status: true,
      };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e));
      return false;
    }
  }
);
export const deleteBankDetails = createAsyncThunk(
  "sendReceive/deleteBankDetails",
  async (payload, { dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `account/bank_details/beneficiary/action?${buildQueryString(
        payload
      )}`;
      const res = await API.put(config.peatio)(url);
      dispatch(
        successToastSelector({
          message: "Deleted successfully",
        })
      );
      dispatch(stopLoading());
      return {
        data: res,
        status: true,
      };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e));
      return false;
    }
  }
);
export const qpayPost = createAsyncThunk(
  "sendReceive/qpayPost",
  async (payload, { dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `account/bank_details/qpay?${buildQueryString(payload)}`;
      const res = await API.post(config.peatio)(url);
      dispatch(stopLoading());
      return {
        data: res,
        status: true,
      };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e));
      return false;
    }
  }
);

export const socialpayPost = createAsyncThunk(
  "sendReceive/socialpayPost",
  async (payload, { dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `account/bank_details/socialpay?${buildQueryString(payload)}`;
      const res = await API.post(config.peatio)(url);
      dispatch(stopLoading());
      return {
        data: res,
        status: true,
      };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e));
      return false;
    }
  }
);

export const depositPost = createAsyncThunk(
  "sendReceive/depositPost",
  async (payload, { dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `account/fiat_deposit/new `;
      const res = await API.post(config.peatio)(url, payload);
      dispatch(successToastSelector({ message: "Deposit successful" }));
      dispatch(stopLoading());
      return {
        data: res,
        status: true,
      };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e));
      return false;
    }
  }
);

export const withdrawsPost = createAsyncThunk(
  "sendReceive/withdrawsPost",
  async (payload, { dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `account/fiat_withdraw/new `;
      const res = await API.post(config.peatio)(url, payload);
      dispatch(successToastSelector({ message: "Withdraw successful" }));
      dispatch(stopLoading());
      return {
        data: res,
        status: true,
      };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e));
      return false;
    }
  }
);

export const getOtpServicePeatio = createAsyncThunk(
  "login/getOtpService",
  async ({ otpClicked, ...rest }, { dispatch }) => {
    try {
      let url = `account/resent_otp`;
      await API.post(config.peatio)(url, rest);
      dispatch(
        successToastSelector({
          message: `OTP ${otpClicked ? "resent" : "sent"}  successfully`,
        })
      );
      return { status: true };
    } catch (e) {
      dispatch(errorToastSelector(e));
      return false;
    }
  }
);

