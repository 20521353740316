import React, { useEffect, useState } from "react";
import NewCard from "./NewCard";
import { Row, Col, Button, Breadcrumb, Space } from "antd";
import style from "./style.module.scss";
import Header from "../Landing/Header";
import Footer from "../Landing/Footer";
import { StoreImages } from "../../Storeimgaes/StoreImages";
import NewsDetail from "./NewsDetail";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getBlogPosts } from "../../../redux/services";
import { AheadIcon } from "../../Assets/Svg/Svg";
import { capitalize } from "../../../helpers";
import dummy from "../../Assets/Images/dummy.svg";
import { rootName } from "../../../utils/constant";
import CustomPagination from "../../customHooks/customPagination";
import { useTranslation } from "react-i18next";

const OurSubscription = () => {
  const initData = {
    page: 1,
    limit: 10,
  };

  window.top.scrollTo({
    top: 0,
    behavior: "smooth",
  });
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { list, total } = useSelector((state) => state.blogs);
  const [paginationState, setPaginationState] = useState({ ...initData });
  const [data, setData] = useState(false);
  const { page, limit } = paginationState;

  useEffect(() => {
    dispatch(
      getBlogPosts({
        page: page,
        limit: limit,
        state: "publish",
      })
    );
  }, [page, limit]);

  const { playstore, appleStore, groupMobile } = StoreImages;

  return (
    <>
      <Header />
      <section>
        <div className={style.banner}>
          <div className="container ">
            <Row gutter={[20, 20]} justify="center">
              <Col lg={12}>
                <div>
                  <h2>{t("landing.maintext")}</h2>
                  <p>{t("landing.maintextSub")}</p>
                  <p>Download the application:</p>
                  <Space size={[16, 16]} wrap>
                    <Link
                      target={"_blank"}
                      to="https://apps.apple.com/mn/app/coinhub-mn/id1585102002"
                    >
                      <Button className="playSore-Btn">
                        <img src={appleStore} alt="icon" />
                        <span>
                          Download on the
                          <br />
                          <strong>App Store</strong>
                        </span>
                      </Button>
                    </Link>
                    <Link
                      target={"_blank"}
                      to="https://play.google.com/store/apps/details?id=com.coinhub.pro&hl=en_US"
                    >
                      <Button className="playSore-Btn">
                        <img src={playstore} alt="icon" />
                        <span>
                          Get it on
                          <br />
                          <strong>Google Play</strong>
                        </span>
                      </Button>
                    </Link>
                    {/* <img width={60} src={QRcode} alt="icon" /> */}
                  </Space>
                </div>
              </Col>
              <Col lg={12}>
                <div className={style.banner_imagePart}>
                  <img src={groupMobile} alt="icon" />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </section>

      <section className={style.cardSecblock}>
        <div className="container ">
          <div className={`projectdetail `}>
            <Breadcrumb style={{ paddingBottom: "unset" }} separator=">">
              <Breadcrumb.Item href="">Home </Breadcrumb.Item>
              <Breadcrumb.Item href="">News and information</Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <Row justify="center" gutter={[20, 20]}>
            {list?.length > 0 &&
              list.map((text) => (
                <Col lg={6} sm={12} xs={24}>
                  <div key={text.id} style={{ maxWidth: "326px" }}>
                    <NewCard
                      img={text?.image ? text?.image : dummy}
                      title={capitalize(text?.title)}
                      Detail={text?.description.slice(0, 100) + "..."}
                      onClick={() =>
                        navigate(`${rootName}newsDetail/${text?.id}`)
                      }
                    />
                  </div>
                </Col>
              ))}
          </Row>
          <br />
          <CustomPagination
            pageNo={page}
            limit={limit}
            total={total}
            onChange={(pageNo, pageSize) => {
              setPaginationState({
                ...paginationState,
                page: pageNo,
                limit: pageSize,
              });
            }}
          />
        </div>
      </section>

      <Footer />
    </>
  );
};

export default OurSubscription;
