import React from "react";
import CHBCard from "./CHBCard/CHBCard.jsx";
import "./CHBToken.scss";
import rewarded from "../../Assets/Images/rewarded1.svg";
import commitment from "../../Assets/Images/rewarded2.svg";
import discount from "../../Assets/Images/rewarded3.svg";
import ServicesCard from "./ServicesCard/ServicesCard";
import Earn from "../../Assets/Images/Earn.svg";
import native from "../../Assets/Images/native.svg";
import responsibility from "../../Assets/Images/responsibility.svg";
import Starttrading from "../../Assets/Images/Starttrading .png";
import { Col, Row } from "antd";
import Header from "../Landing/Header";
import Footer from "../Landing/Footer";
import TokenImg from "../../Assets/Images/TokenImg.png";
import { ButtonCustom } from "../../Ui/button/ButtonCustom.jsx";
import arrowDobble from "../../Assets/Images/arrowDubble.svg";
import { StoreImages } from "../../Storeimgaes/StoreImages.jsx";
import { useNavigate } from "react-router-dom";
import {
  primaryRoutes,
  publicRouteObj,
  sidebarRouteObj,
} from "../../../staticObjects/routing.jsx";
import { rootName } from "../../../utils/constant.js";
import { isAuthenticated } from "../../../redux/selector/user.js";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

function CHBToken() {
  const { t } = useTranslation();
  window.top.scrollTo({
    top: 0,
    behavior: 'smooth' 
  });
  const navigate = useNavigate();
  const isAuthenticate = useSelector((state) => isAuthenticated(state));
  const {
    Battulga,
    Mandah,
    Batdelger,
    Amar,
    Sunduikhu,
    Ninjbadam,
    Erdem,
    Dulamsuren,
    Shirnenduger,
    Sarulzul,
  } = StoreImages;
  const tokenHbc = [
    {
      id: 1,
      Detail:t("chbToken.Token_Detail_Card_One") ,
      img: rewarded,
    },
    {
      id: 2,
      Detail:t("chbToken.Token_Detail_Card_Two") ,
      img: commitment,
    },
    {
      id: 3,
      Detail:t("chbToken.Token_Detail_Card_Three") ,
      img: discount,
    },
  ];

  const teamMember = [
    {
      id: 1,
      name: t("Team&community.O.Battulga"),
      img: Battulga,
      position: t("Team&community.O.Battulga_Title"),
    },
    {
      id: 2,
      name:t("Team&community.T.Mandakh"),
      img: Mandah,
      position:t("Team&community.T.Mandakh_Title"),
    },
    {
      id: 3,
      name:t("Team&community.B.Batdelger") ,
      img: Batdelger,
      position:t("Team&community.B.Batdelger_Title"),
    },
    {
      id: 4,
      name:t("Team&community.G.Amar"),
      img: Amar,
      position:t("Team&community.G.Amar_Title") ,
    },
    {
      id: 5,
      name:t("Team&community.B.Sunduikhuu"),
      img: Sunduikhu,
      position:t("Team&community.B.Sunduikhuu_Title") ,
    },
    {
      id: 6,
      name:t("Team&community.B.Saruulzul"),
      img: Sarulzul,
      position:t("Team&community.B.Saruulzul_Title") ,
    },
    {
      id: 7,
      name:t("Team&community.E.Ninjbadam") ,
      img: Ninjbadam,
      position:t("Team&community.E.Ninjbadam_Title"),
    },
    {
      id: 8,
      name:t("Team&community.Z.Erdem"),
      img: Erdem,
      position:t("Team&community.Z.Erdem_Title"),
    },
    {
      id: 9,
      name:t("Team&community.G.Dulamsuren") ,
      img: Dulamsuren,
      position:t("Team&community.G.Dulamsuren_Title"),
    },
    {
      id: 10,
      name:t("Team&community.G.Shirnenduger"),
      img: Shirnenduger,
      position:t("Team&community.G.Shirnenduger_Title"),
    },
  ];

  const services = [
    {
      id: 1,
      text:t("Products&services.Trade_details") ,
      img: rewarded,
      title: t("Products&services.Trade"),
    },
    {
      id: 2,
      text:t("Products&services.Earn_details"),
      img: Earn,
      title: t("Products&services.Earn"),
    },
    {
      id: 3,
      text:t("Products&services.CHB_token_details") ,
      img: native,
      title: t("Products&services.CHB_token"),
    },
    {
      id: 4,
      text: t("Products&services.Social_responsibility_details"),
      img: responsibility,
      title: t("Products&services.Social_responsibility"),
    },
  ];

  return (
    <div className="chb-token-page">
      <Header />
      <section>
        <div className="chbToken">
          <div className="container chbToken_bannerSec">
            <h1>{t("chbToken.WHO_ARE_WE")}?</h1>
            <p>
              {t("chbToken.Our_blockchain")}
              <br /> {t("chbToken.breackpoint_our_blockChain")}
            </p>
          </div>
        </div>
      </section>
      <section className="chbTokenMain">
        <div className="chbToken_tokenCard container ">
          <Row align="middle" justify="space-between" gutter={[10, 20]}>
            <Col lg={7} xs={24}>
              <div className="tokenImg">
                <img src={TokenImg} alt="" />
              </div>
            </Col>
            <Col lg={16} xs={24}>
              <div className="">
                <h2>{t("chbToken.CHB_Token")}</h2>
                <div className="chbToken_cards">
                  <Row gutter={[20, 20]}>
                    {tokenHbc.map((token) => (
                      <Col key={token.id} lg={8} sm={12} xs={24}>
                        <CHBCard image={token.img} text={token.Detail} />
                      </Col>
                    ))}
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>
      <section className="teamMember">
        <div className="teamCommunity container">
          <h2>{t("Team&community.Team_and_community")}</h2>
          <div>
            <Row gutter={[10, 20]} justify="center">
              {teamMember.map((detail) => (
                <Col key={detail.id}>
                  <div className="teamCommunity_imgSec">
                    <img src={detail.img} alt="Battulga" />
                    <div className="battulga">
                      <h5>{detail.name}</h5>
                      <p>{detail.position}</p>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        </div>
      </section>
      <section className="Products_sec">
        <div className="container">
          <h2>{t("Products&services.Products_services")}</h2>
          <div className="productServices">
            <Row gutter={[20, 20]}>
              {services.map((detail) => (
                <Col lg={6} sm={12} xs={24}>
                  <ServicesCard
                    image={detail.img}
                    title={detail.title}
                    text={detail.text}
                  />
                </Col>
              ))}
            </Row>
          </div>
        </div>
      </section>
      <section className="trading">
        <div className="startTrading">
          <div className="container">
            <div className="tradingContant ">
              <Row align="middle" justify="space-between" gutter={[20, 20]}>
                <Col lg={12} xs={24}>
                  <div className="tradingContent_left">
                    <div className="registerContent">
                      <p>We share</p>
                      <h2>Start trading now</h2>
                      {!isAuthenticate ? (
                        <ButtonCustom
                          onClick={() => {
                            navigate(
                              `${rootName}${publicRouteObj.createAccount}`
                            );
                          }}
                          label="REGISTER"
                          onlyIcon={
                            <span
                              style={{
                                backgroundColor: "white",
                                borderRadius: "50%",
                                width: "30px",
                                height: "30px",
                                display: "inline-block",
                                marginLeft: "10px",
                              }}
                            >
                              <img src={arrowDobble} alt="icon" />
                            </span>
                          }
                        />
                      ) : (
                        <ButtonCustom
                          onClick={() => {
                            navigate(`${rootName}advanceTrading`);
                          }}
                          label="Trade now"
                          onlyIcon={
                            <span
                              style={{
                                backgroundColor: "white",
                                borderRadius: "50%",
                                width: "30px",
                                height: "30px",
                                display: "inline-block",
                                marginLeft: "10px",
                              }}
                            >
                              <img src={arrowDobble} alt="icon" />
                            </span>
                          }
                        />
                      )}
                    </div>
                  </div>
                </Col>
                <Col lg={12}>
                  <div className="tradingContant_right">
                    <img src={Starttrading} alt="Starttrading" />
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default CHBToken;
