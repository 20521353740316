import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Tabs, Button, Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { StoreImages } from "../../Storeimgaes/StoreImages";
import BuySellConvertTab from "../CustomTabs/index.jsx";
import Points from "./Points";
import {
  getCurrencies,
  getCurrencyDetails,
  getGraphData,
  getMarketTickers,
  getSingleMarketTickers,
} from "../../../redux/services";
import PrimaryBalanceTab from "./PrimaryBalanceTab";
import { formatNumber } from "../../../helpers/formatNumber";
import style from "./style.module.scss";
import {
  coinDetailTabs,
  graphDurationTimeObj,
  upDownPriceColor,
} from "../../../staticObjects";
import TradeLineGraph from "../../Common/TradeLineGraph";
import { capitalize, lowercase, preciseData } from "../../../helpers";
import {
  convertToThousandDigit,
  thousandDigit,
} from "../../../helpers/thousand_digit";

const CoinDetail = () => {
  const { TabPane } = Tabs;
  const { t } = useTranslation();
  const { doc, web } = StoreImages;
  const dispatch = useDispatch();
  const { id } = useParams();
  const currDetail = useSelector(
    (state) => state.commonApiData?.currencyDetails
  );
  const { currencyGraphData, currenciesList, tradingType } = useSelector(
    (state) => state.commonApiData
  );
  const { singleMarketTickers, marketTickers } = useSelector(
    (state) => state?.exchange
  );
  const { overview, primaryBalance } = coinDetailTabs;
  const [activeTab, setActiveTab] = useState(overview);
  const [time, setTime] = useState(graphDurationTimeObj[0].value);
  const isUsdt = id === "usdt";
  let tickerData = singleMarketTickers?.ticker;
  // let marketFilter =
  useEffect(() => {
    if (id) {
      dispatch(getMarketTickers({ tradeType: tradingType }));
      dispatch(getCurrencies());
      // dispatch(getCurrencyDetails({ id }));
      dispatch(getSingleMarketTickers({ currency_id: id }));
      graphApi(time);
    }
  }, []);

  const graphApi = (time) => {
    setTime(time);
    dispatch(getGraphData({ id: id, values: time }));
  };

  const getMarketDataList =
    currenciesList?.length > 0
      ? currenciesList?.filter((item) => item.market_data)
      : [];

  const getMarketDataList2 =
    getMarketDataList?.length > 0
      ? getMarketDataList.filter((item) => item.id === id)
      : [];

  const newDataArray2 =
    getMarketDataList.length > 0 &&
    getMarketDataList.map((item) => {
      return {
        id: item?.id,
        name: item?.name,
        price: convertToThousandDigit(item?.price),
        icon: item?.icon_url,
        color: item?.color,
        market_data: item?.market_data,
        market_quote_unit: item?.market_quote_unit,
      };
    });

  const newDataArray =
    getMarketDataList2.length > 0 &&
    marketTickers[getMarketDataList2[0]?.market_data]
      ? marketTickers[getMarketDataList2[0]?.market_data]
      : marketTickers[getMarketDataList2[0]?.ticker?.market_data];

  const getQuoteCurrPrice = (quoteUnit, currencies, price) => {
    let res =
      currencies.length > 0 &&
      currencies
        .filter((data) => data.id === quoteUnit)
        .map((items) => {
          return {
            price: items?.price,
          };
        });
    return res ? Number(price * res[0]?.price) : 0;
  };

  const marketDataKey = getMarketDataList2[0]?.market_data;
  const marketCap = marketTickers[marketDataKey]?.total_volume
    ? marketTickers[marketDataKey]?.total_volume
    : marketTickers[marketDataKey]?.ticker?.total_volume;
  const twenty24Hour = marketTickers[marketDataKey]?.price_change_percent
    ? marketTickers[marketDataKey]?.price_change_percent
    : marketTickers[marketDataKey]?.ticker?.price_change_percent;
  const twenty24HourHigh = marketTickers[marketDataKey]?.high
    ? marketTickers[marketDataKey]?.high
    : marketTickers[marketDataKey]?.ticker?.high;
  const price = marketTickers[marketDataKey]?.last
    ? marketTickers[marketDataKey]?.last
    : marketTickers[marketDataKey]?.ticker?.last;
  const quoteCurrPrice = getQuoteCurrPrice(
    getMarketDataList2[0]?.market_quote_unit,
    currenciesList,
    price
  );

  // if (currDetail?.length === 0 || Object.keys(currDetail).length === 0) return;
  // const getkey = (time) => {
  //   let res = graphDurationTimeObj.find((item, idx) => item.value === time).key;
  //   return res ?? "";
  // };
  // const percentVal = currDetail[getkey(time)];

  const { up, down } = upDownPriceColor;
  let crncy =
    currenciesList.length > 0 && currenciesList.find((data) => data.id === id);
  let market_qute =
    currenciesList.length > 0 &&
    currenciesList.find((data) => data?.id === crncy?.market_quote_unit);

  let currPrice =
    crncy.market_quote_unit === "usdt"
      ? tickerData?.last
      : tickerData?.last * market_qute?.price;

  const {
    icon_url,
    name,
    symbol,
    description,
    whitepaper,
    official_link,
    color,
  } = crncy;

  return (
    <>
      <div className={style.cointdetailPage}>
        <div className={style.cointdetailPage_headBar}>
          <h3 className={`text-black-36 ${style.heading} `}>
            <img className="coin_icon" src={icon_url} alt="icon" />
            {capitalize(lowercase(name))}
            <span>{symbol}</span>
          </h3>
          {/*<Button>{t("coinDetail.watchlist")}</Button>*/}
        </div>
        <div className={style.cointdetailPage_tabs}>
          <Row gutter={40}>
            <Col xs={24} lg={24} xl={24}>
              <Tabs
                className="line-none"
                defaultActiveKey={activeTab}
                onChange={(key) => setActiveTab(key)}
              >
                <TabPane
                  tab={t("coinDetail.overview")}
                  key={overview}
                  className={style.paddingLeft}
                >
                  {activeTab === overview && (
                    <div
                      className={`${style.cointdetailPage_tabs_overView} ${style.mt}`}
                    >
                      <Row>
                        <Col xl={24} md={24} xs={24}>
                          <div
                            className={`${style.cointdetailPage_tabs_overView_graphsec} card`}
                          >
                            <section>
                              <Row
                                className={
                                  style.cointdetailPage_tabs_overView_graphsec_duration
                                }
                              >
                                <Col>
                                  <h3>
                                    $
                                    {convertToThousandDigit(
                                      preciseData(quoteCurrPrice, 8)
                                    )}
                                    <sup
                                      style={{
                                        color:
                                          twenty24Hour &&
                                          twenty24Hour[0] === "+"
                                            ? up
                                            : down,
                                        top: "-1em",
                                      }}
                                    >
                                      {`${twenty24Hour ?? 0} `}
                                    </sup>
                                  </h3>
                                </Col>
                                <Col>
                                  {graphDurationTimeObj?.map((item, idx) => {
                                    return (
                                      <button
                                        key={item.key}
                                        className={`timeBtn ${
                                          time === item.value
                                            ? "timeActive"
                                            : ""
                                        }`}
                                        onClick={() => graphApi(item?.value)}
                                      >
                                        {item.label}
                                      </button>
                                    );
                                  })}
                                </Col>
                              </Row>
                            </section>
                            <section className={style.graph__section}>
                              <TradeLineGraph
                                data={currencyGraphData}
                                color={color}
                              />
                            </section>
                            <section
                              className={
                                style.cointdetailPage_tabs_overView_graphsec_points
                              }
                            >
                              <h4>{t("coinDetail.market_Stats")}</h4>
                              <Row gutter={20}>
                                <Col xl={6} md={8} xs={24}>
                                  <Points
                                    Heading={t("coinDetail.24hrhigh")}
                                    Content={`$${formatNumber(
                                      twenty24HourHigh || 0
                                    )} `}
                                    tooltip={t("overivew.Market_cap")}
                                  />
                                </Col>
                                <Col xl={6} md={8} xs={24}>
                                  <Points
                                    Heading={t("coinDetail.24Vol")}
                                    Content={`$${formatNumber(marketCap || 0)}`}
                                    detail={` ${twenty24Hour ?? 0} `}
                                    color={
                                      twenty24Hour && twenty24Hour[0] === "+"
                                        ? "green"
                                        : "orange"
                                    }
                                    tooltip={t("overivew.Volume_(24h)")}
                                  />
                                </Col>
                                {/* <Col xl={6} md={8} xs={24}>
                                  <Points
                                    Heading="Circulating supply"
                                    Content={`${formatNumber(
                                      circulating_supply
                                    )} ${name}`}
                                    detail={
                                      max_supply
                                        ? `${(
                                            (Number(circulating_supply) /
                                              Number(max_supply)) *
                                            100
                                          ).toFixed()}% of total supply`
                                        : ""
                                    }
                                    color="grey"
                                    tooltip={t("overivew.Circulating_supply")}
                                  />
                                </Col> */}
                                <Col xl={6} md={8} xs={24}>
                                  <Points
                                    Heading={t("coinDetail.popularity")}
                                    Content={`# ${crncy.position}`}
                                    tooltip={t("overivew.Popularity")}
                                  />
                                </Col>
                              </Row>
                            </section>
                            <section
                              className={
                                style.cointdetailPage_tabs_overView_graphsec_textline
                              }
                            >
                              <h4>{t("coinDetail.overview")}</h4>
                              <p>{description}</p>

                              <h5>{t("coinDetail.resources")}</h5>
                              {whitepaper?.url && (
                                <a target="_blank" href={whitepaper?.url}>
                                  <img src={doc} alt="icon" />{" "}
                                  <span>{t("coinDetail.whitepaper")}</span>
                                </a>
                              )}
                              {official_link && (
                                <a target="_blank" href={official_link}>
                                  <img src={web} alt="icon" />
                                  <span>
                                    {t("coinDetail.official_Website")}
                                  </span>
                                </a>
                              )}
                            </section>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  )}
                </TabPane>
                <TabPane
                  tab={t("coinDetail.primary_alance")}
                  key={primaryBalance}
                  className={style.paddingLeft}
                >
                  {activeTab === primaryBalance && <PrimaryBalanceTab />}
                </TabPane>
              </Tabs>
            </Col>
            {/* <Col xs={24} lg={24} xl={6}>
              <div className={`${style.cointdetailPage_tabs_cardtab} card`}>
                <div className="swapPage">
                  <BuySellConvertTab fromWhere="overview" />
                </div>
              </div>
            </Col> */}
          </Row>
        </div>
      </div>
    </>
  );
};

export default CoinDetail;
