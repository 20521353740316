import { createSlice } from "@reduxjs/toolkit";
import {
  getredeemReward,
  getreferralBlsLeft,
  getreferralBlsTotal,
  getreferralData,
  getreferredMembers,
  getrewardData,
  getrrewardSettings,
} from "../../services";

export const referralSlices = createSlice({
  name: "referralSlices",
  initialState: {
    error: false,
    list: [],
    referralList: {},
    totalBalance: [],
    leftBalance: 0,
    referredMembers: [],
    redeemReward: [],
    rewardSettings: [],
    total: 0,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      //getrewardData
      .addCase(getrewardData.pending, (state) => {})
      .addCase(getrewardData.fulfilled, (state, action) => {
        state.list = action.payload.list;
        state.total = action.payload.total;
      })
      .addCase(getrewardData.rejected, (state) => {})

      //getreferralData
      .addCase(getreferralData.pending, (state) => {})
      .addCase(getreferralData.fulfilled, (state, action) => {
        state.referralList = action.payload.list;
        state.total = action.payload.total ?? 0;
      })
      .addCase(getreferralData.rejected, (state) => {})

      //getreferralBlsTotal
      .addCase(getreferralBlsTotal.pending, (state) => {})
      .addCase(getreferralBlsTotal.fulfilled, (state, action) => {
        state.totalBalance = action.payload.list;
      })
      .addCase(getreferralBlsTotal.rejected, (state) => {})

      //getreferralBlsLeft
      .addCase(getreferralBlsLeft.pending, (state) => {})
      .addCase(getreferralBlsLeft.fulfilled, (state, action) => {
        state.leftBalance = action.payload.list;
      })
      .addCase(getreferralBlsLeft.rejected, (state) => {})

      //referred_members
      .addCase(getreferredMembers.pending, (state) => {})
      .addCase(getreferredMembers.fulfilled, (state, action) => {
        state.referredMembers = action.payload.list;
      })
      .addCase(getreferredMembers.rejected, (state) => {})

      //redeem_reward
      .addCase(getredeemReward.pending, (state) => {})
      .addCase(getredeemReward.fulfilled, (state, action) => {
        state.redeemReward = action.payload.list;
      })
      .addCase(getredeemReward.rejected, (state) => {})

      //reward_settings
      .addCase(getrrewardSettings.pending, (state) => {})
      .addCase(getrrewardSettings.fulfilled, (state, action) => {
        state.rewardSettings = action.payload.list ?? [];
      })
      .addCase(getrrewardSettings.rejected, (state) => {});
  },
});
export default referralSlices.reducer;
export const {} = referralSlices.actions;
