import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../api";
import { buildQueryString } from "../../helpers";
import * as config from "../../utils/apiVersion";
import { startLoading, stopLoading } from "../feature";
import { errorToastSelector, successToastSelector } from "../selector";

export const getDeviceData = createAsyncThunk(
    "security/getDeviceData",
    async (payload, { dispatch }) => {
        try {
            dispatch(startLoading());
            let url = `resource/users/store_sessions?${buildQueryString(payload)}`;
            const { data, headers } = await API.get(config.barongWithHeader)(url);
            dispatch(stopLoading());
            return { list: data, status: true, total: headers?.total };
        } catch (e) {
            dispatch(stopLoading());
            dispatch(errorToastSelector(e));
            return false;
        }
    }
);

export const deleteDeviceData = createAsyncThunk(
    "setting/deleteDeviceData",
    async (payload, { dispatch }) => {
        try {
            dispatch(startLoading());
            let url = `identity/sessions/action?${buildQueryString(payload)}`;
            const res = await API.put(config.barong)(url);
            dispatch(stopLoading());
            dispatch(
                successToastSelector({
                    message: `Session deleted successfully`,
                })
            );
            dispatch(getDeviceData({
                limit: 10,
                page: 1
            }));
            return { status: true };
        } catch (e) {
            dispatch(stopLoading());
            dispatch(errorToastSelector(e));
            return false;
        }
    }
);

export const getLoginActivity = createAsyncThunk(
    "security/getLoginActivity",
    async (payload, { dispatch }) => {
        try {
            dispatch(startLoading());
            let url = `resource/users/activity/session/?${buildQueryString(payload)}`;
            const { data, headers } = await API.get(config.barongWithHeader)(url);
            dispatch(stopLoading());
            return { list: data, status: true, total: 98 };
        } catch (e) {
            dispatch(stopLoading());
            dispatch(errorToastSelector(e));
            return false;
        }
    }
);

export const getSecurityActivity = createAsyncThunk(
    "security/getSecurityActivity",
    async (payload, { dispatch }) => {
        try {
            dispatch(startLoading());
            let url = `resource/users/activity/session_delete/?${buildQueryString(payload)}`;
            const { data, headers } = await API.get(config.barongWithHeader)(url);
            dispatch(stopLoading());
            return { list: data, status: true, total: headers?.total };
        } catch (e) {
            dispatch(stopLoading());
            dispatch(errorToastSelector(e));
            return false;
        }
    }
);