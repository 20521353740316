import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../api";
import * as config from "../../utils/apiVersion";
import { startLoading, stopLoading } from "../feature";
import { errorToastSelector, successToastSelector } from "../selector";
import { buildQueryString } from "../../helpers";
import {
  setCurrentMarket,
  setSuccess,
} from "../feature/exchange/exchanges.slice";

export const getMarkets = createAsyncThunk(
  "exchangeService/getMarkets",
  async (payload = {}, { rejectWithValue, dispatch }) => {
    const conf = config.getConfig(payload.tradeType);
    try {
      dispatch(startLoading(true));
      let url = "public/markets";
      let res = await API.get(conf)(url);
      dispatch(stopLoading(false));
      return { list: res, status: true };
    } catch (e) {
      dispatch(stopLoading(false));
      dispatch(errorToastSelector(e));
      dispatch(setCurrentMarket({}));
      return rejectWithValue(e);
    }
  }
);

export const getOrderBook = createAsyncThunk(
  "exchangeService/getOrderBook",
  async (payload = {}, { rejectWithValue, dispatch }) => {
    const conf = config.getConfig(payload.tradeType);
    delete payload.tradeType;
    try {
      dispatch(startLoading(true));
      if (payload?.id) {
        let url = `/public/markets/${payload?.id}/depth`;
        let res = await API.get(conf)(url);
        dispatch(stopLoading(false));
        return { list: res, status: true };
      }
    } catch (e) {
      dispatch(stopLoading(false));
      dispatch(errorToastSelector(e));
      return rejectWithValue(e);
    }
  }
);

export const getTrades = createAsyncThunk(
  "exchangeService/getTrades",
  async (payload = {}, { rejectWithValue, dispatch }) => {
    const conf = config.getConfig(payload.tradeType, true);
    delete payload.tradeType;
    try {
      let url;
      dispatch(startLoading(true));
      if (payload?.id) {
        url = `public/markets/${payload?.id}/trades`;
      } else {
        url = `/market/trades?${buildQueryString(payload)}`;
      }
      let res = await API.get(conf)(url);
      dispatch(stopLoading(false));
      return {
        list: res?.data,
        total: res?.headers?.total > 0 ? res?.headers?.total : 0,
        status: true,
      };
    } catch (e) {
      dispatch(stopLoading(false));
      dispatch(errorToastSelector(e));
      return rejectWithValue(e);
    }
  }
);
// export const getAdjustment = createAsyncThunk(
//   "/account/getAdjustment",
//   async (payload = {}, { rejectWithValue, dispatch }) => {
    
//     const conf = config.getConfig(payload.tradeType, true);
//     delete payload.tradeType;
//     try {
//       let url;
//       dispatch(startLoading(true));
//       if (payload?.id) {
//         url = `/peatio/account/adjustments`;
//       }
//       let res = await API.get(conf)(url);
//       dispatch(stopLoading(false));
//       return {
//         list: res?.data,
//         total: res?.headers?.total > 0 ? res?.headers?.total : 0,
//         status: true,
//       };
//     } catch (e) {
//       dispatch(stopLoading(false));
//       dispatch(errorToastSelector(e));
//       return rejectWithValue(e);
//     }
//   }
// );

export const getMarketTickers = createAsyncThunk(
  "exchangeService/getMarketTickers",
  async (payload = {}, { rejectWithValue, dispatch }) => { 
    const conf = config.getConfig(payload.tradeType);
    try {
      dispatch(startLoading(true));
      let url = "public/markets/tickers";
      let res = await API.get(conf)(url);
      dispatch(stopLoading(false));
      return { list: res, status: true };
    } catch (e) {
      dispatch(stopLoading(false));
      dispatch(errorToastSelector(e));
      return rejectWithValue(e);
    }
  }
);
export const getFavMarketList = createAsyncThunk(
  "exchangeService/getFavMarketList",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      let url = `/account/fav_market/list`;
      let { data } = await API.get(config.peatioWithHeader)(url);
      dispatch(stopLoading(false));
      return {
        list: data,
        status: true,
      };
    } catch (e) {
      dispatch(stopLoading(false));
      dispatch(errorToastSelector(e));
      return rejectWithValue(e);
    }
  }
);
export const setFavMarket = createAsyncThunk(
  "exchangeService/submitOrders",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading(true));
      let url = `/account/fav_market`;
      let res = await API.patch(config.peatio)(url, payload);
      dispatch(stopLoading(false));
      if (res) {
        dispatch(getFavMarketList());
      }
      return { status: true };
    } catch (e) {
      dispatch(stopLoading(false));
      dispatch(errorToastSelector(e));
      return rejectWithValue(e);
    }
  }
);
export const getSingleMarketTickers = createAsyncThunk(
  "exchangeService/getSingleMarketTickers",
  async (payload = {}, { rejectWithValue, dispatch }) => {
    const conf = config.getConfig(payload.tradeType);
    try {
      dispatch(startLoading(true));
      let url = `public/currency/tickers?${buildQueryString(payload)}`;
      let res = await API.get(conf)(url);
      dispatch(stopLoading(false));
      return { list: res, status: true };
    } catch (e) {
      dispatch(stopLoading(false));
      dispatch(errorToastSelector(e));
      return rejectWithValue(e);
    }
  }
);

export const submitOrders = createAsyncThunk(
  "exchangeService/submitOrders",
  async (payload = {}, { rejectWithValue, dispatch }) => {
    const conf = config.getConfig(payload.tradeType, true);
    delete payload.tradeType;
    try {
      dispatch(startLoading(true));
      let url = `market/orders`;
      let res = await API.post(conf)(url, payload);
      dispatch(stopLoading(false));
      if (res) {
        dispatch(
          successToastSelector({ message: "Order created successfully " })
        );
        dispatch(setSuccess(true));
      }
      return { list: res, status: true };
    } catch (e) {
      dispatch(stopLoading(false));
      dispatch(errorToastSelector(e));
      return rejectWithValue(e);
    }
  }
);

export const cancelOrder = createAsyncThunk(
  "exchangeService/CancelOrder",
  async (payload, { rejectWithValue, dispatch }) => {
    const conf = config.getConfig(payload.tradeType);
    delete payload.tradeType;
    try {
      dispatch(startLoading(true));
      let url = `/market/orders/${payload.id}/cancel?market=${payload.market}`;
      let res = await API.post(conf)(url);
      dispatch(stopLoading(false));
      if (res) {
        dispatch(
          successToastSelector({ message: "Order cancelled successfully" })
        );
        dispatch(setSuccess(true));
      }
      return { list: res, status: true };
    } catch (e) {
      dispatch(stopLoading(false));
      dispatch(errorToastSelector(e));
      return rejectWithValue(e);
    }
  }
);

export const cancelAllOrder = createAsyncThunk(
  "exchangeService/cancelAllOrder",
  async (payload, { rejectWithValue, dispatch }) => {
    const conf = config.getConfig(payload.tradeType);
    delete payload.tradeType;
    try {
      dispatch(startLoading(true));
      let url = `/market/orders/cancel?market=${payload.market}`;
      let res = await API.post(conf)(url);
      dispatch(stopLoading(false));
      if (res) {
        dispatch(
          successToastSelector({ message: "All order cancelled successfully" })
        );
        dispatch(setSuccess(true));
      }
      return { status: true };
    } catch (e) {
      dispatch(stopLoading(false));
      dispatch(errorToastSelector(e));
      return rejectWithValue(e);
    }
  }
);

export const getOrderHistory = createAsyncThunk(
  "exchangeService/getOrderHistory",
  async (payload = {}, { rejectWithValue, dispatch }) => {
    const conf = config.getConfig(payload.tradeType, true);
    const tempCallFrom = payload?.callFrom;
    delete payload?.callFrom;
    delete payload.tradeType;
    try {
      dispatch(startLoading(true));
      let url = `market/orders?${buildQueryString(payload)}`;
      let res = await API.get(conf)(url);
      dispatch(stopLoading(false));
      return {
        list: res?.data,
        total: res?.headers?.total,
        state: payload?.state ? payload?.state[0] : "",
        callFrom: tempCallFrom,
        status: true,
      };
    } catch (e) {
      dispatch(stopLoading(false));
      dispatch(errorToastSelector(e));
      return rejectWithValue(e);
    }
  }
);

export const getTradingRules = createAsyncThunk(
  "exchangeService/getTradingRules",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      if (payload?.market_id && payload?.uuid) {
        let url = `public/trading_fees?${buildQueryString(payload)}`;
        let res = await API.get(config.peatioWithHeader)(url);
        dispatch(stopLoading(false));
        return {
          list: res?.data[0] !== undefined ? res?.data[0] : res?.data[1],
          status: true,
        };
      }
    } catch (e) {
      dispatch(stopLoading(false));
      dispatch(errorToastSelector(e));
      return rejectWithValue(e);
    }
  }
);

export const getBalanceUsdt = createAsyncThunk(
  "exchangeService/getBalanceUsdt",
  async (payload = {}, { rejectWithValue, dispatch }) => {
    const conf = config.getConfig(payload.tradeType);
    try {
      dispatch(startLoading());
      let url = `account/usdt/balance`;
      let res = await API.get(conf)(url);
      dispatch(stopLoading());
      return { res, status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const getFutureSetting = createAsyncThunk(
  "exchangeService/getFutureSetting",
  async (payload = {}, { rejectWithValue, dispatch }) => {
    const conf = config.getConfig(payload.tradeType);
    try {
      dispatch(startLoading());
      let url = `account/f_setting/${payload.market_id}?symbol=${payload.market_id}`;
      let res = await API.get(conf)(url);
      dispatch(stopLoading());
      return { res, status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);

export const updateFutureSetting = createAsyncThunk(
  "exchangeService/updateFutureSetting",
  async (payload = {}, { rejectWithValue, dispatch }) => {
    const conf = config.getConfig(payload.tradeType);
    delete payload.tradeType;
    try {
      dispatch(startLoading());
      let url = `account/f_setting/${payload.type}`;
      let res = await API.post(conf)(url, payload);
      dispatch(stopLoading());
      return { status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return { status: false };
    }
  }
);

export const getOrderHistoryInnerData = createAsyncThunk(
  "exchangeService/getOrderHistoryInnerData",
  async (payload = {}, { rejectWithValue, dispatch }) => {
    const conf = config.getConfig(payload.tradeType);
    try {
      let url = `market/${payload.market_id}/order/${payload.order_id}`;
      let res = await API.get(conf)(url);
      return { res: res ?? {}, status: true };
    } catch (e) {
      dispatch(errorToastSelector(e, "error"));
      return rejectWithValue(e);
    }
  }
);
