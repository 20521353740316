import React, { useEffect, useState } from "react";
import { Drawer, Dropdown } from "antd";
import { Link as RouterLink } from "react-router-dom";
import style from "./style.module.scss";
import { rootName } from "../../../utils/constant";
import {
  primaryRoutes,
  publicRouteObj,
  sidebarRouteObj,
} from "../../../staticObjects";
import LogoWhite from "../../Assets/Images/CoinhubWhite.png";
import Language from "../../Assets/Images/languageIcon.svg";
import { useNavigate } from "react-router-dom";
import CommonButton from "../../Ui/button/CommonButton";
import { StoreImages } from "../../Storeimgaes/StoreImages";
import { isAuthenticated } from "../../../redux/selector";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
const Header = () => {
  const [language, setLanguage] = useState(null);
  const { t, i18n } = useTranslation();

  const handleLanguageClick = (item) => {
    setLanguage(item);
    i18n.changeLanguage(item);
    localStorage.setItem("language", item);
  };

  useEffect(() => {
    const lang = localStorage.getItem("language");
    document.body.classList.add("en");

    if (lang === "mn") {
      document.body.classList.add("mn");
      document.body.classList.remove("en");
    }
    if (lang === "en") {
      document.body.classList.add("en");
      document.body.classList.remove("mn");
    }
  }, [language]);

  const languageList = [
    { code: "en", label: "English" },
    { code: "mn", label: "монгол" },
  ];
  const { ToggleIcon } = StoreImages;
  const navigate = useNavigate();
  const isLoged = useSelector((state) => isAuthenticated(state));
  const [visible, setVisible] = useState(false);

  const [color, setColor] = useState(false);
  const colorChange = () => {
    if (window.scrollY >= 50) {
      setColor(true);
    } else setColor(false);
  };
  window.addEventListener("scroll", colorChange);
  let loginLink = `${rootName}${publicRouteObj.login}`;
  let signupLink = `${rootName}${publicRouteObj.createAccount}`;

  const handleDrawerStateChange = (visible) => {
    const body = document.body;

    if (visible) {
      // document.documentElement.classList.add('drawer-open')
      body.classList.add("drawer-open");
    } else {
      body.classList.remove("drawer-open");
      // document.documentElement.classList.remove('drawer-open')
    }
  };

  useEffect(() => {
    return () => {
      document.body.classList.remove("drawer-open");
    };
  }, []);
  return (
    <>
      <header className={` ${color ? "bg-white" : ""} siteHeader`}>
        <div className="container">
          <div className={style.header}>
            <a
              onClick={(e) => {
                e.preventDefault();
                navigate(
                  `${
                    isLoged
                      ? `${rootName}${primaryRoutes.authLanding}`
                      : rootName
                  }`
                );
              }}
            >
              <h2 className="">
                <img src={LogoWhite} style={{ width: "130px" }} alt="" />
              </h2>
            </a>
            <div className={style.header_rightSideFlex}>
              <div className={`${style.header_links} ${style.navbarfull}`}>
                {/* <RouterLink to={`${rootName}${publicRouteObj.listing}`}>
                  Listing
                </RouterLink> */}
                {isLoged && (
                  <RouterLink to={`${rootName}${sidebarRouteObj.home}`}>
                    {t("landingHeadings.home")}
                  </RouterLink>
                )}
                <RouterLink to={`${rootName}${publicRouteObj.whyCoinhub}`}>
                  {t("landingHeadings.why_coinhub")}
                </RouterLink>
                <RouterLink to={`${rootName}${publicRouteObj.ourToken}`}>
                  {t("landingHeadings.CHB_token")}
                </RouterLink>
                <RouterLink to="/ourSubscription">
                  {t("landingHeadings.market_News")}
                </RouterLink>
                <RouterLink to={`${rootName}${publicRouteObj.marketupdates}`}>
                  {t("landingHeadings.market_updates")}
                </RouterLink>
                {!isLoged && (
                  <RouterLink to={isLoged ? `/advanceTrading` : "/trading"}>
                    {t("footer.Products.Trade")}
                  </RouterLink>
                )}
                {/* <Link to={isAuthenticate ? `/advanceTrading` : '/trading'}>{t("footer.Products.Trade")}</Link> */}
                {/* <RouterLink to="">Demo Trading</RouterLink> */}
                {isLoged ? (
                  <>
                    <RouterLink to={`${rootName}${sidebarRouteObj.advTrade}`}>
                      {t("landingHeadings.exchange")}
                    </RouterLink>
                  </>
                ) : (
                  <>
                    <CommonButton
                      title={t("landingHeadings.login")}
                      onClick={() => {
                        navigate(loginLink);
                      }}
                    />
                    <button
                      onClick={() => {
                        navigate(`${rootName}${publicRouteObj.createAccount}`);
                      }}
                    >
                      {t("landingHeadings.register")}
                    </button>
                  </>
                )}
              </div>
              <Dropdown
                overlayClassName="languageDrop"
                overlay={
                  <div className="languageMenu ">
                    <ul
                      style={{
                        backgroundColor: "white",
                        listStyle: "none",
                        textAlign: "center",
                        overflow: "hidden",
                      }}
                    >
                      {languageList.map((languageOption) => (
                        <li
                          style={{ textAlign: "left" }}
                          key={languageOption.code}
                          onClick={() =>
                            handleLanguageClick(languageOption.code)
                          }
                          // className="selectedLanguage"
                          className={
                            language === languageOption.code
                              ? "selectedLanguage"
                              : ""
                          }
                        >
                          {languageOption.label}
                        </li>
                      ))}
                    </ul>
                  </div>
                }
                // open={true}
                trigger={["hover"]}
                className="langaugeDrop"
                getPopupContainer={(trigger) => trigger.parentNode}
              >
                <div className={style.languageIcon}>
                  <img src={Language} alt="Language" />
                </div>
              </Dropdown>

              <div className={style.responsiveSidebar}>
                <div>
                  {/* <MenuFoldOutlined /> */}
                  <img
                    src={ToggleIcon}
                    alt="toggleIcon"
                    onClick={() => {
                      setVisible(true);
                    }}
                  />

                  <Drawer
                    className="drawerLanding"
                    title={
                      <a
                        onClick={(e) => {
                          e.preventDefault();
                          navigate(
                            `${
                              isLoged
                                ? `${rootName}${primaryRoutes.authLanding}`
                                : rootName
                            }`
                          );
                        }}
                      >
                        <h2 className="logo-white">CoinHub</h2>
                      </a>
                    }
                    placement="top"
                    // closable={false}
                    visible={visible}
                    onClose={() => {
                      setVisible(false);
                    }}
                    afterVisibleChange={handleDrawerStateChange}
                  >
                    <div className={style.sidebarHeader}>
                      {/* <RouterLink to={`${rootName}${publicRouteObj.listing}`}>
                        Listing
                      </RouterLink> */}
                      <RouterLink
                        to={`${rootName}${publicRouteObj.whyCoinhub}`}
                      >
                        Why COINHUB
                      </RouterLink>
                      <RouterLink to={`${rootName}${publicRouteObj.ourToken}`}>
                        {t("landingHeadings.CHB_token")}
                      </RouterLink>
                      <RouterLink to="/ourSubscription">
                        {t("landingHeadings.market_News")}
                      </RouterLink>
                      <RouterLink
                        to={`${rootName}${publicRouteObj.marketupdates}`}
                      >
                        Social Responsibility
                      </RouterLink>
                      <RouterLink to={`${rootName}${sidebarRouteObj.advTrade}`}>
                        Exchange
                      </RouterLink>
                      {/*<RouterLink to="">Demo Trading</RouterLink>*/}
                      {isLoged ? (
                        <>
                          <RouterLink to={`${rootName}${sidebarRouteObj.home}`}>
                            Home
                          </RouterLink>
                          <RouterLink
                            to={`${rootName}${sidebarRouteObj.advTrade}`}
                          >
                            Exchange
                          </RouterLink>
                        </>
                      ) : (
                        <>
                          <CommonButton
                            title="Log In"
                            onClick={() => {
                              navigate(loginLink);
                            }}
                          />
                          <RouterLink
                            to={signupLink}
                            onClick={() => {
                              navigate(
                                `${rootName}${publicRouteObj.createAccount}`
                              );
                            }}
                          >
                            Register
                          </RouterLink>
                        </>
                      )}
                    </div>
                  </Drawer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};
export default Header;
