import { Button } from "antd";
import React from "react";
import "./Exchange.scss";

function ExchangeCmnBtn(props) {
  return (
    <Button
      className={`btnExchangeCmn ${props.className}`}
      onClick={() => props.onClick()}
      disabled={props.isDisable}
      htmlType={props.btnType}
    >
      {props.title}
    </Button>
  );
}

export default ExchangeCmnBtn;
