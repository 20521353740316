export function GrayEye() {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="13.842"
        height="9.228"
        viewBox="0 0 13.842 9.228"
      >
        <path
          id="Icon_awesome-eye"
          data-name="Icon awesome-eye"
          d="M13.758,8.763A7.708,7.708,0,0,0,6.921,4.5,7.709,7.709,0,0,0,.084,8.763a.777.777,0,0,0,0,.7,7.708,7.708,0,0,0,6.837,4.263,7.709,7.709,0,0,0,6.837-4.263A.777.777,0,0,0,13.758,8.763ZM6.921,12.574a3.46,3.46,0,1,1,3.46-3.46A3.46,3.46,0,0,1,6.921,12.574Zm0-5.767a2.29,2.29,0,0,0-.608.091A1.15,1.15,0,0,1,4.705,8.506a2.3,2.3,0,1,0,2.216-1.7Z"
          transform="translate(0 -4.5)"
          fill="#80838e"
        />
      </svg>
    </>
  );
}

export function GreenEye() {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="13.842"
        height="9.228"
        viewBox="0 0 13.842 9.228"
      >
        <path
          id="Icon_awesome-eye"
          data-name="Icon awesome-eye"
          d="M13.758,8.763A7.708,7.708,0,0,0,6.921,4.5,7.709,7.709,0,0,0,.084,8.763a.777.777,0,0,0,0,.7,7.708,7.708,0,0,0,6.837,4.263,7.709,7.709,0,0,0,6.837-4.263A.777.777,0,0,0,13.758,8.763ZM6.921,12.574a3.46,3.46,0,1,1,3.46-3.46A3.46,3.46,0,0,1,6.921,12.574Zm0-5.767a2.29,2.29,0,0,0-.608.091A1.15,1.15,0,0,1,4.705,8.506a2.3,2.3,0,1,0,2.216-1.7Z"
          transform="translate(0 -4.5)"
          fill="#00a79e"
        />
      </svg>
    </>
  );
}

export function DownArrow() {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="9.717"
        height="5.555"
        viewBox="0 0 9.717 5.555"
      >
        <path
          id="Icon_ionic-ios-arrow-back"
          data-name="Icon ionic-ios-arrow-back"
          d="M15.131,11.051,11.455,7.377a.694.694,0,0,1,.984-.981L16.6,10.559a.693.693,0,0,1,.02.958l-4.183,4.192a.694.694,0,0,1-.984-.981Z"
          transform="translate(15.911 -11.251) rotate(90)"
          fill="#80838e"
        />
      </svg>
    </>
  );
}

export function RightArrow() {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="5.555"
        height="9.717"
        viewBox="0 0 5.555 9.717"
      >
        <path
          id="Icon_ionic-ios-arrow-back"
          data-name="Icon ionic-ios-arrow-back"
          d="M15.131,11.051,11.455,7.377a.694.694,0,0,1,.984-.981L16.6,10.559a.693.693,0,0,1,.02.958l-4.183,4.192a.694.694,0,0,1-.984-.981Z"
          transform="translate(-11.251 -6.194)"
          fill="#80838e"
        />
      </svg>
    </>
  );
}
export function Btc() {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 23 23"
      >
        <g
          id="Group_106717"
          data-name="Group 106717"
          transform="translate(3841 3861)"
        >
          <path
            id="Path_49323"
            data-name="Path 49323"
            d="M11.5,0A11.5,11.5,0,1,1,0,11.5,11.5,11.5,0,0,1,11.5,0Z"
            transform="translate(-3841 -3861)"
            fill="#f7931a"
            fillRule="evenodd"
          />
          <path
            id="Path_49324"
            data-name="Path 49324"
            d="M1006.1,718.424c.222-1.483-.907-2.28-2.451-2.812l.5-2.009-1.223-.3-.488,1.956c-.322-.08-.652-.156-.98-.231l.491-1.969-1.222-.3-.5,2.008c-.266-.061-.527-.12-.781-.183v-.006l-1.686-.421-.325,1.306s.907.208.888.221a.651.651,0,0,1,.57.711l-.57,2.289a.994.994,0,0,1,.127.041l-.129-.032-.8,3.206a.445.445,0,0,1-.56.29c.012.018-.889-.222-.889-.222l-.607,1.4,1.591.4c.3.074.586.152.872.225l-.506,2.032,1.221.3.5-2.01c.334.091.657.174.975.253l-.5,2,1.223.3.506-2.028c2.085.395,3.653.236,4.313-1.651a2.146,2.146,0,0,0-1.123-2.966,1.95,1.95,0,0,0,1.561-1.8h0Zm-2.794,3.918c-.378,1.519-2.935.7-3.763.492l.671-2.692c.829.207,3.487.616,3.092,2.2Zm.378-3.94c-.345,1.381-2.473.68-3.163.507l.609-2.441c.69.172,2.913.493,2.554,1.934Z"
            transform="translate(-4830.197 -4569.046)"
            fill="#fff"
          />
        </g>
      </svg>
    </>
  );
}
export function BackIcon() {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="8"
        height="14"
        viewBox="0 0 8 14"
        fill="none"
      >
        <path
          d="M7.63268 11.7857L7.63265 11.7856L3.3459 7.49963C3.3459 7.49962 3.34589 7.49962 3.34589 7.49961C3.22797 7.38165 3.16173 7.22169 3.16173 7.0549C3.16173 6.8881 3.22798 6.72813 3.3459 6.61016L7.63134 2.32473C7.71021 2.24838 7.77315 2.15713 7.81647 2.05626C7.85992 1.95511 7.88279 1.84632 7.88375 1.73625C7.88471 1.62617 7.86373 1.517 7.82205 1.41511C7.78036 1.31323 7.7188 1.22066 7.64096 1.14282C7.56312 1.06498 7.47056 1.00342 7.36867 0.961736C7.26678 0.920051 7.15762 0.899075 7.04754 0.900031C6.93746 0.900988 6.82867 0.923858 6.72752 0.967307C6.62665 1.01064 6.53539 1.07357 6.45904 1.15246L1.14274 6.46875L1.14273 6.46876C0.987311 6.62423 0.9 6.83506 0.9 7.0549C0.9 7.27473 0.987311 7.48556 1.14273 7.64103L1.14274 7.64104L6.45961 12.9579C6.45962 12.9579 6.45963 12.9579 6.45964 12.9579C6.61509 13.1135 6.82595 13.2009 7.04585 13.201C7.26577 13.2011 7.47671 13.1138 7.63226 12.9583C7.78782 12.8029 7.87526 12.592 7.87534 12.3721C7.87541 12.1522 7.78813 11.9412 7.63268 11.7857Z"
          fill="#00A79E"
          stroke="#00A79E"
          stroke-width="0.2"
        />
      </svg>
    </>
  );
}
export function SyncIcon() {
  return (
    <>
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="12.000000pt"
        height="12.000000pt"
        viewBox="0 0 512.000000 512.000000"
        preserveAspectRatio="xMidYMid meet"
      >
        <g
          transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
          fill="#00a79e;"
          stroke="00a79e"
        >
          <path
            d="M2358 4949 c-740 -61 -1431 -480 -1825 -1108 l-53 -84 0 241 0 242
-160 0 -160 0 0 -520 0 -520 520 0 520 0 0 160 0 160 -240 0 c-132 0 -240 4
-240 8 0 17 125 212 193 302 383 504 1011 811 1654 810 547 -2 1081 -225 1468
-615 281 -282 480 -653 560 -1043 25 -121 45 -288 45 -373 l0 -49 161 0 162 0
-7 118 c-21 347 -94 628 -246 937 -237 484 -603 852 -1085 1090 -177 87 -298
133 -461 174 -284 72 -531 93 -806 70z"
          />
          <path
            d="M164 2445 c19 -343 98 -645 247 -945 314 -633 874 -1088 1550 -1259
234 -59 360 -75 599 -75 207 1 294 9 475 45 639 128 1208 520 1552 1068 l53
84 0 -241 0 -242 160 0 160 0 0 520 0 520 -520 0 -520 0 0 -160 0 -160 240 0
c132 0 240 -4 240 -8 0 -17 -124 -211 -192 -300 -427 -562 -1155 -876 -1856
-802 -824 88 -1509 639 -1766 1420 -63 190 -106 437 -106 601 l0 49 -162 0
-161 0 7 -115z"
          />
        </g>
      </svg>
    </>
  );
}
export function AheadIcon() {
  return (
    <>
      <svg
        width="8"
        height="14"
        viewBox="0 0 8 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.15052 11.7857L1.15055 11.7856L5.4373 7.49963C5.43731 7.49962 5.43731 7.49962 5.43732 7.49961C5.55523 7.38165 5.62148 7.22169 5.62148 7.0549C5.62148 6.8881 5.55523 6.72813 5.4373 6.61016L1.15187 2.32473C1.07299 2.24838 1.01006 2.15713 0.966728 2.05626C0.923278 1.95511 0.90041 1.84632 0.899453 1.73625C0.898496 1.62617 0.919472 1.517 0.961157 1.41511C1.00284 1.31323 1.0644 1.22066 1.14224 1.14282C1.22008 1.06498 1.31265 1.00342 1.41453 0.961736C1.51642 0.920051 1.62559 0.899075 1.73567 0.900031C1.84575 0.900988 1.95453 0.923858 2.05568 0.967307C2.15655 1.01064 2.24781 1.07357 2.32416 1.15246L7.64046 6.46875L7.64047 6.46876C7.79589 6.62423 7.8832 6.83506 7.8832 7.0549C7.8832 7.27473 7.79589 7.48556 7.64047 7.64103L7.64046 7.64104L2.32359 12.9579C2.32358 12.9579 2.32357 12.9579 2.32356 12.9579C2.16812 13.1135 1.95725 13.2009 1.73735 13.201C1.51744 13.2011 1.3065 13.1138 1.15094 12.9583C0.995381 12.8029 0.907945 12.592 0.907867 12.3721C0.90779 12.1522 0.995075 11.9412 1.15052 11.7857Z"
          fill="#00A79E"
          stroke="#00A79E"
          stroke-width="0.2"
        />
      </svg>
    </>
  );
}
export function FinishIcon() {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="108"
        height="95"
        viewBox="0 0 108 95"
        fill="none"
      >
        <path
          d="M81.7037 29.2815C79.745 28.1623 77.2268 28.1623 74.9884 29.2815L59.3195 38.515L48.687 44.3909L33.2979 53.6243C31.3393 54.7435 28.8211 54.7435 26.5826 53.6243L14.5511 46.3495C12.5925 45.2303 11.1935 42.9918 11.1935 40.4736V26.4835C11.1935 24.2451 12.3127 22.0067 14.5511 20.6077L26.5826 13.6126C28.5412 12.4934 31.0595 12.4934 33.2979 13.6126L45.3294 20.8875C47.288 22.0067 48.687 24.2451 48.687 26.7633V35.9968L59.3195 29.8411V20.3279C59.3195 18.0894 58.2003 15.851 55.9619 14.452L33.5777 1.30132C31.6191 0.182112 29.1009 0.182112 26.8624 1.30132L3.91866 14.7318C1.68024 15.851 0.561035 18.0894 0.561035 20.3279V46.6293C0.561035 48.8677 1.68024 51.1061 3.91866 52.5051L26.5826 65.6558C28.5412 66.775 31.0595 66.775 33.2979 65.6558L48.687 56.7021L59.3195 50.5465L74.7086 41.5928C76.6672 40.4736 79.1854 40.4736 81.4239 41.5928L93.4553 48.5879C95.414 49.7071 96.813 51.9455 96.813 54.4637V68.4538C96.813 70.6923 95.6938 72.9307 93.4553 74.3297L81.7037 81.3247C79.745 82.4439 77.2268 82.4439 74.9884 81.3247L62.9569 74.3297C60.9983 73.2105 59.5993 70.9721 59.5993 68.4538V59.5002L48.9668 65.6558V74.8893C48.9668 77.1277 50.086 79.3661 52.3244 80.7651L74.9884 93.9158C76.947 95.035 79.4652 95.035 81.7037 93.9158L104.368 80.7651C106.326 79.6459 107.725 77.4075 107.725 74.8893V48.3081C107.725 46.0697 106.606 43.8312 104.368 42.4322L81.7037 29.2815Z"
          fill="white"
        />
      </svg>
    </>
  );
}
export function TokenIcon() {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M9.99976 0.000488281C4.47806 0.000488281 -0.00702108 4.48467 8.25135e-06 10.0013C0.00139282 11.105 0.177392 12.1667 0.507717 13.1598H3.49987V4.74661L9.99976 11.2465L16.4993 4.74661V13.16H19.4921C19.8229 12.1669 19.998 11.1052 20 10.0015C20.0094 4.47907 15.522 0.00181959 9.99976 0.00181959V0.000488281Z"
          fill="url(#paint0_linear_77_488)"
        />
        <path
          d="M8.50487 12.7408L5.66827 9.90405V15.1981H3.49956L1.45312 15.1985C3.20849 18.0784 6.38084 20.0048 9.99967 20.0048C13.6185 20.0048 16.791 18.0779 18.5467 15.198H14.3304V9.90405L11.4937 12.7408L9.99935 14.2351L8.50498 12.7408H8.50487Z"
          fill="url(#paint1_linear_77_488)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_77_488"
            x1="10"
            y1="0.000488281"
            x2="10"
            y2="13.16"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#E7804D" />
            <stop offset="1" stop-color="#514E4D" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_77_488"
            x1="9.99991"
            y1="9.90405"
            x2="9.99991"
            y2="20.0048"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#E7804D" />
            <stop offset="1" stop-color="#514E4D" />
          </linearGradient>
        </defs>
      </svg>
    </>
  );
}
export function Trc() {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 44 44"
      >
        <g
          id="Group_106681"
          data-name="Group 106681"
          transform="translate(-22 -668)"
        >
          <path
            id="Path_49474"
            data-name="Path 49474"
            d="M22,0A22,22,0,1,1,0,22,22,22,0,0,1,22,0Z"
            transform="translate(22 668)"
            fill="#ff060a"
            fillRule="evenodd"
          />
          <g id="tron" transform="translate(31.35 680)">
            <path
              id="Path_49472"
              data-name="Path 49472"
              d="M24.05,7.23C22.925,6.191,21.369,4.6,20.1,3.48l-.075-.053a1.433,1.433,0,0,0-.416-.233h0C16.554,2.625,2.33-.034,2.053,0a.525.525,0,0,0-.218.083L1.764.138a.836.836,0,0,0-.2.315L1.55.5V.809c1.6,4.459,7.924,19.065,9.169,22.493.075.233.218.675.484.7h.06c.143,0,.75-.8.75-.8s10.86-13.17,11.959-14.573a3.547,3.547,0,0,0,.375-.555A.9.9,0,0,0,24.05,7.23ZM14.8,8.763,19.434,4.92l2.719,2.505ZM13,8.512l-7.98-6.54L17.93,4.353Zm.72,1.714L21.887,8.91,12.549,20.16Zm-9.784-7.6,8.4,7.125L11.116,20.167Z"
              transform="translate(0 0)"
              fill="#fff"
            />
          </g>
        </g>
      </svg>
    </>
  );
}
export function Swap() {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="52.492"
        height="54.07"
        viewBox="0 0 52.492 54.07"
      >
        <g
          id="Group_150028"
          data-name="Group 150028"
          transform="translate(-1.374)"
        >
          <path
            id="Path_102617"
            data-name="Path 102617"
            d="M53.156,3.156a1.147,1.147,0,0,0-1.253.249l-4.78,4.78A26.7,26.7,0,0,0,27.828,0,26.969,26.969,0,0,0,1.7,20.258a1.15,1.15,0,0,0,1.113,1.436h7.322a1.149,1.149,0,0,0,1.079-.755,17.642,17.642,0,0,1,16.6-11.651,17.5,17.5,0,0,1,12.742,5.46L35.706,19.6a1.15,1.15,0,0,0,.813,1.962h16.2a1.15,1.15,0,0,0,1.15-1.15V4.218a1.151,1.151,0,0,0-.71-1.062Zm-.732,29.22H45.1a1.149,1.149,0,0,0-1.079.754,17.642,17.642,0,0,1-16.6,11.651,17.5,17.5,0,0,1-12.742-5.46l4.853-4.853A1.15,1.15,0,0,0,18.72,32.5H2.524a1.15,1.15,0,0,0-1.15,1.15v16.2a1.15,1.15,0,0,0,1.962.812l4.78-4.779a26.7,26.7,0,0,0,19.295,8.185A26.969,26.969,0,0,0,53.538,33.813a1.148,1.148,0,0,0-1.113-1.437Z"
            transform="translate(0)"
            fill="#00a79e"
          />
        </g>
      </svg>
    </>
  );
}
export function Rewarded() {
  return (
    <>
      <svg
        id="Group_152564"
        data-name="Group 152564"
        xmlns="http://www.w3.org/2000/svg"
        width="24.552"
        height="28.244"
        viewBox="0 0 24.552 28.244"
      >
        <path
          id="Path_103187"
          data-name="Path 103187"
          d="M11.387,15.822h-.855v-1.7a.5.5,0,0,0-1.009,0v1.7H8.669A1.012,1.012,0,0,0,7.66,16.831v12.2A1.009,1.009,0,0,0,8.669,30.04h.855v1.7a.5.5,0,0,0,1.009,0v-1.7h.855A1.009,1.009,0,0,0,12.4,29.032v-12.2a1.012,1.012,0,0,0-1.009-1.009Zm6.6-6.254h-.851v-1.7a.5.5,0,0,0-1.009,0v1.7h-.853a1.012,1.012,0,0,0-1.009,1.009v12.2a1.009,1.009,0,0,0,1.009,1.009h.853v1.7a.5.5,0,0,0,1.009,0v-1.7h.851A1.005,1.005,0,0,0,19,22.777v-12.2a1.009,1.009,0,0,0-1.009-1.009ZM24.6,13.084h-.853v-1.7a.5.5,0,0,0-1.009,0v1.7h-.851a1.009,1.009,0,0,0-1.009,1.009v12.2A1.009,1.009,0,0,0,21.883,27.3h.851V29a.5.5,0,1,0,1.009,0V27.3H24.6a1.012,1.012,0,0,0,1.009-1.009v-12.2A1.012,1.012,0,0,0,24.6,13.084ZM31.2,6.2h-.855V4.5a.5.5,0,1,0-1.009,0V6.2h-.855a1.009,1.009,0,0,0-1.009,1.009v12.2a1.012,1.012,0,0,0,1.009,1.009h.855v1.7a.5.5,0,0,0,1.009,0v-1.7H31.2a1.012,1.012,0,0,0,1.009-1.009V7.213A1.009,1.009,0,0,0,31.2,6.2Z"
          transform="translate(-7.66 -4)"
          fill="#fff"
        />
      </svg>
    </>
  );
}
