import React from "react";
import { StoreImages } from "../../Storeimgaes/StoreImages";
import style from "./style.module.scss";

const BuyList = (props) => {
  const { onClick } = props;
  const { bitCoin, backArrow, etheImg } = StoreImages;

  const data = [
    { id: 1, img: bitCoin, name: "Bitcoin", short: "BTC" },
    { id: 2, img: etheImg, name: "Ethereum", short: "ETH" },
    { id: 3, img: bitCoin, name: "Tron", short: "TRX" },
    { id: 4, img: etheImg, name: "Binance", short: "BNB" },
    { id: 5, img: bitCoin, name: "Litecoin", short: "LTC" },
  ];

  return (
    <>
      <div className={`card ${style.btnList}`}>
        <div className={style.btnList_heading}>
          <button onClick={onClick}>
            <img src={backArrow} alt="icon" />
          </button>
          <h5>Buy</h5>
        </div>
        <ul>
          {data.map((user, id) => (
            <>
              <li id={id}>
                <img src={user.img} alt="icon" />
                <h6>
                  {user.name}
                  <br />
                  <span>{user.short}</span>
                </h6>
              </li>
            </>
          ))}
        </ul>
      </div>
    </>
  );
};

export default BuyList;
