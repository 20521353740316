import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RightOutlined } from "@ant-design/icons";
import { convertNetworkName, uppercase } from "../../../helpers";
import {
  depositWithdrawDropdownType,
  sendReceiveType,
} from "../../../staticObjects";
import ListLikeDropdown from "../../Common/listLikeDropdown";
import style from "./style.module.scss";
import {
  fetchBeneficiary,
  getAddress,
} from "../../../redux/services/sendReceive";
import DownArrow from "../../Assets/Images/downarrowdark.svg";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { setCoinHubUser, setNetworkError } from "../../../redux/feature";

const initDivs = {
  assetDiv: false,
  networkDiv: false,
}; 
const CommonAssetNetworkList = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { popup, coinHubUser, tab } = useSelector(
    (state) => state?.sendReceive
  );
  const { currenciesList } = useSelector((state) => state.commonApiData);
  const [divStatus, setDivStatus] = useState(initDivs);
  const [currentCurrencyObj, setCurrentCurrencyObj] = useState({});
  const [currentNetwork, setCurrentNetwork] = useState({});
  const [networkText, setNetworkText] = useState(""); // State for network text
  const { asset, network } = depositWithdrawDropdownType;
  const [lastAction, setLastAction] = useState(asset);
  const saveCurrentMarket = useSelector(
    (state) => state?.exchange?.saveCurrentMarket
  );

  const {
    convertIcon,
    cbFun,
    className,
    type,
    isAddAddressModal,
    protocol,
    setStates,
    setCheckNetworkErr,
  } = props;
  const { assetDiv, networkDiv } = divStatus;
  const currObj = props.balanceObj;

  useEffect(() => {
    let curr = filterSelectCurrObj(currObj?.currency);
    if (curr !== undefined && Object.keys(curr).length > 0) {
      setCurrentCurrencyObj(curr);
      // setCurrentNetwork({ ...curr?.networks?.[0] });
      // cbFun({ ...curr?.networks[0] }, network);
    }
  }, [currenciesList, currObj]);

  useEffect(() => {
    let curr = filterSelectCurrObj(currObj?.currency);
    if (curr !== undefined && Object.keys(curr).length > 0 && protocol !== "") {
      setCurrentNetwork(
        curr?.networks?.filter((e) => e.protocol === protocol)?.[0]
      );
      setNetworkText(
        uppercase(
          curr?.networks
            ?.filter((e) => e.protocol === protocol)?.[0]
            ?.blockchain_key?.split("-")[0]
        )
      );

      cbFun(
        curr?.networks.filter((e) => e.protocol === protocol)?.[0],
        network
      );
    }
  }, [protocol]);
  useEffect(() => {
    if (
      currentCurrencyObj !== undefined &&
      currentNetwork !== undefined &&
      Object.keys(currentCurrencyObj).length &&
      Object.keys(currentNetwork).length
    ) {
      if (type === sendReceiveType.deposit) {
        dispatch(
          getAddress({
            id: currentCurrencyObj?.id,
            blockchain_key: currentNetwork?.blockchain_key,
          })
        );
      } else if (type === sendReceiveType.withdraw) {
        // setCheckNetworkErr(true)
        if (!isAddAddressModal) {
          if (lastAction === asset) {
            // dispatch(fetchBeneficiary({ coin: currentCurrencyObj?.id }));
          }
        }
      }
    }
  }, [currentCurrencyObj, currentNetwork]);

  const filterSelectCurrObj = (value) => {
    return (
      currenciesList.length > 0 &&
      currenciesList?.filter((item) => item.id === value)[0]
    );
  };
  const openCloseDivsFun = (status, type = "") => {
    let tempDiv = { ...divStatus };
    if (!status) {
      setDivStatus(initDivs);
    } else {
      if (type === asset) {
        tempDiv["assetDiv"] = true;
        tempDiv["networkDiv"] = false;
        !popup && setCurrentNetwork({});
        setNetworkText("");
        setStates(true);
        setCheckNetworkErr && setCheckNetworkErr(false);
        dispatch(setNetworkError(false));
      } else if (type === network) {
        setCheckNetworkErr && setCheckNetworkErr(false);
        setStates(true);
        tempDiv["networkDiv"] = true;
        tempDiv["assetDiv"] = false;
        // Set network text when network section is opened
        // setNetworkText(uppercase(currentNetwork?.blockchain_key?.split("-")[0]));
      }
      setDivStatus({ ...tempDiv });
    }
  };

  const setNetworkFun = (obj, type) => { 
    if (type === network) {
      if (obj?.blockchain_key !== currentNetwork?.blockchain_key) {
        setCurrentNetwork({ ...obj });
        cbFun(obj, type);
        setCheckNetworkErr && setCheckNetworkErr(false);
        setNetworkText(uppercase(obj?.blockchain_key?.split("-")[0])); // Update network text when a network is selected
      }
      setLastAction(type);
    }
  };

  const setAssetFun = (obj, type) => {
    if (type === asset) {
      cbFun(obj, type);
      setLastAction(type);
    }
  }; 
  return (
    <>
      {isAddAddressModal ? (
        <div className={style.inputLike}>
          <div className={style.inputLike_outer}>
            <p>{t("addrsManagement.coin")}</p>
            <div onClick={() => openCloseDivsFun(true, asset)}>
              {Object.keys(currObj).length ? (
                <>
                  {currObj?.currency_name}
                  <img src={DownArrow} />
                </>
              ) : (
                "Select Coin"
              )}
            </div>
          </div>
          <div className={style.inputLike_outer}>
            <p>{t("addrsManagement.network")}</p>
            <div onClick={() => openCloseDivsFun(true, network)}>
              {convertNetworkName(networkText)}
              <img src={DownArrow} />
            </div>
          </div>

          {assetDiv && (
            <div className={`${style.cmn_drpListStyle} ${style.assetactive}`}>
              <div
                className={`${style.positionedContent} ${style.assetDiv}  card ${style.frstdrop}`}
              >
                <ListLikeDropdown
                  balanceObj={currObj}
                  cbFun={setAssetFun}
                  type={asset}
                  onClick={() => openCloseDivsFun(false)}
                />
              </div>
            </div>
          )}
          {networkDiv && (
            <div className={`${style.cmn_drpListStyle} ${style.netactive}`}>
              <div
                className={`${style.positionedContent} ${style.networkDiv} card`}
              >
                <ListLikeDropdown
                  type={network}
                  cbFun={setNetworkFun}
                  allNetwork={currentCurrencyObj?.networks}
                  currentNetwork={currentNetwork}
                  onClick={() => openCloseDivsFun(false)}
                />
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className={`${style.Buy} ${className} dfdsfs`}>
          <div className={` ${style.Buy_btn} `}>
            <ul className={`${style.convertIcon} d-flex buyDrpDnList`}>
              <li
                style={{
                  position: "relative",
                  border: 0,
                  margin: "0",
                  paddingBottom: "0",
                }}
              >
                <label>{t("portfolio.asset")}</label>
                <div
                  className={`${style.dropValue} dropValue`}
                  onClick={() => openCloseDivsFun(!divStatus.assetDiv, asset)}
                >
                  <div>
                    <img
                      className={style.Buy_btn_icon}
                      src={currObj?.currency_icon}
                      alt="icon"
                    />
                    <span>{currObj?.currency_name}</span>
                  </div>
                  <RightOutlined />
                </div>
              </li>
              {coinHubUser === "withdrawalAddress" && (
                <li
                  style={{
                    margin: "0",
                    paddingBottom: "0",
                  }}
                >
                  <label>{t("coinlisting.network")}</label>
                  <div
                    className={`${style.dropValue} dropValue`}
                    onClick={() =>
                      openCloseDivsFun(!divStatus.networkDiv, network)
                    }
                  >
                    <div style={{ color: "#80838e" }}>
                      {convertNetworkName(
                        networkText
                          ? networkText
                          : t("coinlisting.Select_network")
                      )}
                    </div>
                    <RightOutlined />
                  </div>
                </li>
              )}
            </ul>
          </div>

          <div className={`${style.cmn_drpListStyle}`}>
            {assetDiv && (
              <div
                className={`${style.positionedContent} ${style.assetDiv}  card`}
              >
                <ListLikeDropdown
                  balanceObj={currObj}
                  cbFun={setAssetFun}
                  type={asset}
                  onClick={() => openCloseDivsFun(false)}
                />
              </div>
            )}
            {networkDiv && (
              <div
                className={`${style.positionedContent} ${style.networkDiv} card`}
              >
                <ListLikeDropdown
                  type={network}
                  cbFun={setNetworkFun}
                  allNetwork={currentCurrencyObj?.networks}
                  currentNetwork={currentNetwork}
                  onClick={() => openCloseDivsFun(false)}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default CommonAssetNetworkList;
