import React, { useEffect, useState } from 'react'
import style from "../../AssetsTable/style.module.scss";
import { useTranslation } from 'react-i18next';
import { Button, Modal, Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { deleteDeviceData, getDeviceData } from '../../../redux/services/securityServie';
import moment from 'moment';
import CustomPagination from '../../customHooks/customPagination';
import { getCsrfToken } from '../../../helpers';
import { customLocale } from '../../../helpers/customLocale';


const DeviceManagement = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { list, totalDevice } = useSelector(state => state.security);

    const [deviceData, setDeviceData] = useState("");
    const [pageNo, setPageNo] = useState(1);
    const [limit, setLimit] = useState(10);
    const [deleteModal, setDeleteModal] = useState(false);

    useEffect(() => {
        dispatch(getDeviceData({
            limit: limit,
            page: pageNo,
        }))
    }, [limit, pageNo])

    const tableCol = [
        { dataIndex: "user_agent", title: <p className="coins">{t("device_management_page.Device")}</p> },
        { dataIndex: "created_at", title: <p className="coins">{t("device_management_page.Date")}</p> },
        { dataIndex: "user_ip", title: <p className="coins">{t("device_management_page.IP_address")}</p> },
        { dataIndex: "action", title: <p className="coins">{t("device_management_page.Action")}</p> },
    ];

    const onDelete = (token, uid) => {
        setDeleteModal(true);
        setDeviceData(token + "_" + uid);
    }

    const deleteRecord = async () => {
        if (deviceData) {
            let res = await dispatch(deleteDeviceData({
                action: "delete",
                store_session_id: deviceData,
                platform: "web"
            }));
            if (res.payload.status) {
                setDeleteModal(false);
            }
        }
    }

    const datax = () => {
        let newData = [];
        list?.length > 0 &&
            list?.map((items, idx) => {
                if (getCsrfToken() === items?.csrf_token) return
                let temp = {
                    keyId: idx,
                    "user_agent": items?.user_agent || "-",
                    "created_at": moment(items?.created_at).format("DD-MM-YYYY  HH:mm") || "-",
                    "user_ip": items?.user_ip || "-",
                    "action": (
                        <Button className='delButton' type="link" onClick={() => onDelete(items?.csrf_token, items?.uid)}>
                            Delete
                        </Button>
                    ),
                };

                newData.push(temp);
            });
        return newData;
    };

    return (
        <>
            <div className="style_earn__9r+gR">
                <div className={`tabledevicemanage ${style.earn__assets}`}>
                    <div className={`${style.asset} assetTable`}>
                        <div className="topHeading headingmanage">
                            <h4 className="text-black-20">{t("device_management_page.heading")}</h4>
                            <p className="text-black">{t("device_management_page.desc")}</p>
                        </div>
                        <Table
                            rowKey={(obj) => obj.keyId}
                            columns={tableCol}
                            dataSource={datax()}
                            pagination={false}
                            className="portfolioTable cursorInitial"
                            locale={customLocale}
                        />
                        <CustomPagination
                            pageNo={pageNo}
                            limit={limit}
                            total={totalDevice}
                            onChange={(pageNo, pageSize) => {
                                setPageNo(pageNo);
                                setLimit(pageSize);
                            }}
                        />
                    </div>
                </div>
            </div>

            {deleteModal &&
                (<Modal
                    className='deleteModal'
                    open={() => setDeleteModal(true)}
                    onOk={() => deleteRecord()}
                    onCancel={() => setDeleteModal(false)}
                    okText="Yes"
                    cancelText="No"
                    closable={false}
                    centered
                >
                    <p>Are you sure you want to delete the selected trusted device</p>
                </Modal>)}

        </>
    )
}

export default DeviceManagement