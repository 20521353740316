import React from "react";
import style from "./style.module.scss";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { AheadIcon } from "../../Assets/Svg/Svg";
import { useDispatch } from "react-redux";

const NewCard = ({ title, Detail, img, onClick, id }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <>
      <div className={style.newCard}>
        <div className={style.newCard_img}>
          <img src={img} alt="icon" />
        </div>
        <div className={style.newCard_Content}>
          <div>
            <h4>{title}</h4>
          </div>
            <p>{Detail}</p>
        </div>
        <div className={style.newCard_btn}>
          <Button
            style={{ marginBottom: "unset" }}
            className={`backbtn ${style.back}`}
            type="text"
            onClick={onClick}
          >
            Read more <AheadIcon style={{ marginLeft: "5px" }} />
          </Button>
        </div>
      </div>
    </>
  );
};

export default NewCard;
