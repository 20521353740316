import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../../api";
import { buildQueryString } from "../../helpers";
import * as config from "../../utils/apiVersion";
import { startLoading, stopLoading } from "../feature";
import { errorToastSelector, successToastSelector } from "../selector";

export const getEarnData = createAsyncThunk(
  "earn/getEarnData",
  async (payload, { dispatch }) => {
    try {
      dispatch(startLoading());
      let url = "/stake";
      const res = await API.get(config.invest)(url);
      dispatch(stopLoading());
      return res;
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return false;
    }
  }
);
export const prematureEarningsWithdraw = createAsyncThunk(
  "earn/prematureEarningsWithdraw",
  async (payload, { dispatch }) => {
    try {
      dispatch(startLoading());
      const { id } = payload; // Extract currency_id from payload
      let url = `/stake?id=${id}`;
      const res = await API.put(config.invest)(url);
      dispatch(stopLoading());
      dispatch(
        successToastSelector({
          message:
            "Unstaking successful. These assets will appear in you Spot wallet when the transaction completes.",
        })
      );

      return res;
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return false;
    }
  }
);
export const getReward = createAsyncThunk(
  "earn/getReward",
  async (payload, { dispatch }) => {
    try {
      dispatch(startLoading());
      let url = "/stake/portfolio/rewards";
      const res = await API.get(config.invest)(url);
      dispatch(stopLoading());
      return res;
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return false;
    }
  }
);

export const getEarnAssetsData = createAsyncThunk(
  "earn/getEarnAssetsData",
  async (payload, { dispatch }) => {
    try {
      dispatch(startLoading());
      let url = "/asset";
      const res = await API.get(config.invest)(url);
      dispatch(stopLoading());
      return res;
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return false;
    }
  }
);

export const submitAssets = createAsyncThunk(
  "earn/submitAssets",
  async (payload, { dispatch }) => {
    try {
      dispatch(startLoading());
      let url = "/stake";
      const res = await API.post(config.invest)(url, payload);
      dispatch(stopLoading());
      dispatch(successToastSelector({ message: "Staking successful" }));
      return { data: res, status: true };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return false;
    }
  }
);

export const getAllEarnings = createAsyncThunk(
  "earn/getAllEarnings",
  async (payload, { dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `stake/running?${buildQueryString(payload)}`;
      const { data, headers } = await API.get(config.investWithHeader)(url);
      dispatch(stopLoading());
      return { list: data, status: true, total: headers?.total };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return false;
    }
  }
);

export const getAllHistory = createAsyncThunk(
  "earn/getAllHistory",
  async (payload, { dispatch }) => {
    try {
      dispatch(startLoading());
      let url = `/stake/exhausted?${buildQueryString(payload)}`;
      const { data, headers } = await API.get(config.investWithHeader)(url);
      dispatch(stopLoading());
      return { list: data, status: true, total: headers?.total };
    } catch (e) {
      dispatch(stopLoading());
      dispatch(errorToastSelector(e, "error"));
      return false;
    }
  }
);
