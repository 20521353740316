import React from "react";
import { NoRecodImage } from "../Storeimgaes/ExportSvgs";
import { useTranslation } from "react-i18next";
export const NoRecord = (props) => {
  const { t } = useTranslation();

  return (
    <div className="noRecordFound">
      <span>
        <NoRecodImage />
      </span>
      <p> {props.text ?? t("home.No_Record_Found")}</p>
    </div>
  );
};
