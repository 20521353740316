import React, { useState } from "react";
import style from "./style.module.scss";
import { Row, Col } from "antd";
import { StoreImages } from "../../Storeimgaes/StoreImages";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { getBalanceHistory } from "../../../redux/services";
import { capitalize, preciseData, uppercase } from "../../../helpers";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { NoRecord } from "../../NoRecord";
import CustomPagination from "../../customHooks/customPagination";
const initData = {
  page: 1,
  limit: 10,
};
const PrimaryBalanceTab = () => {
  const { t } = useTranslation();
  const { flow } = StoreImages;
  const { id } = useParams();
  const dispatch = useDispatch();
  const { balanceList } = useSelector((state) => state.commonApiData);
  const { balanceHistoryTotal, balanceHistory } = useSelector(
    (state) => state.commonApiData
  );
  const [currentBalance, setCurrentBalance] = useState({});
  const [paginationState, setPaginationState] = useState({ ...initData });

  const { page, limit } = paginationState;

  const apiCall = (data) => {
    dispatch(
      getBalanceHistory({
        limit: limit,
        page: page,
        currency_id: id,
        ...data,
      })
    );
  };

  useEffect(() => {
    apiCall({});
  }, []);

  const filterBalance = (id) => {
    let filterData = balanceList?.find?.((val, idx) => val.currency === id);
    setCurrentBalance(filterData);
    return filterData;
  };

  useEffect(() => {
    if (balanceList.length) {
      filterBalance(id);
    }
  }, [balanceList]);

  const balance = Number(currentBalance?.balance ?? 0);
  const locked = Number(currentBalance?.locked ?? 0);
  const usdt_value = Number(currentBalance?.currency_usdt ?? 0);
  const currency = currentBalance?.currency ?? "";
  const balanceString = `${(balance + locked)
    .toFixed(2)
    .replace(/\.0+$/, "")} ${uppercase(currency)} (${(
      (balance + locked) *
      usdt_value
    )
      .toFixed(2)
      .replace(/\.0+$/, "")})`;

  return (
    <>
      <div className={`${style.cointdetailPage_tabs_balance} ${style.mt} card`}>
        <h4>
          {t("portfolio.balance")}: {balanceString}
        </h4>
        <div className={style.scrolledEleMob}>
          <div>
            {balanceHistory?.length > 0 ? (
              balanceHistory?.map((item, index) => {
                let currUpercase = uppercase(item?.currency);
                let isSwapP2PBal = item.reference_kind === "swap_p2p_balance";
                let prevBal = Number(item.previous_balance);
                let newBal = Number(item?.new_balance);
                let description = item?.description;
                let prevSubNewUsdt = (prevBal - newBal) * usdt_value;
                let newSubPrevUsdt = (newBal - prevBal) * usdt_value;
               
                return (
                  <Row
                    key={index}
                    className={style.cointdetailPage_tabs_balance_flex}
                  > 
                    <Col xs={24} lg={12} xl={12}>
                      <div
                        className={style.cointdetailPage_tabs_balance_flex_main}
                      >
                        <h5>
                          <span>{moment(item?.created_at).format("MMM")}</span>
                          <br />
                          {moment(item?.created_at).format("DD")}
                        </h5>
                        <img src={flow} alt="icon" />
                        {item?.reference_type === "accountadjustment" ? (
                          <h5>
                            Converted from {currUpercase}
                            <br />
                            <span> Using {currUpercase} Wallet </span>
                          </h5>
                        ) : item?.reference_type === "deposit" ? (
                          <h5>
                            Received {currUpercase}
                            <br />
                            <span>From {currUpercase} Deposit</span>
                          </h5>
                        ) : item?.reference_type === "withdraw" ? (
                          <h5>
                            Sent {currUpercase}
                            <br />
                            <span> To {currUpercase} Address </span>
                          </h5>
                        )
                          : item?.reference_type === "transfer" ? (
                            <h5>
                              Transfer {capitalize(item?.reference_kind) || ""} <br />
                              <span>To {" "} {currUpercase}</span>
                            </h5>
                          )
                            : item?.reference_type === "adjustment" ? (
                              <h5>
                                Sent {currUpercase}
                                <br />
                                <span> To {currUpercase}</span>
                              </h5>
                            ) : item?.reference_type?.split('::')?.[0] === "trade" ? (
                              <h5>
                                Bot {currUpercase}
                                <br />
                                <span>Using pair {currUpercase}</span>
                              </h5>
                            ) : item?.reference_type === "reward" ||
                              item?.reference_type === "member" ? (
                              <h5>
                                Reward
                                <br />
                                <span>Using {currUpercase}</span>
                              </h5>
                            ) : item?.reference_type?.split('::')?.[0] === "order" ? (
                              <h5>
                                Order
                                <br />
                                <span> {item?.reference_type?.split('::')?.[1]} order</span>
                              </h5>
                            ) : item?.reference_type === "account" ? (
                              <h5>
                                Transfer from {currUpercase}
                                <br />
                                <span> Using {currUpercase} Wallet</span>
                              </h5>
                            ) : null}

                      </div>
                    </Col>
                   
                    <Col xs={24} lg={12} xl={12}>
                      <h5 className={style.coin_bls}>
                        {item?.reference_type === "account" && isSwapP2PBal
                          ? preciseData(
                            item?.previous_balance - item?.new_balance,
                            item.precision
                          )
                          : preciseData(
                            item?.new_balance - item?.previous_balance,
                            item.precision
                          )}
                        <br />
                        <span>
                          {item?.reference_type === "account" && isSwapP2PBal
                            ? `$${prevSubNewUsdt
                              .toFixed(2)
                              .replace(/\.0+$/, "")}`
                            : `$${newSubPrevUsdt
                              .toFixed(2)
                              .replace(/\.0+$/, "")}`}
                        </span>
                      </h5>
                    </Col>
                    <Col xs={24} lg={24} xl={24}>
                    <span className={style.descriptionText}>{item?.description}</span>                      
                    </Col>
                  </Row>
                );
              })
            ) : (
              <div className="empty_data">
                <NoRecord />
              </div>
            )}
          </div>
          <CustomPagination
            pageNo={page}
            limit={limit}
            total={balanceHistoryTotal}
            onChange={(pageNo, pageSize) => {
              apiCall({ page: pageNo, limit: pageSize });
              setPaginationState({
                ...paginationState,
                page: pageNo,
                limit: pageSize,
              });
            }}
          />
        </div>
      </div>
    </>
  );
};

export default PrimaryBalanceTab;
