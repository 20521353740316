import React, { useState } from "react";
import { Modal, Button } from "antd";
import InputCustom from "../../Ui/input/InputCustom";
import CommonButton from "../../Ui/button/CommonButton";
import CheckboxCustom from "../../Ui/checkbox/CheckboxCustom.jsx";
import { useTranslation } from "react-i18next";
function CommitModaldata() {
  const { t } = useTranslation();

  const commitments = [
    { commitmentText: "Max commitment limit", commitmentLimit: "0.4500 MNT" },
    { commitmentText: "Spot wallet balance", commitmentLimit: "0.0232 MNT" },
    {
      commitmentText: "Single Initial Investment",
      commitmentLimit: "0.10 MNT",
    },
  ];
  const [commit, setCommit] = useState(false);
  const showCommit = () => {
    setCommit(true);
  };
  const handleOk = () => {
    setCommit(false);
  };
  const handleCancel = () => {
    setCommit(false);
  };
  return (
    <div>
      <Button className="btn_custom" type="primary" onClick={showCommit}>
        Commit MNT
      </Button>
      <Modal
        centered
        width={574}
        className="commitModalstyle"
        open={commit}
        footer={false}
        // onOk={handleOk}
        onCancel={handleCancel}
      >
        <h3>Commit MNT</h3>
        <div className="commitModalstyle_content">
          <ul>
            {commitments.map((commitment, index) => (
              <li key={index}>
                {commitment.commitmentText}
                <span>{commitment.commitmentLimit}</span>
              </li>
            ))}
          </ul>
        </div>
        <div className="commitModalstyle_inputs input_textimg ">
          <InputCustom
            placeholder={t("market.enter_amount")}
            label="Amount"
            basicinput={+true}
            suffix={
              <span style={{ color: "#00A79E", fontWeight: "600" }}>MAX</span>
            }
          />
          <CheckboxCustom
            style={{ display: "flex", marginTop: "10px" }}
            checkboxTxt={
              <span>
                I have read and agree to the <span>purchase agreement</span>
              </span>
            }
            // checked={hideSymbol}
          />
          <CommonButton
            style={{ width: "100%", marginTop: "52px" }}
            btn_custom
            title="Commit Now"
            onClick={() => handleCancel()}
          />
        </div>
      </Modal>
    </div>
  );
}

export default CommitModaldata;
