import { createSlice } from "@reduxjs/toolkit";
import {
  fetchAllBeneficiary,
  fetchAllBank,
  fetchBeneficiary,
  getAddress,
  fetchBankDetail,
  fetchBankbeneficiary,
  qpayPost,
  socialpayPost,
  fetchIsInternalAddress,
  getHideZeroBls,
  fetchWithdrawLimit,
  fetchWithdrawFees,
} from "../../services";

export const sendReceiveSlice = createSlice({
  name: "sendReceiveSlice",
  initialState: {
    error: false,
    address: "",
    beneficiaryList: [],
    allBeneficiaryList: [],
    allBankList: [],
    detailBankList: [],
    bankbeneficiaryList: [],
    beneficiaryTotal: 0,
    payData: [],
    socialpay: [],
    isItInternalAddress: false,
    text: false,
    networkErr: false,
    withdrawLimit: {},
    tab: "deposit",
    currency: "",
    network: "",
    popup: false,
    withdrawfee: {},
    coinHubUser: "withdrawalAddress",
  },
  reducers: {
    setCoinHubUser: (state, action) => {
      state.coinHubUser = action.payload;
    },
    bankAmountReset: (state, action) => {
      state.payData = "";
      state.socialpay = "";
    },
    currentCurrency: (state, action) => {
      state.currency = action.payload;
    },
    currentActiveNetwork: (state, action) => {
      state.network = action.payload;
    },
    setText: (state, action) => {
      state.text = true;
    },
    setNetworkError: (state, action) => {
      state.networkErr = action.payload;
    },
    setSelectedPaneTab: (state, action) => {
      console.log(action.payload,"action")
      state.tab = action.payload;
    },
    emptyAddress: (state, action) => {
      state.address = "";
    },
    popupStatus: (state, action) => {
      state.popup = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAddress.pending, (state) => { })
      .addCase(getAddress.fulfilled, (state, action) => {
        state.address = action?.payload?.data;
      })
      .addCase(getAddress.rejected, (state) => { })

      .addCase(fetchBeneficiary.pending, (state) => { })
      .addCase(fetchBeneficiary.fulfilled, (state, action) => {
        state.beneficiaryList = action?.payload?.data;
      })
      .addCase(fetchBeneficiary.rejected, (state) => { })

      .addCase(fetchAllBeneficiary.pending, (state) => { })
      .addCase(fetchAllBeneficiary.fulfilled, (state, action) => {
        state.allBeneficiaryList = action?.payload?.data;
        state.beneficiaryTotal = action.payload.total;
      })
      .addCase(fetchAllBeneficiary.rejected, (state) => { })

      .addCase(fetchIsInternalAddress.pending, (state) => { })
      .addCase(fetchIsInternalAddress.fulfilled, (state, action) => {
        state.isItInternalAddress = action?.payload?.data;
      })
      .addCase(fetchIsInternalAddress.rejected, (state) => { })

      // bank list
      .addCase(fetchAllBank.pending, (state) => { })
      .addCase(fetchAllBank.fulfilled, (state, action) => {
        state.allBankList = action?.payload?.data;
      })
      .addCase(fetchAllBank.rejected, (state) => { })

      // single bank detail
      .addCase(fetchBankDetail.pending, (state) => { })
      .addCase(fetchBankDetail.fulfilled, (state, action) => {
        state.detailBankList = action?.payload?.data;
      })
      .addCase(fetchBankDetail.rejected, (state) => { })

      .addCase(fetchBankbeneficiary.pending, (state) => { })
      .addCase(fetchBankbeneficiary.fulfilled, (state, action) => {
        state.bankbeneficiaryList = action?.payload?.data;
      })
      .addCase(fetchBankbeneficiary.rejected, (state) => { })

      .addCase(qpayPost.pending, (state) => { })
      .addCase(qpayPost.fulfilled, (state, action) => {
        state.payData = action?.payload?.data;
      })
      .addCase(qpayPost.rejected, (state) => { })

      .addCase(socialpayPost.pending, (state) => { })
      .addCase(socialpayPost.fulfilled, (state, action) => {
        state.payData = action?.payload?.data;
      })
      .addCase(socialpayPost.rejected, (state) => { })
      .addCase(fetchWithdrawLimit.pending, (state) => { })
      .addCase(fetchWithdrawLimit.fulfilled, (state, action) => {
        state.withdrawLimit = action?.payload?.data;
      })
      .addCase(fetchWithdrawLimit.rejected, (state) => { })

      .addCase(fetchWithdrawFees.pending, (state) => { })
      .addCase(fetchWithdrawFees.fulfilled, (state, action) => {
        state.withdrawfee = action?.payload?.data;
      })
      .addCase(fetchWithdrawFees.rejected, (state) => { });
  },
});
export default sendReceiveSlice.reducer;
export const {
  bankAmountReset,
  setText,
  currentCurrency,
  currentActiveNetwork,
  popupStatus,
  setNetworkError,
  setSelectedPaneTab,
  emptyAddress,
  setCoinHubUser,
} = sendReceiveSlice.actions;
