import React from "react";
import LaunchpadStepList from "./LaunchpadStepList";
import LaunchpadCard from "./LaunchpadCard";
import style from "./style.module.scss";
import { Typography, Button } from "antd";
import SignInHeader from "../../Layout/Header/SignInHeader";
import Footer from "../../Layout/Footer/Footer";
import { useNavigate, Link } from "react-router-dom";
import { BackIcon, AheadIcon } from "../../Assets/Svg/Svg";

const { Title } = Typography;

const lineText = [
  "Arkham (ARKM) is an intel-to-earn economy powering the deanonymization of the blockchain with AI.",
  "Arkham uses AI to deanonymize the blockchain, showing users the people and companies behind cryptocurrency transactions; and Arkham introduces intel-to-earn, an intel economy that matches buyers and sellers of blockchain data at scale",
  "ARKM is the Arkham platform’s native utility token. It's utility include using it as a currency to access the premium features of Arkham Intel Exchange at a discount, incentives to facilitate transactions on the intel exchange marketplace and Governance.",
];
const lineText1 = [
  "Arkham is a data intelligence platform that aims to deanonymize the pseudoanonymous metadata to enhance on-chain transparency. Arkham has its own intel marketplace exchange that allows the community to buy/sell data and a properietary AI engine, Ulttra, that aggregates, filters and labels entities to addresses",
  "Ultra - Ultra is the technology behind Arkham, an AI-powered engine that algorithmically matches addresses to real-world entities. Ultra was developed over 3 years by Arkham core contributors with backing from the founders of Palantir and OpenAI.",
  "Intel Exchange - There is large and growing demand for blockchain intelligence as crypto users become more sophisticated. There is also a large and growing community of blockchain sleuths who gather blockchain intelligence. However, there is not a market that matches these buyers and sellers, so crypto users miss out on valuable information and sleuths aren’t paid for their work. The Arkham Intel Exchange creates this market, allowing intel to be bought and sold through smart contract bounties and auctions.",
];
const pointList = [
  { id: 1, title: "Tokens Offered", detail: "70,000.00.00 XMR" },
  { id: 2, title: "sale Price", detail: "1 XMR = 0.52 USD" },
];

const Listingppoints = ({ title, lineText }) => {
  return (
    <>
      <h6>{title}</h6>
      {lineText?.map((line, index) => (
        <p key={index}>{line}</p>
      ))}
    </>
  );
};
const LaunchpadDetail = () => {
  const navigate = useNavigate();

  const historyPage = () => {
    // Call the navigate function to redirect to a specific route
    navigate("/launchpadHistory"); // Replace '/desired-route' with the route you want to navigate to
  };

  return (
    <>
      <SignInHeader />
      <div className={`LaunchpadDetail-page  commanBgFull`}>
        <div className="container ">
          <Button
            className={`backbtn ${style.back}`}
            style={{ marginBottom: "28px" }}
            type="text"
            onClick={() => {
              navigate(-1);
            }}
          >
            <BackIcon style={{ marginRight: "5px" }} /> Back to previous
          </Button>
          <LaunchpadCard
            color="cyan"
            tagtext="Prepration Period"
            DayCount
            pointList={pointList}
          />
          <Button
            className={`backbtn ${style.launchpadHistory}`}
            type="text"
            onClick={historyPage}
          >
            Launchpad History <AheadIcon style={{ marginLeft: "5px" }} />
          </Button>
          <div className={style.LaunchpadDetail}>
            <section className={style.stepTimeline}>
              <Title level={3}>Subscription Timeline</Title>
              <LaunchpadStepList />
            </section>
            <section className={style.blockchainAI}>
              <Title level={3}>
                Arkham- An intel-to-earn token powering the deanonymization of
                the blockchain with AI.
              </Title>
              <Listingppoints
                title="Project Introduction"
                lineText={lineText}
              />
              <Listingppoints
                title="Key Features and Highlights"
                lineText={lineText1}
              />
            </section>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default LaunchpadDetail;
