import React from "react";
import "./Footer.scss";
import { t } from "i18next";

function Footer() {
  return (
    <div className="footer">
      <p className="footer__text">
        &copy; {new Date().getFullYear()} CoinHub | {t("footer.rights")}
      </p>
    </div>
  );
}

export default Footer;
