import { createSlice } from "@reduxjs/toolkit";
import {
  generateQrCode, 
  getBalance,
  getBalanceHistory,
  getConvertCurrencyDetail,
  getConvertMNG,
  getCurrencies,
  getCurrencyDetails,
  getGainersLosers,
  getGraphData,
  getHideZeroBls,
  getMarketTickersList,
  getPortfolioCurrency,
  getPortfolioFuture,
  getPortfolioPrice,
  getSingleCurrencyDetails,
} from "../../services";
import { typeOfTradeObj } from "../../../staticObjects";
export const commonSlice = createSlice({
  name: "commonSlice",
  initialState: {
    balanceList: [],
    currenciesList: [],
    error: false,
    currencyDetails: [],
    balanceHistory: [],
    convertCurrencyList: [],
    balanceHistoryTotal: 0,
    singleCurrency: {},
    convertMngList: {},
    getCode: "",
    clickViewFrom: "",
    currentMarketId: "",
    hideZeroBls: false,
    gainersLosers: {
      gainers: [],
      losers: [],
    },
    futurePositionList: [],
    currencyGraphData: [],
    portfolioPrices: {},
    tradingType: localStorage.getItem("tradeType") || typeOfTradeObj.spot,
    memberData: {},
    marketList: {},
    loading: false,
  },
  reducers: {
      viewForTrans: (state, action) => {
      state.clickViewFrom = action?.payload?.clickViewFrom;
      state.currentMarketId = action?.payload?.marketId;
    },
    setTradingType: (state, action) => {
      state.tradingType = action?.payload;
    },
    emptyBalanceList: (state, action) => {
      state.balanceList = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBalance.pending, (state) => {
        state.error = false;
        state.balanceList = [];
      })
      .addCase(getBalance.fulfilled, (state, action) => {
        state.loading = false;
        state.balanceList = action.payload.list;
      })
      .addCase(getBalance.rejected, (state, action) => {
        state.error = true;
      })
      .addCase(getCurrencies.pending, (state) => {
        state.error = false;
      })
      .addCase(getCurrencies.fulfilled, (state, action) => {
        state.loading = false;
        state.currenciesList = action.payload.list;
      })
      .addCase(getCurrencies.rejected, (state, action) => {
        state.error = true;
      })
      .addCase(getCurrencyDetails.pending, (state) => {
        state.error = false;
      })
      .addCase(getCurrencyDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.currencyDetails = action.payload.list;
      })
      .addCase(getCurrencyDetails.rejected, (state) => {
        state.error = true;
      })

      .addCase(getGraphData.pending, (state) => {})
      .addCase(getGraphData.fulfilled, (state, action) => {
        state.currencyGraphData = action.payload.list;
      })
      .addCase(getGraphData.rejected, (state) => {})

      .addCase(getSingleCurrencyDetails.pending, (state) => {
        state.error = false;
      })
      .addCase(getSingleCurrencyDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.singleCurrency = action.payload;
      })
      .addCase(getSingleCurrencyDetails.rejected, (state) => {
        state.error = true;
      })

      .addCase(getBalanceHistory.pending, (state) => {
        state.error = false;
      })
      .addCase(getBalanceHistory.fulfilled, (state, action) => {
        state.loading = false;
        state.balanceHistory = action?.payload?.data;
        state.balanceHistoryTotal = action.payload.total || 0;
      })
      .addCase(getBalanceHistory.rejected, (state) => {
        state.error = true;
      })

      .addCase(generateQrCode.pending, (state) => {
        state.error = false;
      })
      .addCase(generateQrCode.fulfilled, (state, action) => {
        state.loading = false;
        state.getCode = action.payload.qrCode;
      })
      .addCase(generateQrCode.rejected, (state) => {
        state.error = true;
      })

      .addCase(getGainersLosers.pending, (state) => {})
      .addCase(getGainersLosers.fulfilled, (state, action) => {
        state.gainersLosers = action?.payload?.data;
      })
      .addCase(getGainersLosers.rejected, (state) => {})

      .addCase(getPortfolioPrice.pending, (state) => {})
      .addCase(getPortfolioPrice.fulfilled, (state, action) => {
        state.portfolioPrices = action.payload;
      })
      .addCase(getPortfolioPrice.rejected, (state) => {})

      // getConvertMNG
      .addCase(getConvertMNG.pending, (state) => {})
      .addCase(getConvertMNG.fulfilled, (state, action) => {
        state.convertMngList = action.payload;
      })
      .addCase(getConvertMNG.rejected, (state) => {})

      .addCase(getConvertCurrencyDetail.pending, (state) => {})
      .addCase(getConvertCurrencyDetail.fulfilled, (state, action) => {
        state.convertCurrencyList = action.payload;
      })
      .addCase(getConvertCurrencyDetail.rejected, (state) => {})

      .addCase(getPortfolioCurrency.pending, (state, { meta }) => {
        state.error = false;
      })
      .addCase(getPortfolioCurrency.fulfilled, (state, action) => {
        state.memberData = { ...action.payload };
      })
      .addCase(getPortfolioCurrency.rejected, (state) => {
        state.memberData = {};
        state.error = true;
      })
      .addCase(getPortfolioFuture.pending, (state) => {
        state.error = false;
      })
      .addCase(getPortfolioFuture.fulfilled, (state, action) => {
        state.loading = false;
        state.futurePositionList = action.payload.list;
      })
      .addCase(getPortfolioFuture.rejected, (state, action) => {
        state.error = true;
      })

      // .addCase(getMarketTickersList.pending, (state) => {
      //   state.error = false;
      // })
      // .addCase(getMarketTickersList.fulfilled, (state, action) => {
      //   state.loading = false;
      //   state.marketList = action.payload.list;
      // })
      // .addCase(getMarketTickersList.rejected, (state, action) => {
      //   state.error = true;
      // });
      .addCase(getHideZeroBls.pending, (state) => {})
      .addCase(getHideZeroBls.fulfilled, (state, action) => {
        state.hideZeroBls = action?.payload?.data;
      })
      .addCase(getHideZeroBls.rejected, (state) => {});
  },
});

export const { viewForTrans, setTradingType, emptyBalanceList } =
  commonSlice.actions;
export default commonSlice.reducer;
