import React from "react";
import { Link } from "react-router-dom";
import style from "./style.module.scss";
import { StoreImages } from "../../Storeimgaes/StoreImages";
import { Col, Row } from "antd";
import { rootName } from "../../../utils/constant";
import LogoWhite from "../../Assets/Images/CoinhubWhite.png";
import { useTranslation } from "react-i18next";
import { publicRouteObj, sidebarRouteObj } from "../../../staticObjects";
import { isAuthenticated } from "../../../redux/selector";
import { useSelector } from "react-redux";

const Footer = () => {
  const { insta, fb, telegram, twitter, linkedIn } = StoreImages;
  const isAuthenticate = useSelector((state) => isAuthenticated(state));

  const { t } = useTranslation();
  return (
    <>
      <div className={style.footerKazix}>
        <div className="container">
          <Row className={style.footerKazix__kazixfooter} gutter={[10, 10]}>
            <Col xs={24} sm={16} md={10} lg={10}>
              <div className={style.footerKazix__kazixfooter__kazix}>
                <Link to={rootName}>
                  <h2 className="logo-blue">
                    <img src={LogoWhite} style={{ width: "130px" }} />
                  </h2>
                </Link>
                <p className={style.lorem}>
                  {t("footer.address")}
                </p>
                <h3 style={{ color: "white" }}>+976-75076060</h3>
                <Link style={{ color: "white" }} to="info@coinhub.mn">
                  info@coinhub.mn
                </Link>
              </div>
            </Col>
            <Col xs={24} sm={8} md={5} lg={4}>
              <div className={style.footerKazix__kazixfooter__legal}>
                <h3 className={style.legal}>{t("footer.Products.Products")}</h3>
                {/* <Link to={`${rootName}advanceTrading`}>{t("footer.Products.Trade")} </Link> */}
                <Link to={isAuthenticate ? `/advanceTrading` : "/trading"}>
                  {t("footer.Products.Trade")}
                </Link>
                <br />
                <Link to={`${rootName}${publicRouteObj.ourToken}`}>
                  {t("footer.Products.CHBtoken")}
                </Link>
                <br />
                <Link to={`${rootName}${sidebarRouteObj.earn}`}>
                  {t("footer.Products.Earn")}
                </Link>
              </div>
            </Col>
            <Col xs={24} sm={8} md={5} lg={5}>
              <div className={`${style.footerKazix__kazixfooter__legal}`}>
                <h3 className={style.legal}>{t("footer.aboutUs.aboutUs")}</h3>
                <Link to={`${rootName}${publicRouteObj.whyCoinhub}`}>
                  {t("footer.aboutUs.aboutCOINUB")}
                </Link>
                <br />
                <Link to={`${rootName}${publicRouteObj.ourSubscription}`}>
                  {t("footer.aboutUs.marketNews")}
                </Link>
                <br />
                <Link to={`${rootName}${publicRouteObj.marketupdates}`}>
                  {t("footer.aboutUs.socialresponsibility")}
                </Link>
              </div>
            </Col>
            <Col xs={24} sm={8} md={4} lg={5}>
              <div className={style.footerKazix__kazixfooter__legal}>
                <h3 className={style.legal}>Policy</h3>
                {/* <a href="https://www.coinhub.mn/about-terms" target="_blank">
                  {t("footer.termsConditions")}
                </a> */}
                <Link to="/terms" target="_blank">
                  {t("footer.termsConditions")}
                </Link>
                <div className="imgs">
                  {/* <a
                    href="https://www.coinhub.mn/about-privacy"
                    target="_blank"
                  >
                    {t("footer.privacyPolicy")}
                  </a> */}
                  <Link to="/privacy" target="_blank">
                    {t("footer.privacyPolicy")}
                  </Link>
                  <br />
                  <Link to="/policies" target="_blank">
                    {" "}
                    {t("footer.policiesVASP")}
                  </Link>
                  {/* <br />
                  <Link to={`/`}> {t("footer.fee")}</Link> */}
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className={style.copyRight}>
          <div className="container">
            <div className={style.copyRight__inner}>
              <p>
                &copy; {new Date().getFullYear()} Coinhub | {t("footer.rights")}
              </p>
              <div className={style.copyRight__inner_links}>
                <Link
                  to="https://www.facebook.com/Coinhub.Mongolia/"
                  target="_blank"
                >
                  <img src={fb} alt="img" />
                </Link>
                <Link to="https://twitter.com/CoinhubMongolia" target="_blank">
                  <img src={twitter} alt="img" />
                </Link>
                <Link
                  to="https://www.instagram.com/coinhub.mongolia/"
                  target="_blank"
                >
                  <img src={insta} alt="img" />
                </Link>
                <Link
                  to="https://www.linkedin.com/company/coinhub-mongolia/"
                  target="_blank"
                >
                  <img src={linkedIn} alt="img" />
                </Link>
                <Link to="https://t.me/coinhub_CHB_owners" target="_blank">
                  <img src={telegram} alt="img" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
