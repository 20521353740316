import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toOptionalFixed, uppercase } from "../../../helpers";
import { createConvert } from "../../../redux/services";
import HeadComman from "../../Common/HeadComman";
import CommonButton from "../../Ui/button/CommonButton";
import style from "./style.module.scss";

const ConvertPreview = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    onClick,
    firstCurr,
    secondCurr,
    fromAmt,
    toAmt,
    firstCurrObj,
    secondCurrObj,
    callBack,
  } = props;

  const convertFun = async () => {
    const sendData = {
      amount: fromAmt,
      currency: firstCurr?.currency,
      bid_currency: secondCurr?.currency,
    };
    let res = await dispatch(createConvert({ ...sendData }));
    if (res.payload.status) {
      callBack();
    }
  };

  const { currency, currency_usdt, balance, currency_icon, currency_name } =
    firstCurr;
  const { swap_fees, precision } = firstCurrObj ?? {};

  let fromCurrCap = uppercase(currency);
  let toCurrCap = uppercase(secondCurr.currency);

  let calFee = fromAmt * swap_fees;
  let totalAmt = toOptionalFixed(fromAmt - calFee, precision);
  const dollVal = toOptionalFixed(Number(currency_usdt) * Number(balance), 4);
  const totalDollVal = toOptionalFixed(totalAmt * currency_usdt, 4);

  const getVal = toOptionalFixed(
    totalDollVal / secondCurr?.currency_usdt,
    secondCurrObj?.precision
  );

  return (
    <div className={`${style.Asset} `}>
      <HeadComman title={"Order Preview"} onClick={onClick} />
      <div className={style.Asset_body}>
        <div className={style.Asset_body_scrollitem}>
          <div className={style.amount}>
            {getVal} {uppercase(secondCurr?.currency)}
          </div>
          <div className={style.currencydata}>
            <label>Pay with</label>
            <h4>
              <img src={currency_icon} alt="icon" /> {fromCurrCap}{" "}
            </h4>
          </div>

          <div className={style.currencydata}>
            <label>Exchange rate</label>
            <h4>
              1 {fromCurrCap} ≈{" "}
              {toOptionalFixed(
                Number(currency_usdt) / Number(secondCurr?.currency_usdt),
                secondCurrObj?.precision ?? 2
              )}{" "}
              {toCurrCap}
            </h4>
          </div>

          <div className={style.currencydata}>
            <label>Platform fee</label>
            <h4>{calFee}</h4>
          </div>

          <div className={style.currencydata}>
            <label>Total</label>
            <div>
              <h4>
                {totalAmt} {fromCurrCap}
              </h4>
              <h4>${totalDollVal}</h4>
            </div>
          </div>
          <p className={style.note}>{t("convert_disclaimer")}</p>
        </div>

        <div className={style.Buy_btnMain}>
          <CommonButton
            title={"Convert now"}
            className="btn"
            onClick={convertFun}
          />
        </div>

        <div className={style.Buy_amount}>
          <label>{currency_name} Balance</label>
          <p>
            <span>
              {toOptionalFixed(balance, precision ?? 2)} {fromCurrCap} ≈
            </span>
            <span> ${dollVal} </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ConvertPreview;
