import React from "react";
import "./ServicesCard.scss";
export default function ServicesCard({ image, text, title, className }) {
  return (
    <div className={`servicesCard ${className}`}>
        <div className="cardImg">
      <img src={image} width={25} height={28} alt="rewarded" />
      </div>
      <div>
      <h5>{title}</h5>
      <p>{text}</p>
      </div>
    </div>
  );
}
