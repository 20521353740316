import { combineReducers } from "@reduxjs/toolkit";
import alertReducer from "./feature/alert/alert.slice";
import loaderReducer from "./feature/loader/loader.slice";
import loginReducer from "./feature/login/login.slice";
import userReducer from "./feature/user/user.slices";
import commonReducer from "./feature/common/common.Slice";
import exchangesReducer from "./feature/exchange/exchanges.slice";
import sendReceiveReducer from "./feature/sendReceive/sendReceive.slice";
import earnReducer from "./feature/earn/earn.slice";
import referralReducer from "./feature/referral/referral.Slices";
import transHistoriesReducer from "./feature/transHistories/transHistories.Slice";
import coinListingReducer from "./feature/coinlisting/coinlisting.Slice";
import settingReducer from "./feature/setting/setting.Slice";
import notificationReducer from "./feature/notification/notification.slice";
import securityReducer from "./feature/securityManagement/security.Slices";
import tokenReducer from "./feature/ourToken/ourToken.slice";
import blogReducer from "./feature/blog/blogPosts.Slice";

export const rootReducer = combineReducers({
  notifications: notificationReducer,
  alert: alertReducer,
  loader: loaderReducer,
  login: loginReducer,
  user: userReducer,
  commonApiData: commonReducer,
  sendReceive: sendReceiveReducer,
  earn: earnReducer,
  exchange: exchangesReducer,
  referral: referralReducer,
  transactionHistories: transHistoriesReducer,
  coinlisting: coinListingReducer,
  setting: settingReducer,
  security: securityReducer,
  ourTokens: tokenReducer,
  blogs: blogReducer
});
