import { DatePicker } from "antd";
import moment from "moment";
import React from "react";
import "./DatePickerCustom.scss";

function DobDatePickerCustom(props) {
  const { RangePicker } = DatePicker;
  const {
    datepickerSimple,
    datepickerSimpleStyle,
    datepickerAdvance,
    datepickerAdvanceStyle,
    datepickerTime,
    className,
    label,
    onChange,
    labelcustom,
    value,
    dateDisabled,
    onClick,
    suffixIcon,
    defaultPickerValue
  } = props;

  const disabledDate = (current) => {
    // Disable future dates and dates after 18 years ago from the current date
    return (
      // current && current.isAfter(moment().subtract(18, "years").endOf("day"))
      current && current.isAfter(moment().endOf("day"))
    );
  };
  const momentValue = value? moment(value):null; 
  return (
    <>
      {label ? <label className={`label ${labelcustom}`}>{label}</label> : null}
      {datepickerSimple && (
        <DatePicker
          suffixIcon={suffixIcon}
          className={`dobD customDatePicker ${
            datepickerSimpleStyle && "datepickerSimple"
          } ${className} `}
          placeholder="YY-MM-DD"
          disabledDate={dateDisabled || disabledDate}
          type="date"
          onChange={onChange}
          inputReadOnly
          value={momentValue}
          onClick={onClick}
          defaultPickerValue={defaultPickerValue}
          // defaultPickerValue={moment().subtract(18, "years")}
        />
      )}
      {datepickerAdvance && (
        <RangePicker
          className={`customDatePicker  ${
            datepickerAdvanceStyle && "datepickerAdvance"
          } `}
        />
      )}
      {datepickerTime && (
        <RangePicker
          showTime
          className={`customDatePicker $ ${datepickerTime && "datepickerTime"}`}
        />
      )}
    </>
  );
}

export default DobDatePickerCustom;
