import React, { useEffect } from "react";
import "../style.scss";
function Policies() {
  useEffect(() => {
    document.body.classList.add("policyPages");
    return () => {
      document.body.classList.add("policyPages");
    };
  }, []);
  return (
    <div>
      <div class="page">
        <div class="translations-content-container">
          <div class="container">
            <div
              class="tab-content translations-content-item en visible"
              id="en"
            >
              <p className="mb-15">
                <b>ВХҮҮ-ийн бодлого, журам</b>
              </p>
              <p className="mb-15">
                <b>
                  Биржийн үйл ажиллагаанд хүндрэл үүссэн тохиолдолд үйл
                  ажиллагааг зогсоох төлөвлөгөө
                </b>
              </p>
              <p className="mb-15">
                Койнхаб биржийн үйл ажиллагаанд хүндрэл үүссэн тохиолдолд
                бизнесийн үйл ажиллагааг тогтвортой, тасралтгүй үргэлжлүүлэх
                зорилгоор Бизнесийн тасралтгүй үйл ажиллагааны төлөвлөгөөг
                боловсруулсан болно. Энэхүү төлөвлөгөө нь байгалийн давагдашгүй
                хүчин зүйлс, системийн доголдол зэрэг учирч болзошгүй нөхцөл
                байдлын үед бизнесийн үйл ажиллагааг тасралтгүй хангахад
                чиглэнэ. Биржийн үйл ажиллагаанд эрсдэлтэй нөхцөл байдалд үүссэн
                тохиолдолд нийт харилцагч, хөрөнгө оруулагч, хувьцаа эзэмшигч
                зэрэг оролцогч талуудад учирч болох сөрөг нөлөөг хамгийн бага
                түвшинд байлгах үүднээс хүлээн зөвшөөрөх эрсдэлийн хамрах хүрээг
                тодорхойлоод байна. Энэхүү төлөвлөгөөнд заасны дагуу гэнэтийн
                хүчин зүйлс үүсэх, систем доголдох үед 24 цагт багтаан холбогдох
                арга хэмжээг авч ажиллана.
              </p>
              <p className="mb-15">
                <b>
                  Бирж бүртгэлээс хасагдаж, үйл ажиллагаагаа зогсоосон
                  тохиолдолд авах арга хэмжээ
                </b>
              </p>
              <p className="mb-15">
                Койнхаб бирж Санхүүгийн зохицуулах хорооноос баталсан
                &quot;Виртуал хөрөнгийн үйлчилгээ үзүүлэгчийн үйл ажиллагаанд
                хязгаарлалт тогтоох, түр зогсоох, бүртгэлээс хасах
                журам&quot;-ын дагуу аливаа эрсдэл учирсан тохиолдолд бизнесийн
                үйл ажиллагааг тогтвортой, тасралтгүй үргэлжлүүлэх, харилцагчийн
                мөнгөн хөрөнгийн аюулгүй байдлыг хангах зорилгоор Бизнесийн
                Тасралтгүй Үйл Ажиллагааны Төлөвлөгөө болон Онцгой нөхцөл байдал
                үүссэн үед авах арга хэмжээний төлөвлөгөө боловсруулж баталсан
                байна. Энэхүү төлөвлөгөөгөөр онцгой нөхцөл байдалд үйл
                ажиллагааг түр зогсоох, хязгаарлалт тогтоох, татан буугдах,
                үйлчилгээ зогсох, кибер халдлага, түрэмгийлэлд өртөх үед авч
                хэрэгжүүлэх арга хэмжээг тодорхойлсон. Төлөвлөгөөнд заасны дагуу
                тухайн нөхцөл байдал үүссэн тохиолдолд Гүйцэтгэх захирлын
                тушаалаар ажлын хэсгийг байгуулж уг ажлын хэсэг үүсэн нөхцөл
                байдалд дүн шинжилгээ хийх, шалтгаан нөхцөлийг илрүүлэх,
                зөрчлийг арилгах арга хэмжээний төлөвлөгөө боловсруулах,
                хэрэгжүүлэх, холбогдох удирдлагад тайлагнах зэрэг шаардлагатай
                бүхий л арга хэмжээг авч хэрэгжүүлнэ.
              </p>
              <p className="mb-15">
                <b>
                  Виртуал хөрөнгийн бүртгэлийн шаардлага, шалгуур, дүрэм, журам
                </b>
              </p>
              <p className="mb-15">
                Виртуал хөрөнгийг бүртгэх үед Бирж нь “Виртуал хөрөнгийн
                үйлчилгээ үзүүлэгчийн үйл ажиллагааны журам” болон Койнхаб
                биржийн “Виртуал хөрөнгийг нийтэд санал болгох, бүртгэх,
                бүртгэлээс хасах үйл ажиллагааны журам”-ын дагуу дараах
                мэдээллийг авна. Үүнд:
              </p>
              <p className="mb-15">- Виртуал хөрөнгө /токен/ гаргагчийн нэр;</p>
              <p className="mb-15">
                - Виртуал хөрөнгө гаргагч хуулийн этгээдийн дүрэм, гэрчилгээ,
                үүсгэн байгуулагдаж, бүртгэгдсэн улс, үйл ажиллагаа явуулж буй
                хаяг;
              </p>
              <p className="mb-15">
                - Виртуал хөрөнгө гаргагчийн албан ёсны вэб хуудас, сошиал
                хуудас (Medium, Reddit, Twitter, Facebook, Linkedin гэх мэт)-ны
                холбоосууд;
              </p>
              <p className="mb-15">
                - Виртуал хөрөнгө гаргагчийн компанийн хувьцаа эзэмшлийн бүтэц,
                Гүйцэтгэх захирал, эцсийн өмчлөгчийн овог нэр, иргэний үнэмлэх
                эсхүл гадаад паспорт;
              </p>
              <p className="mb-15"> - Виртуал хөрөнгийн нэр, төрөл, тикер;</p>
              <p>
                - Төслийн танилцуулга (Whitepaper)-ыг хавсаргах, эсхүл алдаа
                заагаагүй танилцах холбоос;
              </p>
              <p className="mb-15">- Smart contract хаяг;</p>
              <p>
                - Койны блокчэйн сүлжээний мэдээлэл (ERC20, BEP20 суурилсан гэх
                мэт);
              </p>
              <p className="mb-15">
                - Chain Fork буюу салаалсан блокчейн технологи байгаа эсэх
                талаарх мэдээлэл;
              </p>
              <p className="mb-15">
                - IEO/Launch болон бусад байдлаар Койн худалдах үйл ажиллагаа
                хийсэн талаарх мэдээлэл;
              </p>
              <p className="mb-15">
                - Анхдагч зах зээлийн үнэ, үнийг хэрхэн тогтоосон талаарх
                мэдээлэл;
              </p>
              <p className="mb-15">
                - Койныг арилжаалж эхэлсэн эсхүл арилжихаар тохиролцож, гэрээ
                байгуулсан биржүүдийн талаарх мэдээлэл;
              </p>
              <p className="mb-15">
                - Койныг арилжаалж эхэлсэн бол арилжаалж буй биржүүдийн үнийн
                түүхийн талаарх тоон үзүүлэлт бүхий мэдээлэл;
              </p>
              <p className="mb-15">
                - Холбоо барих мэдээлэл /ажилтны албан тушаал, нэр, утасны
                дугаар, цахим шуудангийн хаяг/;
              </p>
              <p className="mb-15">
                - Шаардлагатай тохиолдолд Виртуал хөрөнгө гаргагчаас олон улсын
                хөндлөнгийн аудитын компаниар аудит хийлгэхийг шаардаж болно.
              </p>
              <p className="mb-15">
                - Виртуал хөрөнгө гаргагч компанийн хувьцааны 33 буюу түүнээс
                дээш хувийг эзэмшдэг этгээдийн нэр, эзэмшлийн хувь, хаяг,
                иргэншил /CV/ сүүлийн таван жилийн ажлын туршлага, холбогдох
                салбарын туршлага, боловсролын зэрэг, гэмт хэрэг, зөрчилд
                холбогдож байсан эсэх талаарх мэдээллийг заавал багтаасан
                байна/;
              </p>
              <p className="mb-15">
                - Виртуал хөрөнгө гаргагч компанийн эрх бүхий албан тушаалтны
                жагсаалт, тэдгээрийн албан тушаал, товч танилцуулга /CV/
                /сүүлийн таван жилийн ажлын туршлага, холбогдох салбарын
                туршлага, боловсролын зэрэг, гэмт хэрэг, зөрчилд холбогдож
                байсан эсэх талаарх мэдээллийг заавал багтаасан байна/;
              </p>
              <p className="mb-15">
                - Виртуал хөрөнгө гаргагчийн үйл ажиллагаа эсхүл Койны төсөлд
                холбогдох хууль зүйн магадлан шинжилгээ хийлгэсэн эсэх,
                хийлгэсэн бол магадлан шинжилгээний тайлан, дүгнэлт;
              </p>
              <p className="mb-15">
                - Хууль зүйн зөвлөх үйлчилгээ авдаг, эсхүл хуулийн
                мэргэжилтэнтэй бол тэдгээртэй холбогдох мэдээлэл;
              </p>
              <p className="mb-15">
                - Виртуал хөрөнгө гаргагчийн бүртгэлтэй улс нь виртуал хөрөнгийг
                хуулиар зохицуулдаг эсэх, зохицуулдаг бол бүртгэлтэй улс,
                зохицуулах байгууллагын мэдээлэл болон тусгай
                зөвшөөрөл/лицензийн талаарх мэдээллийг хавсаргах;
              </p>
              <p className="mb-15">
                - Хуулиар зохицуулагддаг санхүүгийн үйлчилгээ үзүүлдэг этгээдээс
                үйлчилгээ авдаг эсэх талаарх мэдээлэл;
              </p>
              <p className="mb-15">
                - Токены үнэ, ханш нь өөр койн, токен эсхүл төслөөс хамааралтай
                эсэх талаарх мэдээлэл;
              </p>
              <p className="mb-15">
                - Токен нь одоогийн суурилсан протоколоос ирээдүйд өөр протоколд
                шилжих төлөвлөгөөтэй эсэх талаарх мэдээлэл;
              </p>
              <p className="mb-15">
                - Уламжлалт санхүүгийн хэрэгслээр дамжуулан санхүүжилт босгосон
                эсэх, босгосон санхүүжилтийн талаарх товч тайлбар болон
                худалдах, худалдан авах гэрээ, санхүүгийн тайлан зэргийг
                хавсаргах;
              </p>
              <p className="mb-15">
                - Виртуал хөрөнгийн төсөл, виртуал хөрөнгийн танилцуулга
                (whitepaper)-ын талаарх Журамд заасан шалгуурыг хангахтай
                холбоотой бусад мэдээлэл;&quot;
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Policies;
